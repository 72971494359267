import React from "react";

const PublisherProcessComponent = ({ step }) => {
  return (
    <div className="processes">
      <div
        className="publisher-process-circle"
        style={{ backgroundColor: step.circleColor }}
      />
      <h1 className="text-start text-theme fst-italic">{step.header}</h1>
      <h2 className="text-start text-theme fst-italic">{step.helperText}</h2>
      <p className="text-start text-theme fst-italic">{step.helperText2}</p>
    </div>
  );
};

export default PublisherProcessComponent;
