import React from "react";

const FocusElement = ({ testimonial }) => {
  const { img, authorName } = testimonial;

  return (
    <div className="image-slide ">
      {img ? (
        <div
          className="focus-image"
          style={{ backgroundImage: `url(${img})` }}
        />
      ) : (
        <strong className="focus-image text-white fs-1 d-flex justify-content-center align-items-center">
          {authorName[0]}
        </strong>
      )}
    </div>
  );
};

export default FocusElement;
