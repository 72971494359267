import React from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { getContent } from "../localization/index";
import { GAevent } from "../utils/Events";
import { EVENT_NAME } from "./../utils/Events";

function SignupBanner({ title, subheader, cta }) {
  const history = useHistory();
  const language = useSelector((state) => state.language);

  return (
    <div className=" p-md-5 my-md-3 p-3 rounded signup-banner ">
      <h2 className="text-white fw-bolder text-center pt-4 pb-3">
        {title ? title : getContent(language, "GET_STARTED_HEADER")}
      </h2>
      <p
        className="text-white fw-semibold text-center px-3 px-md-5"
        style={{ fontSize: 16 }}
      >
        {subheader
          ? subheader
          : ` ${getContent(language, "GET_STARTED_DESCRIPTION")} `}
      </p>
      <div className="d-flex justify-content-center">
        <button
          className="signup-button p-3 my-2 px-4"
          onClick={() => {
            GAevent(EVENT_NAME.signup_page, ["get_started_cta"])
            history.push("/signup");
          }}
        >
          {cta ? cta : getContent(language, "GET_STARTED_CTA")}
        </button>
      </div>
    </div>
  );
}

export default SignupBanner;
