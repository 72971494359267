import React from "react";
import { useHistory } from "react-router-dom";
import { GAevent } from "../../utils/Events";
import { EVENT_NAME } from './../../utils/Events';

const AddPaypalInvoiceButton = () => {
  const history = useHistory();
  return (
    <div>
      <button
        className="text-white fw-bold  py-2  px-4 bg-theme addWebButton shadow text-nowrap"
        onClick={() => {
          history.push("/advertiser/paypal-invoice/create");
          GAevent(EVENT_NAME.paypal_invoices, [
            "create_invoice",
          ]);
        }}
      >
        CREATE INVOICE
      </button>
    </div>
  );
};

export default AddPaypalInvoiceButton;
