import axios from "axios";
import { fetchJWTToken } from "../utils/auth";
import { BASE_URL } from "../constants/Endpoints";

export function fetchTransactionsList(data, userType) {
  return axios({
    method: "GET",
    url: `${BASE_URL}/om/transaction?userType=${userType}&pageNumber=${
      data.pageNumber
    }&pageSize=${data.pageSize}&status=${data.status}${
      data.formattedStartDate &&
      data.formattedEndDate &&
      `&startDate=${data.formattedStartDate}&endDate=${data.formattedEndDate}`
    }`,
    headers: {
      Authorization: fetchJWTToken(),
    },
  });
}

export function fetchTransactionDetails(transactionId, userType) {
  return axios({
    method: "GET",
    url: `${BASE_URL}/om/transaction/details?userType=${userType}&transactionId=${transactionId}`,
    headers: {
      Authorization: fetchJWTToken(),
    },
  });
}

export function createPaypalPayment({
  amount,
  paymentType,
  transactionAmount,
  totalAmount,
  transactionChargeDto,
}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/om/payment/create-payment-link`,
    data: {
      amount,
      paymentType,
      transactionAmount,
      totalAmount,
      transactionChargeDto,
    },
    headers: {
      Authorization: fetchJWTToken(),
    },
  });
}

export function approvePaypalPayment(body) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/om/payment/capture-payment`,
    data: body,
    headers: {
      Authorization: fetchJWTToken(),
    },
  });
}
