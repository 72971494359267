import React from "react";
import Modal from "react-modal";
import Colors from "../constants/colors";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const ModalWrapper = ({
  isOpen,
  close,
  outsideClickClose = true,
  title = "",
  icon,
  description = "",
  cta = [],
  customCta,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const profileDetails = useSelector((state) => state.profileDetails);

  const customStyles = {
    content: {
      maxWidth: 400,
      textAlign: "center",
      paddingLeft: "32px",
      paddingRight: "32px",
      padding: "20px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none",
      borderRadius: "8px",
    },
    overlay: {
      background: "rgba(0,0,0,0.4)",
      zIndex: 2,
    },
  };

  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      onRequestClose={close}
      shouldCloseOnOverlayClick={outsideClickClose}
      shouldCloseOnEsc={outsideClickClose}
    >
      <div className="d-flex justify-content-center">
        <img src={icon} height={150} width={150} />
      </div>

      <p className="text-theme fw-bold" style={{ fontSize: 22 }}>
        {title}
      </p>

      <div className="d-flex flex-column mt-3">
        <div className="d-flex justify-content-center">
          {customCta}
          {cta.map((ctaItem) => (
            <button
              className="btn text-white fw-bold ms-2 px-4 c-ptr"
              style={{
                backgroundColor: ctaItem.backgroundColor
                  ? ctaItem.backgroundColor
                  : Colors.primaryColor,
              }}
              onClick={ctaItem.onClick}
            >
              {ctaItem.title}
            </button>
          ))}
          {/* {profileDetails.primaryPayoutType === "NA"|| profileDetails.primaryPayoutType === null ? (
            <button
              className="btn text-white fw-bold ms-2 px-4 c-ptr"
              style={{
                backgroundColor: Colors.primaryColor,
              }}
              onClick={() => {
                history.push("/publisher/payments");
              }}
            >
              Add Payment Details
            </button>
          ) : null} */}
        </div>
      </div>
    </Modal>
  );
};

export default ModalWrapper;
