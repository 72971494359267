import React from "react";
import { BounceLoading } from "respinner";
import Colors from "../constants/colors";

const FullScreenLoader = ({ text }) => {
  return (
    <div
      className="vw-100 h-100 position-fixed d-flex justify-content-center align-items-center "
      style={{
        top: 0,
        right: 0,
        zIndex: 1100,

        background: "rgba(0, 0, 0, 0.15)",
      }}
    >
      <div
        className="d-flex flex-column align-items-center mx-auto w-100 pb-3 pt-4 px-0"
        style={{
          background: text && "white",
          maxWidth: "200px",
          borderRadius: "8px",
        }}
      >
        <BounceLoading fill={Colors.primaryColor} />
        <p className="text-theme text-center fw-bold mt-3">{text}</p>
      </div>
    </div>
  );
};

export default FullScreenLoader;
