import React from "react";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import PublisherComp from "../../components/PublisherComp";
import SignupBanner from "../../components/SignupBanner";
import { useSelector } from "react-redux";
import { getContent } from "../../localization/index";

export default function PublisherPage() {
  const language = useSelector(state => state.language);

  return (
    <>
      <NavBar />
      <div className="container">
        <PublisherComp />
        <SignupBanner
          title={getContent(language, "GET_STARTED_HEADER_PUBLISHER")}
          subheader={getContent(language, "GET_STARTED_DESCRIPTION_PUBLISHER")}
          cta={getContent(language, "GET_STARTED_CTA_PUBLISHER")}
        />
      </div>
      <Footer />
    </>
  );
}
