import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { getContent } from "../localization";

export default function BreadCrumbs() {
  const location = useLocation();
  const [route, setRoute] = useState("");
  const pref = useSelector((state) => state.profileDetails);
  const language = useSelector((state) => state.language);

  const preference = pref ? pref.preference.toLowerCase() : "";

  useEffect(() => {
    setRoute(location.pathname);
  }, [location]);

  const singleRoutes = [
    { route: `/${preference}/marketplace`, label: "Marketplace" },
    { route: `/${preference}/my-orders`, label: "Orders" },
    { route: `/${preference}/my-website`, label: "My Websites" },
    { route: `/${preference}/content-writing`, label: "Content Writing" },
    { route: `/${preference}/billing-funds`, label: "Billing And Funds" },
    { route: `/${preference}/reports`, label: "Reports" },
    { route: `/${preference}/payments`, label: "Payments" },
    { route: `/${preference}/cart`, label: "Cart" },
    { route: `/${preference}/paypal-invoice`, label: `${getContent(language, "PAYPAL_INVOICES")}` },

    { route: `/wishlist`, label: "Wishlist" },
  ];
  // Hide breadcrumb on dashboard routes
  if (route === `/${preference}/dashboard`) {
    return <></>;
  }

  // Single dashboard routes
  else if (singleRoutes.some((x) => x.route === route)) {
    return (
      <div className="d-flex">
        <Link
          className={`fw-semiBold ${
            preference === "advertiser" ? "text-theme-minor" : "text-peachPink"
          } underline-on-hover text-decoration-none`}
          to={`/${preference}/dashboard`}
        >
          Dashboard
        </Link>
        &nbsp;<span>|</span>&nbsp;
        <p className="text-theme">
          {singleRoutes.find((x) => x.route === route).label}
        </p>
      </div>
    );
  }

  // Double dashboard routes
  else if (route === "/advertiser/order-content-writing") {
    return (
      <div className="d-flex">
        <Link
          className={`fw-semiBold ${
            preference === "advertiser" ? "text-theme-minor" : "text-peachPink"
          } underline-on-hover text-decoration-none`}
          to={`/${preference}/dashboard`}
        >
          Dashboard
        </Link>
        &nbsp;<span>|</span>&nbsp;
        <Link
          className={`fw-semiBold ${
            preference === "advertiser" ? "text-theme-minor" : "text-peachPink"
          } underline-on-hover text-decoration-none`}
          to={`/${preference}/content-writing`}
        >
          Content Writing
        </Link>
        &nbsp;<span>|</span>&nbsp;
        <p className="text-theme">Order Content Writing</p>
      </div>
    );
  } else if (route === "/publisher/add-website") {
    return (
      <div className="d-flex">
        <Link
          className={`fw-semiBold ${
            preference === "advertiser" ? "text-theme-minor" : "text-peachPink"
          } underline-on-hover text-decoration-none `}
          to={`/${preference}/dashboard`}
        >
          Dashboard
        </Link>
        &nbsp;<span>|</span>&nbsp;
        <Link
          className={`fw-semiBold ${
            preference === "advertiser" ? "text-theme-minor" : "text-peachPink"
          } underline-on-hover text-decoration-none`}
          to={`/${preference}/my-website`}
        >
          My Websites
        </Link>
        &nbsp;<span>|</span>&nbsp;
        <p className="text-theme">Add Website</p>
      </div>
    );
  } else if (route === "/publisher/my-website/edit") {
    return (
      <div className="d-flex">
        <Link
          className={`fw-semiBold ${
            preference === "advertiser" ? "text-theme-minor" : "text-peachPink"
          } underline-on-hover text-decoration-none `}
          to={`/${preference}/dashboard`}
        >
          Dashboard
        </Link>
        &nbsp;<span>|</span>&nbsp;
        <Link
          className={`fw-semiBold ${
            preference === "advertiser" ? "text-theme-minor" : "text-peachPink"
          } underline-on-hover text-decoration-none`}
          to={`/${preference}/my-website`}
        >
          My Websites
        </Link>
        &nbsp;<span>|</span>&nbsp;
        <p className="text-theme">Edit Website</p>
      </div>
    );
  } else if (route === "/publisher/my-website/validate") {
    return (
      <div className="d-flex">
        <Link
          className={`fw-semiBold ${
            preference === "advertiser" ? "text-theme-minor" : "text-peachPink"
          } underline-on-hover text-decoration-none `}
          to={`/${preference}/dashboard`}
        >
          Dashboard
        </Link>
        &nbsp;<span>|</span>&nbsp;
        <Link
          className={`fw-semiBold ${
            preference === "advertiser" ? "text-theme-minor" : "text-peachPink"
          } underline-on-hover text-decoration-none`}
          to={`/${preference}/my-website`}
        >
          My Websites
        </Link>
        &nbsp;<span>|</span>&nbsp;
        <p className="text-theme">Update Traffic</p>
      </div>
    );
  } else if (route === "/advertiser/paypal-invoice/create") {
    return (
      <div className="d-flex">
        <Link
          className={`fw-semiBold ${
            preference === "advertiser" ? "text-theme-minor" : "text-peachPink"
          } underline-on-hover text-decoration-none `}
          to={`/${preference}/dashboard`}
        >
          Dashboard
        </Link>
        &nbsp;<span>|</span>&nbsp;
        <Link
          className={`fw-semiBold ${
            preference === "advertiser" ? "text-theme-minor" : "text-peachPink"
          } underline-on-hover text-decoration-none`}
          to={`/${preference}/paypal-invoice`}
        >
           {getContent(language, "PAYPAL_INVOICES")}
        </Link>
        &nbsp;<span>|</span>&nbsp;
        <p className="text-theme">Create</p>
      </div>
    );
  } else if (route === "/advertiser/paypal-invoice/edit") {
    return (
      <div className="d-flex">
        <Link
          className={`fw-semiBold ${
            preference === "advertiser" ? "text-theme-minor" : "text-peachPink"
          } underline-on-hover text-decoration-none `}
          to={`/${preference}/dashboard`}
        >
          Dashboard
        </Link>
        &nbsp;<span>|</span>&nbsp;
        <Link
          className={`fw-semiBold ${
            preference === "advertiser" ? "text-theme-minor" : "text-peachPink"
          } underline-on-hover text-decoration-none`}
          to={`/${preference}/paypal-invoice`}
        >
          {getContent(language, "PAYPAL_INVOICES")}
        </Link>
        &nbsp;<span>|</span>&nbsp;
        <p className="text-theme">Edit</p>
      </div>
    );
  } else return <></>;
}
