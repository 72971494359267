import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router";
import { handleErrorBlock } from "../utils/error";
import {
  fetchLastPaypalInvoiceDetails,
  createPaypalInvoice,
} from "../services/PaypalInvoiceService";
import { toast } from "react-toastify";
import { getContent } from "../localization";
import { Helmet } from "react-helmet";
import LoadMoreLoader from "../components/LoadMoreLoader";
import EmptyComponent from "../components/EmptyComponent";
import FullScreenLoader from "../components/FullScreenLoader";
import CustomSelect from "../components/CustomSelect";
import PayPalInvoiceItem from "../components/PayPalInvoiceItem";
import { AiFillCloseCircle } from "react-icons/all";
import { FiPlus, FiXCircle } from "react-icons/fi";

import AddCustomerInfo from "../components/AddCustomerInfo";
import { async } from "@firebase/util";
import { fetchAppConfig } from "../services/ConfigService";
import DashboardTableError from "../components/DashboardComponents/DashboardTableError";

function AddPaypalInvoicePage(props) {
  const [error, setError] = useState(false);
  const [retry, setRetry] = useState(false);
  const [showCC, setShowCC] = useState(false);

  const [isLoading, setIsLoading] = useState(null);
  const [showFullScreenLoader, setShowFullScreenLoader] = useState(null);
  const [showLoadMoreLoader, setShowLoadMoreLoader] = useState(null);
  const [lastInvoice, setLastInvoice] = useState(null);
  const language = useSelector((state) => state.language);

  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(0);
  const [checked, setChecked] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState(0);
  const [paypalBillingDto, setPaypalBillingDto] = useState(null);
  const [sendInvoice, setSendInvoice] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  const previousValue = useRef(null);

  const [itemList, setItemList] = useState([
    {
      amount: 0,
      description: "",
      name: "",
      transactionFee: 0,
      totalAmount: 0,
    },
  ]);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchLastInvoice();
  }, []);

  const { transactionChargeDtoMap } = useSelector(
    (state) => state.dashboardConfig
  );

  const profileDetails = useSelector((state) => state.profileDetails);

  const fetchLastInvoice = async () => {
    setIsLoading(true);
    await fetchLastPaypalInvoiceDetails()
      .then((res) => {
        setLastInvoice(res.data);
        setPaypalBillingDto(res.data);
        if (res.data) {
          setEmail(res.data.emailAddress);
        }
        setIsLoading(false);
        setShowFullScreenLoader(true);
        previousValue.current = res.data.emailAddress;
        if (res.data.emailAddress !== profileDetails.email) {
          setShowCC(true);
          if (res.data.enableCC) {
            setChecked(true);
          }
        } else {
          setShowCC(false);
          setChecked(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setShowFullScreenLoader(true);
        setError(true);
        setLastInvoice(null);
        setPaypalBillingDto(null);
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error("Something went wrong. Please try again.");
        }
        handleErrorBlock(
          "Last invoice: Error ->",
          err,
          history,
          dispatch,
          false
        );
      });
  };

  const handleRetry = () => {
    fetchLastInvoice();
    setRetry(!retry);
  };

  let handleChange = (i, e) => {
    let newFormValues = [...itemList];
    newFormValues[i][e.target.name] = e.target.value;
    if (e.target.name == "amount") {
      const amount = e.target.value ? e.target.value : 0;
      const tax = transactionChargeDtoMap["PAYPAL"].transactionCharge;
      const total_tax = tax * 0.01 * parseFloat(amount);
      const total_amount = parseFloat(amount) + total_tax;
      newFormValues[i].totalAmount = parseFloat(total_amount).toFixed(2);
      newFormValues[i].transactionFee = parseFloat(total_tax).toFixed(2);
    }
    setItemList(newFormValues);
  };

  const addNewItem = () => {
    const newData = {
      amount: 0,
      description: "",
      name: "",
      transactionFee: 0,
      totalAmount: 0,
    };

    setItemList([...itemList, newData]);
  };

  let removeItem = (i) => {
    let newFormValues = [...itemList];
    newFormValues.splice(i, 1);
    setItemList(newFormValues);
  };

  const saveCustomerInfo = (params) => {
    console.log(params);
    setPaypalBillingDto(params);
    setEmail(params.emailAddress);
  };

  const subTotal = itemList.reduce(
    (total, currentValue) =>
      (total =
        total + parseFloat(currentValue.amount ? currentValue.amount : 0)),
    0
  );
  const totalTax = itemList.reduce(
    (total, currentValue) =>
      (total =
        total + parseFloat(currentValue.totalAmount - currentValue.amount)),
    0
  );
  const grandTotal = itemList.reduce(
    (total, currentValue) =>
      (total =
        total +
        parseFloat(currentValue.totalAmount ? currentValue.totalAmount : 0)),
    0
  );

  let handleSubmit = async (event) => {
    event.preventDefault();
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (
      !paypalBillingDto.emailAddress ||
      paypalBillingDto.emailAddress.length === 0
    ) {
      toast.error("You have not enter your email");
      return;
    } else if (
      !paypalBillingDto.emailAddress ||
      regex.test(paypalBillingDto.emailAddress) === false
    ) {
      toast.error("You have not enter Valid email");
      return;
    }

    paypalBillingDto["enableCC"] = checked;
    const data = {
      sendInvoice: sendInvoice,
      amount: parseFloat(subTotal).toFixed(2),
      totalAmount: parseFloat(grandTotal).toFixed(2),
      transactionFee: parseFloat(totalTax).toFixed(2),
      transactionChargeDto: {
        currencyType: "USD",
        transactionCharge: transactionChargeDtoMap["PAYPAL"].transactionCharge,
      },
      itemList: itemList,
      paypalBillingDto: paypalBillingDto,
    };

    setIsLoading(true);
    await createPaypalInvoice(data)
      .then((res) => {
        if (res.status === 200 && res.data) {
          setIsLoading(false);
          console.log(res.data);
          if (res.data?.invoiceUrl) {
            window.open(res.data.invoiceUrl, "_blank");
          }
          history.push(`/advertiser/paypal-invoice`);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error("Something went wrong. Please try again.");
        }
      });
  };

  const onBlurEmail = (e) => {
    setEmail(e.target.value);

    setChecked(lastInvoice.enableCC);
    if (!paypalBillingDto) {
      resetData(e);
    } else {
      paypalBillingDto.emailAddress = e.target.value;
      setPaypalBillingDto(paypalBillingDto);
    }
    if (e.target.value !== profileDetails.email) {
      setShowCC(true);
    } else {
      setShowCC(false);
    }

    if (previousValue.current !== e.target.value) {
      resetData(e);
    }

    previousValue.current = e.target.value;
  };

  const resetData = (e) => {
    setPaypalBillingDto({
      billingAddress: {
        addressLine1: null,
        addressLine2: null,
        country: null,
        additionalInfo: null,
        postalCode: null,
        vatNumber: null,
      },
      companyName: null,
      emailAddress: e.target.value,
      firstName: null,
      lastName: null,
    });
  };
  return (
    <>
      <Helmet>
        <title>{getContent(language, "META_TITLE_ADD_PAYPAL_INVOICE")}</title>
        <meta
          name="description"
          content={getContent(language, "META_DESCRIPTION_ADD_PAYPAL_INVOICE")}
        />
      </Helmet>

      {showFullScreenLoader === true && error === false && (
        <div className="card mt-2">
          <div className="card-body invoice-form-container">
            <div className=" d-flex flex-column " style={{ flex: 1 }}>
              <div className="d-flex justify-content-end ">
                <FloatingLabel
                  controlId="currency"
                  label="Currency"
                  className="mb-3"
                  style={{ width: 120 }}
                >
                  <Form.Control
                    type="text"
                    value={transactionChargeDtoMap["PAYPAL"].currencyType}
                    disabled
                  />
                </FloatingLabel>
              </div>
              <p style={{ fontSize: "1.125rem" }}>Bill to:</p>
              <FloatingLabel
                controlId="email"
                label="Email Address"
                className=""
              >
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => {
                    onBlurEmail(e);
                  }}
                />
              </FloatingLabel>
              {showCC ? (
                <Form.Check
                  className="mt-2 mb-2"
                  label={`CC : ${profileDetails.email}`}
                  name="enableCC"
                  type={"checkbox"}
                  checked={checked}
                  onChange={() => {
                    setChecked(!checked);
                  }}
                />
              ) : null}
              {paypalBillingDto && (
                <div className="d-flex flex-column text-theme">
                  <div style={{ fontSize: ".8125rem" }}>
                    {paypalBillingDto?.companyName ? (
                      <>
                        {paypalBillingDto?.companyName}
                        <br />
                      </>
                    ) : null}

                    {paypalBillingDto?.firstName ||
                    paypalBillingDto?.lastName ? (
                      <>
                        {paypalBillingDto?.firstName}{" "}
                        {paypalBillingDto?.lastName}
                        <br />
                      </>
                    ) : null}

                    {paypalBillingDto.billingAddress?.addressLine1}
                    {paypalBillingDto.billingAddress?.addressLine1 ? (
                      <br />
                    ) : null}
                    {paypalBillingDto.billingAddress?.addressLine2}
                    {paypalBillingDto.billingAddress?.addressLine2 ? (
                      <br />
                    ) : null}
                    {paypalBillingDto.billingAddress?.postalCode}
                    {paypalBillingDto.billingAddress?.postalCode ? ", " : null}
                    {paypalBillingDto.billingAddress?.country}

                    {paypalBillingDto.billingAddress?.vatNumber ? (
                      <>
                        <br />
                        VAT Number: {paypalBillingDto.billingAddress?.vatNumber}
                      </>
                    ) : null}
                  </div>
                  <b
                    style={{
                      fontSize: 14,
                      color: "#34006b",
                      width: "fit-content",
                    }}
                    className="mt-2 cursor-pointer"
                    onClick={() => {
                      setModalShow(true);
                    }}
                  >
                    Edit customer information
                  </b>
                </div>
              )}
              <div
                className="mb-3 mt-3 text-theme"
                style={{ fontSize: "1.125rem" }}
              >
                Items
              </div>
              <form onSubmit={handleSubmit}>
                <div>
                  {itemList &&
                    itemList.length > 0 &&
                    itemList.map((item, index) => {
                      return (
                        <div className="invoice-item-container">
                          <div className="row">
                            <div className="col-sm-6">
                              <FloatingLabel
                                controlId="name"
                                label="Item Name *"
                                className="mb-3"
                              >
                                <Form.Control
                                  name="name"
                                  type="text"
                                  value={item.name || ""}
                                  onChange={(e) => handleChange(index, e)}
                                  required
                                />
                              </FloatingLabel>
                            </div>
                            <div className="col-sm-2">
                              <FloatingLabel
                                controlId="amount"
                                label="Price *"
                                className="mb-3"
                              >
                                <Form.Control
                                  name="amount"
                                  type="number"
                                  min={0}
                                  value={item.amount || ""}
                                  onChange={(e) => handleChange(index, e)}
                                  required
                                />
                              </FloatingLabel>
                            </div>
                            <div className="col-sm-2">
                              <FloatingLabel
                                controlId="Quantity"
                                label="Quantity"
                                className="mb-3"
                              >
                                <Form.Control
                                  name="quantity"
                                  type="text"
                                  value={"1"}
                                  disabled
                                />
                              </FloatingLabel>
                            </div>

                            <div className="col-sm-2">
                              <FloatingLabel
                                controlId="tax"
                                label="Tax"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  min={0}
                                  placeholder="No Tax"
                                  value={`${transactionChargeDtoMap["PAYPAL"].transactionCharge} %`}
                                  disabled
                                />
                              </FloatingLabel>
                            </div>

                            <div className="col-sm-12">
                              <FloatingLabel
                                controlId="description"
                                label="Description"
                                className="mb-3"
                              >
                                <Form.Control
                                  className="text-theme"
                                  as="textarea"
                                  name="description"
                                  value={item.description || ""}
                                  onChange={(e) => handleChange(index, e)}
                                  style={{ height: "100px" }}
                                />
                              </FloatingLabel>
                            </div>
                          </div>
                          <div className="text-end">
                            <strong
                              style={{ color: "#687173", fontSize: ".8125rem" }}
                            >
                              Amount:{" "}
                              <span
                                className="ms-2 text-theme"
                                style={{ fontSize: ".9375rem" }}
                              >
                                ${parseFloat(item.totalAmount).toFixed(2)}
                              </span>
                            </strong>
                          </div>
                          {index > 0 && (
                            <FiXCircle
                              className="position-absolute top-50 cursor-pointer"
                              style={{
                                right: -35,
                                fontSize: 25,
                                color: "#D41E45",
                              }}
                              onClick={() => removeItem(index)}
                            />
                          )}
                        </div>
                      );
                    })}
                </div>
                <div
                  onClick={addNewItem}
                  className="cursor-pointer text-theme"
                  style={{
                    width: "fit-content",
                    fontWeight: 600,
                    fontSize: ".9375rem",
                  }}
                >
                  + Add Item or Service
                </div>
                <hr />
                <div className="row mt-3 mb-3">
                  <div className="col-md-9"></div>
                  <div className="col-md-3 d-flex flex-row justify-content-between">
                    <b style={{ color: "#687173", fontSize: ".8125rem" }}>
                      Sub Total :{" "}
                    </b>
                    <b
                      className="me-3 text-theme"
                      style={{ fontSize: ".9375rem" }}
                    >
                      ${parseFloat(subTotal).toFixed(2)}
                    </b>
                  </div>
                </div>
                <div className="row mt-3 mb-3">
                  <div className="col-md-9"></div>
                  <div className="col-md-3 d-flex flex-row justify-content-between">
                    <b style={{ color: "#687173", fontSize: ".8125rem" }}>
                      Total Tax :{" "}
                    </b>
                    <b
                      className="me-3 text-theme"
                      style={{ fontSize: ".9375rem" }}
                    >
                      ${parseFloat(totalTax).toFixed(2)}
                    </b>
                  </div>
                </div>
                <div className="row mt-3 mb-3">
                  <div className="col-md-9"></div>
                  <div className="col-md-3 d-flex flex-row justify-content-between">
                    <b style={{ color: "#687173", fontSize: ".8125rem" }}>
                      Total Amount :{" "}
                    </b>
                    <b
                      className="me-3 text-theme"
                      style={{ fontSize: ".9375rem" }}
                    >
                      ${parseFloat(grandTotal).toFixed(2)}
                    </b>
                  </div>
                </div>
                <hr />
                <div className="text-end">
                  <button
                    type="submit"
                    name="submit"
                    className="btn text-white m-3 mt-0 fw-bold bg-theme-minor px-4 mt-4 addWebButton shadow"
                    onClick={() => setSendInvoice(false)}
                  >
                    Save as Draft
                  </button>

                  <button
                    type="submit"
                    name="submit"
                    className="btn text-white m-3 mt-0 fw-bold bg-theme-minor px-4 mt-4 addWebButton shadow"
                    onClick={() => setSendInvoice(true)}
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>

          {modalShow ? (
            <AddCustomerInfo
              show={modalShow}
              onHide={() => setModalShow(false)}
              saveCustomerInfo={saveCustomerInfo}
              paypalBillingDto={paypalBillingDto}
            />
          ) : null}
        </div>
      )}

      {error === true && isLoading === false && (
        <DashboardTableError onRetry={handleRetry} />
      )}
      {isLoading ? <FullScreenLoader /> : null}
    </>
  );
}

export default AddPaypalInvoicePage;
