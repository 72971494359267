import React from "react";
import { CopperLoading } from "respinner";

const LoadMoreLoader = ({ style }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center mb-3 px-3"
      style={style}
    >
      <div className="mr-3">
        <CopperLoading size={20} fill="#34006B" />
      </div>

      <p
        className="text-theme fw-bold my-auto"
        style={{ fontSize: "0.8em", marginLeft: "5px" }}
      >
        LOADING
      </p>
    </div>
  );
};

export default LoadMoreLoader;
