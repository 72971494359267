import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { setRazorpayPaymentId } from "../redux/auth/ActionCreator";

const RazorpayPaymentRedirect = () => {
  const dispatch = useDispatch();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  console.log(query, "query");
  const razorpayPaymentId = query.get("razorpay_payment_link_id");
  console.log(razorpayPaymentId, "razorpayPaymentId");
  useEffect(() => {
    localStorage.setItem("razorpayPaymentId", razorpayPaymentId);
    dispatch(setRazorpayPaymentId(razorpayPaymentId));
    localStorage.setItem("addFundsClose", "true");
    const path =  localStorage.getItem("REDIRECT_PATH");
    window.location.href=path;
  }, [razorpayPaymentId]);

  return <></>;
};

export default RazorpayPaymentRedirect;
