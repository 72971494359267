import React, { useState } from "react";
import ContactUsHero from "../../assets/contacthero.webp";
import Typography from "../../components/Typography";
import { FaArrowRight, FiMail, FiMapPin } from "react-icons/all";
import PrimaryButton from "../../components/PrimaryButton";
import Colors from "../../constants/colors";
import { cardIconStyle } from "../../constants/content";
import SignupBanner from "../../components/SignupBanner";
import SkypeIcon from "../../assets/skype-logo.png";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { postContactUsQuery } from "../../services/ContactUsService";
import { useDispatch, useSelector } from "react-redux";
import alertLottie from "../../assets/Lottie/prod-error.png";
import FullScreenLoader from "../../components/FullScreenLoader";
import { handleErrorBlock } from "../../utils/error";
import ProgressiveImage from "../../components/ProgressiveImage";
import { Helmet } from "react-helmet";
import { getContent } from "../../localization/index";
import ModalWrapper from "../../components/ModalWrapper";
import successLottie from "../../assets/Lottie/tick.png";
import { EVENT_NAME, GAevent } from "../../utils/Events";

const FormInputNames = {
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  message: "message",
};

const ContactUs = () => {
  const { contactUsQueryTypeList } = useSelector(
    (state) => state.dashboardConfig
  );
  const supportMail = useSelector(
    (state) => state.dashboardConfig.supportEmail
  );

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const [showFullScreenLoader, setShowFullScreenLoader] = useState(false);

  const customStyles = {
    content: {
      textAlign: "center",
      paddingLeft: "32px",
      paddingRight: "32px",
      padding: "20px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none",
      borderRadius: "8px",
    },
    overlay: {
      background: "rgba(0,0,0,0.4)",
      zIndex: 2,
    },
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: alertLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [queryType, setQueryType] = useState(
    contactUsQueryTypeList ? contactUsQueryTypeList[0] : ""
  );

  const language = useSelector((state) => state.language);

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    reValidateMode: "onSubmit",
  });

  const handleInvalidForm = (errors) => {
    Object.keys(FormInputNames).some((name) => {
      if (errors[name]) {
        errors[name].message && toast.error(errors[name].message);
        return true;
      }
      return false;
    });
  };

  const onFormSubmit = async ({ email, firstName, lastName, message }) => {
    GAevent(EVENT_NAME.contact_us_page, ["submit_cta"]);
    try {
      setShowFullScreenLoader(true);

      const response = await postContactUsQuery({
        email,
        message,
        name: `${firstName} ${lastName}`,
        type: queryType,
      });

      setShowFullScreenLoader(false);
      openModal();

      reset();
    } catch (e) {
      handleErrorBlock("Contact Us: Error ->", e, history, dispatch);

      setShowFullScreenLoader(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{getContent(language, "META_TITLE_CONTACT_US")}</title>
        <meta
          name="description"
          content={getContent(language, "META_DESCRIPTION_CONTACT_US")}
        />
        <link rel="canonical" href="/contact-us" />
        <meta
          property="og:title"
          content={getContent(language, "META_TITLE_CONTACT_US")}
        />
        <meta
          property="og:site_name"
          content={getContent(language, "META_TITLE_HOME")}
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:description"
          content={getContent(language, "META_DESCRIPTION_CONTACT_US")}
        />
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:image"
          content="https://blog.outreachmantra.com/wp-content/uploads/2022/10/OutreachMantra-OG-Image.png"
        />
      </Helmet>
      {showFullScreenLoader && <FullScreenLoader />}

      <ModalWrapper
        title={getContent(language, "MODAL_CONTACT_US_SUBMIT_SUCCESS")}
        icon={successLottie}
        cta={[
          {
            title: getContent(language, "MODAL_GO_BACK_CTA"),
            backgroundColor: Colors.lavender,
            onClick: () => closeModal(),
          },
        ]}
        isOpen={modalOpen}
        close={closeModal}
        outsideClickClose={true}
      />

      {/* <Modal
        style={customStyles}
        isOpen={modalOpen}
        onRequestClose={closeModal}
      >
        <div>
          <Lottie options={defaultOptions} height={150} width={150} />
        </div>
        <p className="text-theme fw-bold">
          Thank You! Our team will reply in the next 24 hours.
        </p>

        <PrimaryButton onClick={() => closeModal()}>Go Back</PrimaryButton>
      </Modal> */}

      <NavBar />

      <main className="container">
        <section className="row">
          <div className="col-sm-7 mt-3 mt-md-0 d-flex flex-column justify-content-end ps-md-5 pb-5 position-relative ">
            <div className="contact-circle-translucent" />
            <Typography
              variant="header"
              text={getContent(language, "CONTACT_US_HERO_SECTION_HEADER")}
            />
            <Typography
              text={getContent(language, "CONTACT_US_HERO_SECTION_SUBHEADER")}
              className=""
              children=""
            />
            <div className="d-flex flex-row flex-wrap">
              <div className="d-flex flex-row align-items-center">
                <div className="circle-icon-container">
                  <div
                    className="cardCircle position-relative"
                    style={{ backgroundColor: Colors.secondaryColor }}
                  />
                  <img src={SkypeIcon} className="contact-skype-icon" alt="" />
                </div>

                <a
                  onClick={() => {
                    GAevent(EVENT_NAME.contact_us_page, ["skype"]);
                  }}
                  href="skype:live:.cid.8618d16e3a52ef77?add"
                  className="text-theme fw-bold text-decoration-none underline-on-hover"
                >
                  {getContent(language, "CONTACT_US_SKYPE_ID")}
                </a>
              </div>
              <div className=" mx-md-4 mx-0 d-flex flex-row align-items-center">
                <div className="circle-icon-container">
                  <div
                    className="cardCircle"
                    style={{ backgroundColor: "#D41E45" }}
                  />
                  <FiMail style={cardIconStyle} size={40} />
                </div>
                <a
                  href={`mailto:${supportMail}`}
                  className="text-theme fw-bold text-decoration-none underline-on-hover c-ptr"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    GAevent(EVENT_NAME.contact_us_page, ["support_email"]);
                  }}
                >
                  {supportMail}
                </a>
              </div>
              <div className="d-flex flex-row align-items-center mt-md-3">
                <div className="circle-icon-container">
                  <div
                    className="cardCircle"
                    style={{ backgroundColor: "#86C28B" }}
                  />
                  <FiMapPin style={cardIconStyle} size={40} />
                </div>
                <div className="text-theme fw-bold">
                  {getContent(language, "CONTACT_US_ADDRESS")}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 pt-5 position-relative contactUs-img-container ">
            <div className="contact-circle-1 shadow" />
            <ProgressiveImage
              className="mb-5 mt-3 w-100 px-lg-5 px-sm-2 px-0"
              src={getContent(language, "IMAGE_staticContactHero")}
              placeholder={ContactUsHero}
              alt="Contact Us Hero"
            />
          </div>
        </section>

        <section className="d-flex px-md-4 mb-5">
          <div className="d-flex flex-wrap g-5">
            <div className="pb-2  col-md-5 mb-5 mb-md-0 d-flex flex-column justify-content-between">
              <div>
                <Typography variant="header" className="contact-faq-header">
                  {getContent(language, "CONTACT_US_FAQ_HEADER")}
                </Typography>

                <p className="contact-faq-desc text-theme">
                  {getContent(language, "CONTACT_US_FAQ_SUBHEADER")}
                </p>
                <div
                  className="d-flex justify-content-between align-items-center my-4 me-md-5 contact-faq-box c-ptr"
                  onClick={() => {
                    GAevent(EVENT_NAME.contact_us_page, ["publishers_faqs"]);
                    history.push("/faq", { faqTabName: "PUBLISHERS" });
                  }}
                >
                  <div className="d-flex flex-column px-4 py-3">
                    <h5 className="text-theme mb-0 fw-bold">
                      {getContent(language, "PUBLISHERS")}
                    </h5>
                    <div className="text-theme check-faq-text">
                      {getContent(
                        language,
                        "CONTACT_US_PUBLISHERS_DESCRIPTION"
                      )}
                    </div>
                  </div>
                  <FaArrowRight
                    color={Colors.secondaryColor}
                    size={24}
                    className="me-3"
                  />
                </div>
                <div
                  className="d-flex justify-content-between align-items-center my-4 me-md-5 contact-faq-box c-ptr"
                  onClick={() => {
                    GAevent(EVENT_NAME.contact_us_page, ["advertisers_faqs"]);
                    history.push("/faq", { faqTabName: "ADVERTISERS" });
                  }}
                >
                  <div className="d-flex flex-column px-4 py-3">
                    <h5 className="text-theme mb-0 fw-bold">
                      {getContent(language, "ADVERTISERS")}
                    </h5>
                    <div className="text-theme check-faq-text ">
                      {getContent(
                        language,
                        "CONTACT_US_ADVERTISERS_DESCRIPTION"
                      )}
                    </div>
                  </div>
                  <FaArrowRight
                    size={24}
                    color={Colors.secondaryColor}
                    className="me-3"
                  />
                </div>
                <div
                  className="d-flex justify-content-between align-items-center my-4 mb-0 me-md-5 contact-faq-box c-ptr"
                  onClick={() => {
                    GAevent(EVENT_NAME.contact_us_page, [
                      "content_writing_faqs",
                    ]);
                    history.push("/faq", { faqTabName: "CONTENT WRITING" });
                  }}
                >
                  <div className="d-flex flex-column px-4 py-3 pb-3">
                    <h5 className="text-theme mb-0 fw-bold">
                      {getContent(language, "CONTENT_WRITING")}
                    </h5>
                    <div className="text-theme check-faq-text">
                      {getContent(
                        language,
                        "CONTACT_US_CONTENT_WRITING_DESCRIPTION"
                      )}
                    </div>
                  </div>
                  <FaArrowRight
                    color={Colors.secondaryColor}
                    size={24}
                    className="me-3"
                  />
                </div>
              </div>
            </div>
            <div></div>
            <form
              style={{
                background: "#ffffff7e",
                borderRadius: "10px",
              }}
              className="col-md-7 p-5 px-3 px-md-5 position-relative"
              onSubmit={handleSubmit(onFormSubmit, handleInvalidForm)}
              noValidate
            >
              <div className="contact-circle-translucent-2" />
              <div className="mb-5">
                <Typography
                  variant="header"
                  text={getContent(language, "CONTACT_US_FORM_HEADER")}
                />

                <p className=" mb-0 heading-mid text-theme w-100">
                  {getContent(language, "CONTACT_US_FORM_SUBHEADER")}
                </p>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <input
                    className="form-control contact-us-input p-2"
                    name="firstname"
                    placeholder={getContent(
                      language,
                      "PH_CONTACT_US_FIRST_NAME"
                    )}
                    {...register(FormInputNames.firstName, {
                      required: getContent(language, "ERR_CONTACT_FIRST_NAME"),
                    })}
                  />

                  <span
                    className={`${
                      errors[FormInputNames.firstName] ? "visible" : "invisible"
                    } text-danger ps-1`}
                  >
                    {errors[FormInputNames.firstName]?.message}
                  </span>
                </div>
                <div className="col-sm-6 ">
                  <input
                    className="form-control contact-us-input  p-2"
                    placeholder={getContent(
                      language,
                      "PH_CONTACT_US_LAST_NAME"
                    )}
                    name="lastname"
                    {...register(FormInputNames.lastName, {
                      required: getContent(language, "ERR_CONTACT_LAST_NAME"),
                    })}
                  />
                  <span
                    className={`${
                      errors[FormInputNames.lastName] ? "visible" : "invisible"
                    } text-danger ps-1`}
                  >
                    {errors[FormInputNames.lastName]?.message}
                  </span>
                </div>
              </div>
              <input
                className="form-control contact-us-input p-2"
                placeholder={getContent(language, "PH_CONTACT_US_EMAIL")}
                name="email"
                {...register(FormInputNames.email, {
                  required: getContent(language, "ERR_CONTACT_EMAIL"),
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: getContent(language, "ERR_CONTACT_EMAIL"),
                  },
                })}
                type="email"
              />
              <span
                className={`${
                  errors[FormInputNames.email] ? "visible" : "invisible"
                } text-danger ps-1`}
              >
                {errors[FormInputNames.email]?.message}
              </span>

              <div className="p-0">
                <select
                  className="form-select contact-us-select"
                  aria-label="Default select example"
                  style={{ color: "#6C757D" }}
                  value={queryType}
                  onChange={(e) => setQueryType(e.target.value)}
                >
                  {contactUsQueryTypeList?.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>

              <textarea
                className="form-control contact-us-input mt-3"
                style={{ marginTop: 20 }}
                placeholder={getContent(language, "PH_CONTACT_US_MESSAGE")}
                cols="30"
                rows="4"
                name="message"
                {...register(FormInputNames.message, {
                  required: getContent(language, "ERR_CONTACT_MSG"),
                })}
              />
              <span
                className={`${
                  errors[FormInputNames.message] ? "visible" : "invisible"
                } text-danger ps-1`}
              >
                {errors[FormInputNames.message]?.message}
              </span>
              <div className="text-center  pt-3">
                <PrimaryButton
                  type="submit"
                  className="
              contact-submit-button "
                >
                  SUBMIT
                </PrimaryButton>
              </div>
            </form>
          </div>
        </section>
        <div className="mt-5 pt-5">
          <SignupBanner />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default ContactUs;
