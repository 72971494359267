import Bowser from "bowser";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";

export const CURRENT_URL = window.location.origin;
let userData = "";

export const initGA = (userId) => {
  console.log(isMobile());
  userData = userId;
  ReactGA.initialize("G-4MD0GS3QY6", {
    debug: false,
    titleCase: false,
    gaOptions: {
      platform: isMobile() ? "MOBILE" : "WEB",
    },
  });
};

export const GApageView = (page) => {
  if (process.env.REACT_APP_ENVIRONMENT !== "DEV") {
    ReactGA.send({ hitType: "pageview", page: page, title: page });
  }
};

export const GAevent = (eventName, eventParams) => {
  let event_name = EVENT_NAME[eventName];
  let event_params = EVENT_PARAMS[eventName];
  let event = {};
  eventParams &&
    eventParams.length > 0 &&
    eventParams.map((item, index) => {
      event[event_params[index]] = item;
    });
  event["user_unique_id"] = userData ? userData : "N/A";
  if (process.env.REACT_APP_ENVIRONMENT !== "DEV") {
    ReactGA.event(event_name, event);
  }
};

export const getBrowserName = () => {
  const browser = Bowser.getParser(window.navigator.userAgent).getBrowser()
    .name;
  return browser;
};

export const getBrowserVersion = () => {
  const version = parseInt(
    Bowser.getParser(window.navigator.userAgent)
      .getBrowser()
      .version.split(".")[0]
  );

  return version;
};

export const getDeviceId = () => {
  const deviceId = MediaDeviceInfo.deviceId;
  return deviceId;
};

export const isMobile = () => {
  return window.matchMedia && window.matchMedia("(max-width: 480px)").matches;
};

export const EVENT_NAME = {
  error_alert: "error_alert",
  header_logo_icon: "header_logo_icon",
  header_our_story: "header_our_story",
  header_solutions: "header_solutions",
  header_solutions_advertisers: "header_solutions_advertisers",
  header_solutions_publishers: "header_solutions_publishers",
  header_solutions_content_writing: "header_solutions_content_writing",
  header_blog: "header_blog",
  header_faqs: "header_faqs",
  header_contact_us: "header_contact_us",
  header_login_cta: "header_login_cta",
  header_signup_cta: "header_signup_cta",
  header_advertisers_video: "header_advertisers_video",
  header_publishers_video: "header_publishers_video",
  home_page_brands: "home_page_brands",
  home_page_testimonials: "home_page_testimonials",
  footer_sign_up: "footer_sign_up",
  footer_logo_icon: "footer_logo_icon",
  footer_home_text: "footer_home_text",
  footer_our_story: "footer_our_story",
  footer_blog: "footer_blog",
  footer_faqs: "footer_faqs",
  footer_contact_us: "footer_contact_us",
  footer_solutions_advertisers: "footer_solutions_advertisers",
  footer_solutions_publishers: "footer_solutions_publishers",
  footer_solutions_content_writing: "footer_solutions_content_writing",
  footer_facebook: "footer_facebook",
  footer_linkedin: "footer_linkedin",
  footer_twitter: "footer_twitter",
  footer_instagram: "footer_instagram",
  footer_youtube: "footer_youtube",
  footer_pinterest: "footer_pinterest",
  footer_support_email: "footer_support_email",
  footer_skype: "footer_skype",
  footer_refund_policy: "footer_refund_policy",
  footer_terms_and_conditions: "footer_terms_and_conditions",
  footer_privacy_policy: "footer_privacy_policy",
  footer_data_deletion_policy: "footer_data_deletion_policy",
  solutions_advertisers_page: "solutions_advertisers_page",
  solutions_publishers_page: "solutions_publishers_page",
  solutions_content_writing_page: "solutions_content_writing_page",
  faqs_page: "faqs_page",
  contact_us_page: "contact_us_page",
  login_page: "login_page",
  signup_page: "signup_page",
  switch_option: "switch_option",
  myprofile: "myprofile",
  my_profile_click: "myprofile",
  notification_option: "notification_option",
  landing_advertiser: "landing_advertiser",
  advertiser_howitworks: "advertiser_howitworks",
  advertiser_user_cart: "advertiser_user_cart",
  advertiser_wishlist: "advertiser_wishlist",
  advertiser_addfunds: "advertiser_addfunds",
  advertiser_menu_dashboard: "advertiser_menu_dashboard",
  advertiser_menu_marketplace: "advertiser_menu_marketplace",
  advertiser_menu_content_writing: "advertiser_menu_content_writing",
  advertiser_menu_myorders: "advertiser_menu_myorders",
  advertiser_menu_paypal_invoices: "advertiser_menu_paypal_invoices",
  advertiser_menu_billing_funds: "advertiser_menu_billing_funds",
  advertiser_menu_reports: "advertiser_menu_reports",
  advertiser_refer_earn: "advertiser_refer_earn",
  advertiser_need_help: "advertiser_need_help",
  advertiser_order_journey: "advertiser_order_journey",
  advertiser_order_website: "advertiser_order_website",
  advertiser_order_action: "advertiser_order_action",
  advertiser_order_view_orders: "advertiser_order_view_orders",
  advertiser_order_load_more: "advertiser_order_load_more",
  marketplace_create_filter: "marketplace_create_filter",
  marketplace_tags: "marketplace_tags",
  marketplace_sort: "marketplace_sort",
  marketplace_manual_filter: "marketplace_manual_filter",
  marketplace_search: "marketplace_search",
  marketplace_card: "marketplace_card",
  marketplace_card_click: "marketplace_card",
  marketplace_card_checkout: "marketplace_card",
  marketplace_load_more: "marketplace_load_more",
  advertiser_contentorder_journey: "advertiser_contentorder_journey",
  advertiser_contentorder_action: "advertiser_contentorder_action",
  advertiser_contentorder_load_more: "advertiser_contentorder_load_more",
  paypal_invoices: "paypal_invoices",
  paypal_invoices_action: "paypal_invoices_action",
  billing_funds_txn_details: "billing_funds_txn_details",
  invite_friends: "invite_friends",
  invite_friends_code: "invite_friends",
  invite_friends_email: "invite_friends",
  landing_publisher: "landing_publisher",
  publisher_howitworks: "publisher_howitworks",
  publisher_menu_dashboard: "publisher_menu_dashboard",
  publisher_menu_mywebsites: "publisher_menu_mywebsites",
  publisher_menu_myorders: "publisher_menu_myorders",
  publisher_menu_payments: "publisher_menu_payments",
  publisher_menu_reports: "publisher_menu_reports",
  publisher_refer_earn: "publisher_refer_earn",
  publisher_need_help: "publisher_need_help",
  publisher_order_journey: "publisher_order_journey",
  publisher_order_website: "publisher_order_website",
  publisher_order_action: "publisher_order_action",
  publisher_order_view_orders: "publisher_order_view_orders",
  publisher_order_load_more: "publisher_order_load_more",
  mywebsite_add: "mywebsite_add",
  mywebsite_card_edit: "mywebsite_card_edit",
  mywebsite_check_eligibility: "mywebsite_check_eligibility",
  mywebsite_step_1_download_file: "mywebsite_step_1_download_file",
  mywebsite_card_activate: "mywebsite_card_activate",
  mywebsite_card_deactivate: "mywebsite_card_deactivate",
  mywebsite_step_1_copy_tag: "mywebsite_step_1_copy_tag",
  mywebsite_step_1_verify: "mywebsite_step_1_verify",
  mywebsite_step_1_reload: "mywebsite_step_1_reload",
  mywebsite_step_2_submit: "mywebsite_step_2_submit",
  mywebsite_step_2_update: "mywebsite_step_2_update",

  payments_add: "payments_add",
  payments_action: "payments_action",
  payments_txn_details: "payments_txn_details",
  landing_our_story: "landing_our_story",
  landing_solutions: "landing_solutions",
  landing_solutions_advertisers: "landing_solutions_advertisers",
  landing_solutions_publishers: "landing_solutions_publishers",
  landing_solutions_content_writing: "landing_solutions_content_writing",
  landing_blog: "landing_blog",
  landing_faqs: "landing_faqs",
  landing_contact_us: "landing_contact_us",
  landing_refund_policy: "landing_refund_policy",
  landing_terms_conditions: "landing_terms_conditions",
  landing_privacy_policy: "landing_privacy_policy",
  landing_data_deletion_policy: "landing_data_deletion_policy",
  landing_data_deletion_policy1: "landing_data_deletion_policy",
};

export const EVENT_PARAMS = {
  error_alert: ["error_name", "status_code", "error_message"],
  header_logo_icon: ["page_url"],
  header_our_story: ["page_url"],
  header_solutions: ["page_url"],
  header_solutions_advertisers: ["page_url"],
  header_solutions_publishers: ["page_url"],
  header_solutions_content_writing: ["page_url"],
  header_blog: ["page_url"],
  header_faqs: ["page_url"],
  header_contact_us: ["page_url"],
  header_login_cta: ["page_url"],
  header_signup_cta: ["page_url"],
  header_advertisers_video: ["page_url"],
  header_publishers_video: ["page_url"],
  home_page_brands: ["click_event"],
  home_page_testimonials: ["click_event", "testimonial_name"],
  footer_sign_up: ["page_url"],
  footer_logo_icon: ["page_url"],
  footer_home_text: ["page_url"],
  footer_our_story: ["page_url"],
  footer_blog: ["page_url"],
  footer_faqs: "footer_faqs",
  footer_contact_us: ["page_url"],
  footer_solutions_advertisers: ["page_url"],
  footer_solutions_publishers: ["page_url"],
  footer_solutions_content_writing: ["page_url"],
  footer_facebook: ["page_url"],
  footer_linkedin: ["page_url"],
  footer_twitter: ["page_url"],
  footer_instagram: ["page_url"],
  footer_youtube: ["page_url"],
  footer_pinterest: ["page_url"],
  footer_support_email: ["page_url"],
  footer_skype: ["page_url"],
  footer_refund_policy: ["page_url"],
  footer_terms_and_conditions: ["page_url"],
  footer_privacy_policy: ["page_url"],
  footer_data_deletion_policy: ["page_url"],
  solutions_advertisers_page: ["click_event"],
  solutions_publishers_page: ["click_event"],
  solutions_content_writing_page: ["click_event"],
  faqs_page: ["click_event"],
  contact_us_page: ["click_event"],
  login_page: ["click_event"],
  signup_page: ["click_event"],
  switch_option: ["current_profile"],
  myprofile: ["page_url", "current_profile"],
  my_profile_click: ["click_event"],
  advertiser_howitworks: ["video_link"],
  advertiser_user_cart: ["page_url"],
  advertiser_wishlist: ["page_url"],
  advertiser_menu_dashboard: ["page_url"],
  advertiser_menu_marketplace: ["page_url"],
  advertiser_menu_myorders: ["page_url"],
  advertiser_menu_content_writing: ["page_url"],
  advertiser_menu_paypal_invoices: ["page_url"],
  advertiser_menu_billing_funds: ["page_url"],
  advertiser_menu_reports: ["page_url"],
  advertiser_refer_earn: ["page_url"],
  advertiser_need_help: ["click_event"],
  advertiser_order_journey: ["order_id", "order_status"],
  advertiser_order_website: ["website_name"],
  advertiser_order_action: ["action_text"],
  advertiser_order_view_orders: ["page_url"],
  advertiser_order_load_more: ["page_number"],
  marketplace_tags: ["tag_name"],
  marketplace_sort: ["sort_value"],
  marketplace_manual_filter: ["filter_status"],
  marketplace_search: ["search_text"],
  marketplace_card_click: ["click_event", "website_url"],
  marketplace_card_checkout: ["click_event", "page_url"],
  marketplace_card: ["click_event"],
  marketplace_load_more: ["page_number"],
  advertiser_contentorder_journey: ["order_id"],
  advertiser_contentorder_action: ["action_text"],
  advertiser_contentorder_load_more: ["page_number"],
  paypal_invoices: ["click_event"],
  paypal_invoices_action: ["action_name"],
  billing_funds_txn_details: ["txn_id"],
  invite_friends: ["click_event", "resolution"],
  invite_friends_code: ["click_event", "invite_code"],
  invite_friends_email: ["click_event", "email_id"],

  publisher_howitworks: ["video_link"],
  publisher_menu_dashboard: ["page_url"],
  publisher_menu_mywebsites: ["page_url"],
  publisher_menu_myorders: ["page_url"],
  publisher_menu_payments: ["page_url"],
  publisher_menu_reports: ["page_url"],
  publisher_refer_earn: ["page_url"],
  publisher_need_help: ["click_event"],
  publisher_order_journey: ["order_id", "order_status"],
  publisher_order_website: ["website_name"],
  publisher_order_action: ["action_text"],
  publisher_order_view_orders: ["page_url"],
  publisher_order_load_more: ["page_number"],
  mywebsite_add: ["page_url"],
  mywebsite_card_edit: ["page_url"],
  mywebsite_step_1_verify: ["verify_method"],
  mywebsite_step_1_reload: ["reload_method"],
  payments_add: ["account_type"],
  payments_action: ["click_action"],
  payments_txn_details: ["txn_id"],
  landing_our_story: ["page_url"],
  landing_solutions: ["page_url"],
  landing_solutions_advertisers: ["page_url"],
  landing_solutions_publishers: ["page_url"],
  landing_solutions_content_writing: ["page_url"],
  landing_blog: ["page_url"],
  landing_faqs: ["page_url"],
  landing_contact_us: ["page_url"],
  landing_refund_policy: ["page_url"],
  landing_terms_conditions: ["page_url"],
  landing_privacy_policy: ["page_url"],
  landing_data_deletion_policy: ["page_url"],
  landing_data_deletion_policy1: ["page_url"],

  notification_option: ["unread_count", "current_profile", ""],
};
