import axios from "axios";
import { fetchJWTToken } from "../utils/auth";
import { BASE_URL } from "../constants/Endpoints";
import { toast } from "react-toastify";

export function fetchPaypalInvoiceList(data) {
  return axios({
    method: "GET",
    url: `${BASE_URL}/om/paypal-invoice/list?pageNumber=${
      data.pageNumber
    }&pageSize=${data.pageSize}&status=${data.status}`,
    headers: {
      Authorization: fetchJWTToken(),
    },
  });
}


export function fetchPaypalInvoiceDetails(invoiceNumber) {
  return axios({
    method: "GET",
    url: `${BASE_URL}/om/paypal-invoice?invoiceNumber=${invoiceNumber}`,
    headers: {
      Authorization: fetchJWTToken(),
    },
  });
}


export function fetchLastPaypalInvoiceDetails() {
  return axios({
    method: "GET",
    url: `${BASE_URL}/om/paypal-invoice/last-billed-info`,
    headers: {
      Authorization: fetchJWTToken(),
    },
  });
}

export function createPaypalInvoice(data) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/om/paypal-invoice/create-send`,
    data: data,
    headers: {
      Authorization: fetchJWTToken(),
    },
  });
}


export function sendPaypalInvoice(data) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/om/paypal-invoice/send`,
    data: data,
    headers: {
      Authorization: fetchJWTToken(),
    },
  });
}

export function cancelPaypalInvoice(invoiceNumber) {
  return axios({
    method: "PUT",
    url: `${BASE_URL}/om/paypal-invoice/cancel/${invoiceNumber}`,
    headers: {
      Authorization: fetchJWTToken(),
    },
  });
}

export function deletePaypalInvoice(invoiceNumber) {
  return axios({
    method: "DELETE",
    url: `${BASE_URL}/om/paypal-invoice/${invoiceNumber}`,
    headers: {
      Authorization: fetchJWTToken(),
    },
  });
}

