import React, { useEffect, useState } from "react";
import "react-toggle/style.css";
import {
  AiOutlineMenu,
  FaMoneyCheckAlt,
  FiHeart,
  FiShoppingCart,
} from "react-icons/all";
import Toggle from "react-toggle";
import { switchUserPreference } from "../services/UserService";
import { useHistory } from "react-router";
import Modal from "react-modal";
import YouTube from "react-youtube";
import { defaultModalStyles, opts } from "../utils/modalStyle";
import {
  setAdvertiserNotificationsCount,
  setDashboardBadges,
  setPublisherNotificationsCount,
  updateUserPreference,
} from "../redux/auth/ActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import BreadCrumbs from "./BreadCrumbs";
import {
  getNotifications,
  readAllNotifications,
} from "../services/NotificationService";
import NotificationsDropdownMenu from "./NotificationsDropdownMenu";
import ProfileDropdownMenu from "./ProfileDropdownMenu";
import { handleErrorBlock } from "../utils/error";
import Colors from "../constants/colors";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
import { AdvertiserRoutes, PublisherRoutes } from "./sidebarRoutes";
import Logo from "../assets/logo.png";
import { truncate } from "../utils/Genutils";
import FullScreenLoader from "./FullScreenLoader";
import { UserNavData } from "./DashboardComponents/UserNavData";
import AddFundsModal from "./AddFundsModal";
import { CURRENT_URL, EVENT_NAME, GAevent } from "../utils/Events";

const UserNav = ({ preference }) => {
  const [openAddFundsModal, setOpenAddFundsModal] = useState(false);

  const [fullScreenLoaderText, setFullScreenLoaderText] = useState(false);
  const [showFullScreenLoader, setShowFullScreenLoader] = useState(null);

  const dispatch = useDispatch();
  const [showPreferenceSwitchLoader, setShowPreferenceSwitchLoader] =
    useState(false);
  const [preferenceToggle, setPreferenceToggle] = useState(preference);
  const history = useHistory();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const profileDetails = useSelector((state) => state.profileDetails);
  const dashboardBadges = useSelector((state) => state.dashboardBadges);
  const advertiserBadgeCount = useSelector(
    (state) => state.advertiserNotificationsCount
  );
  const publisherBadgeCount = useSelector(
    (state) => state.publisherNotificationsCount
  );
  const [notificationsCount, setNotificationsCount] = useState(
    profileDetails.preference === "ADVERTISER"
      ? advertiserBadgeCount
      : publisherBadgeCount
  );
  const [showCartAndWishlist, setShowCartAndWishlist] = useState(
    profileDetails.preference === "ADVERTISER"
  );
  const location = useLocation();
  const [notificationsList, setNotificationsList] = useState([]);
  const [showNotificationsDropdown, setShowNotificationsDropdown] =
    useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState(false);
  const [retry, setRetry] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [showLoadMoreLoader, setShowLoadMoreLoader] = useState(null);
  const [sideDrawer, setSideDrawer] = useState(false);
  const dashboardConfig = useSelector((state) => state.dashboardConfig);
  const [routeData, setRouteData] = useState({
    pageTitle: "",
    customButton: "",
  });
  useEffect(() => {
    if (showNotificationsDropdown !== false) {
      handleNotificationsList();
    }
  }, [retry]);

  const handleRetry = () => {
    setIsLoading(true);
    setRetry(!retry);
  };

  async function handleNotificationsList() {
    try {
      // Set user notifications redux count to 0
      resetUserNotificationCount();
      // setShowNotificationsDropdown((n) => !n);
      setIsLoading(true);
      // Call notifications list API
      const response = await getNotifications({
        pageNumber: 0,
        preference: profileDetails.preference,
      });
      setIsLoading(false);
      setNotificationsList(response.data.notificationDocumentList);
      // Call read all notifications API
      if (
        profileDetails.preference === "ADVERTISER" &&
        advertiserBadgeCount > 0
      ) {
        const readAllResponse = await readAllNotifications({
          userType: profileDetails.preference,
        });
      }
      if (
        profileDetails.preference === "PUBLISHER" &&
        publisherBadgeCount > 0
      ) {
        const readAllResponse = await readAllNotifications({
          userType: profileDetails.preference,
        });
      }
      setError(false);
    } catch (e) {
      setError(true);
      setIsLoading(false);
      handleErrorBlock("Notifications: Error ->", e, history, dispatch);
    }
  }

  const handleLoadMore = async () => {
    try {
      setShowLoadMore(false);
      setShowLoadMoreLoader(true);

      const response = await getNotifications({
        pageNumber: pageNumber + 1,
        preference: profileDetails.preference,
      });
      setShowLoadMoreLoader(false);
      setNotificationsList((prevState) => [
        ...prevState,
        ...response.data.notificationDocumentList,
      ]);
      if (response.data.totalPages > response.data.pageNumber + 1) {
        setShowLoadMore(true);
      } else {
        setShowLoadMore(false);
      }
      // Increment page number now for handling load more
      setPageNumber(pageNumber + 1);
    } catch (e) {
      setShowLoadMore(false);
      handleErrorBlock(
        "Notifications Load More : Error ->",
        e,
        history,
        dispatch
      );
      setShowLoadMoreLoader(false);
      setIsLoading(false);
    }
  };
  // Resets user notification count (badges) to 0 in Redux
  function resetUserNotificationCount() {
    if (profileDetails.preference === "ADVERTISER") {
      dispatch(setAdvertiserNotificationsCount(0));
    }
    if (profileDetails.preference === "PUBLISHER") {
      dispatch(setPublisherNotificationsCount(0));
    }
    dispatch(
      setDashboardBadges({
        ...dashboardBadges,
        userNotificationCount: 0,
      })
    );
  }

  async function handlePreferenceChange(e) {
    let preferenceChanged;
    try {
      if (e.target.checked) {
        setShowPreferenceSwitchLoader(true);
        const response = await switchUserPreference("PUBLISHER");
        setShowCartAndWishlist(false);
        setNotificationsCount(publisherBadgeCount);
        dispatch(updateUserPreference(response.data));
        preferenceChanged = response.data.preference.toLowerCase();
        setPreferenceToggle("PUBLISHER");
        history.push(`/publisher/dashboard`, {
          state: "preferenceToggled",
        });
        setShowPreferenceSwitchLoader(false);
        GAevent(EVENT_NAME.switch_option, [
          CURRENT_URL + "/publisher/dashboard",
          "PUBLISHER",
        ]);
      } else {
        setShowPreferenceSwitchLoader(true);
        const response = await switchUserPreference("ADVERTISER");
        setShowCartAndWishlist(true);
        setNotificationsCount(advertiserBadgeCount);
        dispatch(updateUserPreference(response.data));
        preferenceChanged = response.data.preference.toLowerCase();
        setPreferenceToggle("ADVERTISER");
        history.push(`/advertiser/dashboard`, {
          state: "preferenceToggled",
        });
        setShowPreferenceSwitchLoader(false);
        GAevent(EVENT_NAME.switch_option, [
          CURRENT_URL + "/advertiser/dashboard",
          "ADVERTISER",
        ]);
      }
    } catch (error) {
      setShowPreferenceSwitchLoader(false);
      handleErrorBlock(
        "Switch User Preference : Error ->",
        error,
        history,
        dispatch
      );
    }
  }

  function openModal() {
    GAevent(
      preference === "ADVERTISER"
        ? EVENT_NAME.advertiser_howitworks
        : EVENT_NAME.publisher_howitworks,
      [
        preference === "ADVERTISER"
          ? dashboardConfig?.tourDto?.advertiser
          : dashboardConfig?.tourDto?.publisher,
      ]
    );

    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    let routeInfo = UserNavData.filter(
      (item) => item.route === location.pathname
    );
    setRouteData(routeInfo[0]);
    setSideDrawer(false);
  }, [location.pathname]);

  return (
    <>
      {showPreferenceSwitchLoader && <FullScreenLoader />}
      {showFullScreenLoader && <FullScreenLoader text={fullScreenLoaderText} />}

      <AddFundsModal
        setShowFullScreenLoader={setShowFullScreenLoader}
        setFullScreenLoaderText={setFullScreenLoaderText}
        isOpen={openAddFundsModal}
        closeModal={() => setOpenAddFundsModal(false)}
      />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={defaultModalStyles}
        contentLabel="Example Modal"
      >
        <YouTube
          videoId={
            preference === "ADVERTISER"
              ? dashboardConfig?.tourDto?.advertiser?.split("?v=")[1]
              : dashboardConfig?.tourDto?.publisher?.split("?v=")[1]
          }
          opts={opts}
          onReady={true}
          containerClassName="youtube-container-class"
        />
      </Modal>
      <Drawer
        open={sideDrawer}
        onClose={() => setSideDrawer(false)}
        direction="left"
        className=""
        size={300}
      >
        <div className="p-3">
          <div className="mb-2">
            <img src={Logo} className="img-fluid w-75  " alt="" />
            <div className=" text-theme d-flex align-items-baseline flex-wrap ">
              <p style={{ fontSize: "0.8rem" }} className="mb-0">
                Welcome
              </p>
              &nbsp;
              <h6 className="fw-bold">
                {profileDetails &&
                  profileDetails !== "" &&
                  truncate(profileDetails?.name.split(" ")[0], 10)}
              </h6>
            </div>
          </div>
          <div className="d-flex toggle-container align-items-center mb-3">
            <p className="text-theme fw-bold mx-2 my-0 pt-1 ">ADVERTISER</p>
            <Toggle
              checked={preferenceToggle === "PUBLISHER"}
              onChange={handlePreferenceChange}
              className="user-nav-toggle "
              icons={false}
            />

            <p className="text-theme fw-bold mx-2 my-0 pt-1 ">PUBLISHER</p>
          </div>
          <div
            className=" p-3 my-3 mt-1 wallet-summary shadow"
            style={{ width: "80%" }}
          >
            {preference !== "ADVERTISER" && (
              <FaMoneyCheckAlt size={32} color={Colors.peachPink} />
            )}
            <p className="text-theme mb-2">Wallet Balance</p>
            <h2 className="text-theme">
              {profileDetails.amount >= 0
                ? `$${profileDetails.amount}`
                : `-$${Math.abs(profileDetails.amount)}`}
            </h2>
            <p className="text-theme mb-1">
              {preference === "ADVERTISER"
                ? "Total Amount Added"
                : "Total Earned"}
            </p>
            <h5 className="text-theme">
              {profileDetails.totalAmount >= 0
                ? `$${profileDetails.totalAmount}`
                : `-$${Math.abs(profileDetails.totalAmount)}`}
            </h5>
          </div>
          <div>
            <div>
              {preference === "ADVERTISER"
                ? AdvertiserRoutes.map((item) => (
                    <NavLink
                      activeClassName="text-theme"
                      to={item.to}
                      className="d-flex align-content-end text-uppercase side-route text-decoration-none "
                    >
                      {item.icon}
                      <p className=" my-auto">{item.name}</p>
                    </NavLink>
                  ))
                : PublisherRoutes.map((item) => (
                    <NavLink
                      activeClassName="text-theme"
                      to={item.to}
                      className="d-flex align-content-end text-uppercase side-route text-decoration-none "
                    >
                      {item.icon}
                      <p className=" my-auto">{item.name}</p>
                    </NavLink>
                  ))}
            </div>
          </div>
        </div>
      </Drawer>
      <div className="usernav-mobile py-3">
        <div className="d-flex justify-content-between ">
          <div>
            <AiOutlineMenu
              onClick={() => setSideDrawer(true)}
              color={Colors.primaryColor}
              size="1.8em"
            />
          </div>
          <div>
            {" "}
            {preference !== "ADVERTISER" && (
              <p
                className=" text-center text-peachPink fw-bold m-0 "
                style={{ fontSize: "0.7em" }}
              >
                PUBLISHER
              </p>
            )}
            {preference !== "PUBLISHER" && (
              <p
                className=" text-center text-theme-minor fw-bold m-0"
                style={{ fontSize: "0.7em" }}
              >
                ADVERTISER
              </p>
            )}
            <h5 className="fw-bolder text-theme m-0 p-0 ">
              {routeData.pageTitle}
            </h5>
          </div>
          <div>
            {" "}
            <ProfileDropdownMenu
              preference={preference}
              setShowFullScreenLoader={setShowPreferenceSwitchLoader}
              profileDetails={profileDetails}
            />
          </div>
        </div>
      </div>

      <div className=" w-100 py-5  pb-3 usernav-container ">
        <div style={{ marginLeft: 2, marginBottom: -4 }}>
          {preference !== "ADVERTISER" && (
            <p className=" usernav-type text-peachPink fw-bold m-0">
              PUBLISHER
            </p>
          )}
          {preference !== "PUBLISHER" && (
            <p className=" usernav-type text-theme-minor fw-bold m-0 ">
              ADVERTISER
            </p>
          )}
        </div>
        <div
          className="d-flex justify-content-between align-items-center mb-2 "
          style={{ height: "3.3 em", maxHeight: "35px" }}
        >
          <h2 className="fw-bolder text-theme m-0 p-0 text-nowrap usernav-pagetitle">
            {routeData.pageTitle}
          </h2>

          {routeData.customButton ? (
            <> {routeData.customButton} </>
          ) : location.pathname.includes("dashboard") ? (
            <button
              className={`btn text-white  fw-bold  py-2 px-4 addWebButton shadow ${
                profileDetails.preference === "ADVERTISER" && "bg-theme"
              } `}
              onClick={openModal}
            >
              How It Works?
            </button>
          ) : (
            ""
          )}

          {location.pathname === "/advertiser/billing-funds" && (
            <button
              className="text-white  fw-bold  py-2 px-4 addWebButton shadow bg-theme-minor text-nowrap"
              onClick={() => setOpenAddFundsModal(true)}
            >
              ADD FUNDS
            </button>
          )}

          <div>
            <div className="d-flex align-items-center">
              <div className="d-flex toggle-container align-items-center">
                <p className="text-theme fw-bold mx-2 my-0 pt-1 ">ADVERTISER</p>
                <Toggle
                  checked={preferenceToggle === "PUBLISHER"}
                  onChange={handlePreferenceChange}
                  className="user-nav-toggle "
                  icons={false}
                />
                <p className="text-theme fw-bold mx-2 my-0 pt-1 ">PUBLISHER</p>
              </div>
              <div className="d-flex align-items-center">
                {showCartAndWishlist === true && (
                  <div
                    onClick={() => {
                      history.push(`/${preference.toLowerCase()}/cart`, {
                        state: history.location.pathname,
                      });
                      GAevent(EVENT_NAME.advertiser_user_cart, [
                        CURRENT_URL + "/advertiser/cart",
                      ]);
                    }}
                    className="position-relative px-1 c-ptr"
                  >
                    <FiShoppingCart className="user-nav-icon position-relative c-ptr" />
                    {dashboardBadges?.userKartCount > 0 && (
                      <div className="user-nav-badge d-flex align-items-center justify-content-center p-2 text-white fw-bold ">
                        {dashboardBadges?.userKartCount > 99
                          ? "99"
                          : dashboardBadges?.userKartCount}
                      </div>
                    )}
                  </div>
                )}
                {showCartAndWishlist === true && (
                  <div
                    onClick={() => {
                      history.push("/wishlist");
                      GAevent(EVENT_NAME.advertiser_wishlist, [
                        CURRENT_URL + "/wishlist",
                      ]);
                    }}
                    className="position-relative px-1 c-ptr "
                  >
                    <FiHeart className="user-nav-icon c-ptr" />
                    {dashboardBadges?.userWishlistCount > 0 && (
                      <div className="user-nav-badge d-flex align-items-center justify-content-center p-2 text-white fw-bold ">
                        {dashboardBadges?.userWishlistCount > 99
                          ? "99"
                          : dashboardBadges?.userWishlistCount}
                      </div>
                    )}
                  </div>
                )}
                <NotificationsDropdownMenu
                  notificationsCount={
                    profileDetails.preference === "ADVERTISER"
                      ? advertiserBadgeCount
                      : publisherBadgeCount
                  }
                  // notificationsCount={notificationsCount}
                  preference={profileDetails.preference}
                  dashboardBadges={dashboardBadges}
                  visibility={showNotificationsDropdown}
                  setVisibility={setShowNotificationsDropdown}
                  isLoading={isLoading}
                  data={notificationsList}
                  setData={setNotificationsList}
                  retry={retry}
                  handleRetry={handleRetry}
                  error={error}
                  showLoadMore={showLoadMore}
                  handleLoadMore={handleLoadMore}
                  showLoadMoreLoader={showLoadMoreLoader}
                  handleNotificationsList={handleNotificationsList}
                />
                <ProfileDropdownMenu
                  setShowFullScreenLoader={setShowPreferenceSwitchLoader}
                  profileDetails={profileDetails}
                />
              </div>
            </div>
          </div>
        </div>
        <BreadCrumbs />
      </div>
    </>
  );
};

export default UserNav;
