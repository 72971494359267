import YouTube from "react-youtube";
import Modal from "react-modal";
import React from "react";
import { getContent } from "../localization/index";
import { useSelector } from "react-redux";
import { EVENT_NAME, GAevent } from "../utils/Events";

const HowItWorksCard = ({ url, page }) => {
  const language = useSelector((state) => state.language);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      transform: "translate(-50%, -50%)",
      border: "none",
    },
    overlay: {
      background: "rgba(0,0,0,0.4)",
    },
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);

    GAevent(page, [url]);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const opts = {
    height: "507",
    width: "832",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <YouTube
          videoId={url}
          opts={opts}
          onReady={true}
          containerClassName="youtube-container-class"
        />
        {/* <video width={"90%"} controls>
          <source
            src="https://www.youtube.com/watch?v=-nOdSj0u29U"
            type="video/mp4"
          />
        </video> */}
      </Modal>
      <div className="row howItWork mx-auto mb-5 mt-3 ">
        <div className="col-md-6 pe-lg-3 ps-4 py-3 textContainer howItWork-textContainer mb-3 mb-md-0">
          <h2 className="text-theme mb-2 text-start ">
            {getContent(language, "HOW_IT_WORKS_HEADER")}
          </h2>
          <p className=" w-100 text-start">
            {getContent(language, "HOW_IT_WORKS_DESCRIPTION")}
          </p>
        </div>
        <div
          className="col-md-6 videoContainer position-relative"
          onClick={openModal}
        >
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
          />
          <iframe
            style={{
              height: "100%",
              width: "100%",
            }}
            src={`https://www.youtube.com/embed/${url}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </>
  );
};

export default HowItWorksCard;
