import { toast } from "react-toastify";
import { removeJWTToken } from "./auth";
import { setUserProfileData } from "../redux/auth/ActionCreator";
import { EVENT_NAME, GAevent } from "./Events";

export const handleErrorBlock = (
  logMessagePrefix,
  error,
  history,
  dispatch,
  showSnackbar = true
) => {
  // Status Code 200
  // Process request

  console.log("Universal Error:", error);
  console.log("Universal Error: Status", error?.response);
  console.log("Universal Error: Data", error?.response);

  // // Network Error
  // if (`${error}`.includes("Network Error") && showSnackbar === true) {
  //   // toast.error("Network Error");
  //   toast.error("No internet connection");
  // }

  // Status Code 401
  // Clear Cookies -> Send to Home Screen
  if (
    error?.response?.status === 401 ||
    error?.response?.data?.status === 401
  ) {
    removeJWTToken();
    dispatch(setUserProfileData(""));
    history.push("/");
    toast.error("Unauthorized Error");
    GAevent(EVENT_NAME.error_alert, [
      "Unauthorized",
      401,
      "Unauthorized Error",
    ]);
  } else {
    let message = error?.response?.data?.message;
    let path = error?.response?.data?.path;

    if (!path) path = "Api Not available";
    if (error?.response?.data?.error && message)
      message = "Something went wrong. Please try after sometime.";
    else if (!message)
      message = "Site is under maintenance, we will be up very soon!";

    toast.error(message);
    GAevent(EVENT_NAME.error_alert, [path, error?.response?.status, message]);
  }


};
