import React, { useEffect, useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import Logo from "../assets/logo.png";
import PrimaryButton from "./PrimaryButton";
import { FiArrowRight, RiArrowDownSLine } from "react-icons/all";
import { Link, useHistory, useLocation } from "react-router-dom";
import Colors from "../constants/colors";
import { handleHideNavLinks } from "../utils/nav";
import { CURRENT_URL, EVENT_NAME, GAevent } from "../utils/Events";

export default function NavBar({ dashboard }) {
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const [hideNavLinks, setHideNavLinks] = useState(false);
  const IsLoginPage = location.pathname === "/login";
  const IsSignUp = location.pathname === "/signup";

  useEffect(() => {
    handleHideNavLinks(location, setHideNavLinks);
  }, []);

  const isSolutionsPage = () => {
    return (
      pathname === "/guest-posts" ||
      pathname === "/publisher" ||
      pathname === "/content-writing-services"
    );
  };

  return (
    <div className=" border-bottom border-1 border-light">
      <div className="container py-2">
        <Navbar collapseOnSelect expand="lg" bg="transparent">
          <Navbar.Brand
            href="/"
            onClick={() => {
              GAevent(EVENT_NAME.header_logo_icon, [CURRENT_URL]);
            }}
          >
            <img id="logo" src={Logo} alt="Logo" height="35px" width="185px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            {!dashboard && (
              <>
                {!IsLoginPage && !IsSignUp && !hideNavLinks ? (
                  <Nav>
                    {/* <Nav.Link
                      className={`navLinks text-theme react-link ${
                        pathname === "/" ? "link-active" : ""
                      }`}
                      href="/"
                    >
                      HOME
                    </Nav.Link> */}
                    <Link
                      className={`navLinks text-decoration-none text-theme react-link p-2 ps-lg-2 ps-0 ${
                        pathname === "/our-story" ? "link-active" : ""
                      }`}
                      onClick={() => {
                        GAevent(EVENT_NAME.header_our_story, [
                          CURRENT_URL + "/our-story",
                        ]);
                      }}
                      to={"/our-story"}
                      style={{ marginTop: 2 }}
                    >
                      OUR STORY
                    </Link>
                    <NavDropdown
                      title={
                        <div className="text-theme react-link">
                          SOLUTIONS{" "}
                          <RiArrowDownSLine
                            size={20}
                            style={{ marginTop: -2 }}
                          />
                        </div>
                      }
                      className={`navLinks text-theme ${
                        isSolutionsPage() ? "link-active" : ""
                      }`}
                      id="navDropdown"
                    >
                      <Link
                        id="dropdownLinks"
                        className="text-decoration-none"
                        to="/guest-posts"
                        onClick={() => {
                          GAevent(EVENT_NAME.header_solutions_advertisers, [
                            CURRENT_URL + "/guest-posts",
                          ]);
                        }}
                      >
                        ADVERTISERS <FiArrowRight className="arrowIcon" />
                      </Link>
                      <Link
                        className="text-decoration-none nav-item-active"
                        id="dropdownLinks"
                        to="/publisher"
                        onClick={() => {
                          GAevent(EVENT_NAME.header_solutions_publishers, [
                            CURRENT_URL + "/publisher",
                          ]);
                        }}
                      >
                        PUBLISHERS <FiArrowRight className="arrowIcon" />
                      </Link>
                      <Link
                        id="dropdownLinks"
                        to="/content-writing-services"
                        className="text-decoration-none text-nowrap "
                        onClick={() => {
                          GAevent(EVENT_NAME.header_solutions_content_writing, [
                            CURRENT_URL + "/content-writing-services",
                          ]);
                        }}
                      >
                        CONTENT WRITING <FiArrowRight className="arrowIcon" />
                      </Link>
                    </NavDropdown>
                    <Nav.Link
                      className="navLinks text-theme text-decoration-none"
                      href="https://blog.outreachmantra.com/"
                      target="_blank"
                      onClick={() => {
                        GAevent(EVENT_NAME.header_blog, [
                          "https://blog.outreachmantra.com/",
                        ]);
                      }}
                    >
                      BLOG
                    </Nav.Link>
                    <Link
                      className={`navLinks text-decoration-none text-theme react-link p-2 ps-lg-2 ps-0 ${
                        pathname === "/faq" ? "link-active" : ""
                      }`}
                      to="/faq"
                      style={{ marginTop: 2 }}
                      onClick={() => {
                        GAevent(EVENT_NAME.header_faqs, [CURRENT_URL + "/faq"]);
                      }}
                    >
                      FAQS
                    </Link>
                    <Link
                      className={`navLinks text-decoration-none text-theme react-link p-2 ps-lg-2 ps-0 ${
                        pathname === "/contact-us" ? "link-active" : ""
                      }`}
                      style={{ marginTop: 2 }}
                      to="/contact-us"
                      onClick={() => {
                        GAevent(EVENT_NAME.header_contact_us, [
                          CURRENT_URL + "/contact-us",
                        ]);
                      }}
                    >
                      CONTACT US
                    </Link>
                    <Nav.Item>
                      <PrimaryButton
                        onClick={() => {
                          GAevent(EVENT_NAME.header_login_cta, [
                            CURRENT_URL + "/login",
                          ]);
                          history.push("/login");
                        }}
                        className="ms-md-5 ms-0"
                      >
                        LOGIN
                      </PrimaryButton>
                    </Nav.Item>
                  </Nav>
                ) : hideNavLinks ? null : (
                  <Nav color={Colors.primaryColor}>
                    <Nav.Item
                      className=" text-theme  pt-2 pe-1"
                      style={{ fontWeight: "600" }}
                    >
                      {IsLoginPage
                        ? "Don't have an account?"
                        : "Already a member?"}
                    </Nav.Item>

                    <Link
                      className={`navLinks text-theme fw-bolder fs-6 text-decoration-underline mt-2`}
                      onClick={() => {
                        console.log("Register");
                        GAevent(
                          IsLoginPage
                            ? EVENT_NAME.header_signup_cta
                            : EVENT_NAME.header_login_cta,
                          IsLoginPage
                            ? [CURRENT_URL + "/signup"]
                            : [CURRENT_URL + "/login"]
                        );
                      }}
                      to={IsLoginPage ? "/signup" : "/login"}
                    >
                      {IsLoginPage ? "REGISTER NOW" : "LOGIN"}
                    </Link>
                  </Nav>
                )}
              </>
            )}
            {dashboard && (
              <PrimaryButton onClick={() => history.push("/")}>
                Go to Dashboard
              </PrimaryButton>
            )}
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
}
