import React from "react";
import HeroImage from "../assets/herobanner.webp";
import PrimaryButton from "./PrimaryButton";
import { GoPlay, IoCheckmarkCircleSharp } from "react-icons/all";
import { MemberCardData } from "../constants/content";
import Typography from "./Typography";
import Card from "../components/Card";
import CarouselComp from "./CarouselComp";
import Colors from "../constants/colors";
import Carousel from "./TestimonialSlider/Carousel";
import TestimonialBanner from "../assets/testimonialBanner.webp";
import Modal from "react-modal";
import YouTube from "react-youtube";
import { defaultModalStyles, opts } from "../utils/modalStyle";
import ProgressiveImage from "./ProgressiveImage";
import { Helmet } from "react-helmet";
import KnowListed from "../assets/know-top-list.webp";
import KnowRegPub from "../assets/know-regd-publisher.webp";
import KnowTotalAdv from "../assets/know-total-adv.webp";
import KnowTotal from "../assets/know-total.webp";
import { useSelector } from "react-redux";
import { getContent } from "../localization/index";
import { useHistory } from "react-router";
import { CURRENT_URL, EVENT_NAME, GAevent } from "../utils/Events";

export default function HomeComponent() {
  const history = useHistory();

  const homeConfig = useSelector((state) => state.dashboardConfig);
  const language = useSelector((state) => state.language);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [url, setUrl] = React.useState("");

  function openModal(pref) {
    setIsOpen(true);

    if (pref === "advertiser") {
      setUrl(homeConfig?.tourDto?.advertiser?.split("?v=")[1]);
      GAevent(EVENT_NAME.header_advertisers_video, [
        homeConfig?.tourDto?.advertiser,
      ]);
    }

    if (pref === "publisher") {
      setUrl(homeConfig?.tourDto?.publisher?.split("?v=")[1]);
      GAevent(EVENT_NAME.header_publishers_video, [
        homeConfig?.tourDto?.publisher,
      ]);
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  const HomepageCard = [
    {
      imgSrc: KnowListed,
    },
    {
      imgSrc: KnowRegPub,
    },
    {
      imgSrc: KnowTotalAdv,
    },
    {
      imgSrc: KnowTotal,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{getContent(language, "META_TITLE_HOME")}</title>
        <meta
          name="description"
          content={getContent(language, "META_DESCRIPTION_HOME")}
        />
        <link rel="canonical" href="/" />
        <meta
          property="og:title"
          content={getContent(language, "META_TITLE_HOME")}
        />
        <meta
          property="og:site_name"
          content={getContent(language, "META_TITLE_HOME")}
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:description"
          content={getContent(language, "META_DESCRIPTION_HOME")}
        />
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:image"
          content="https://blog.outreachmantra.com/wp-content/uploads/2022/10/OutreachMantra-OG-Image.png"
        />
      </Helmet>

      <div className="container">
        <Modal
          style={defaultModalStyles}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
        >
          <YouTube
            videoId={url}
            opts={opts}
            onReady={true}
            containerClassName="youtube-container-class"
          />
        </Modal>

        <div className="row">
          <div className="col-lg-6 align-self-end position-relative">
            <div className="home-circle-translucent " />
            <div className="imgContainer  position-relative ">
              <ProgressiveImage
                className="heroImg"
                src={getContent(language, "IMAGE_staticHomeHerobanner")}
                placeholder={HeroImage}
              />
              <div className="home-circle-1 rounded-circle shadow" />
              <div className="tourBox p-2 py-3 d-flex flex-row justify-content-around align-items-center">
                <div className=" position-relative">
                  <div className="d-flex flex-row">
                    <div
                      onClick={() => {
                        openModal("advertiser");
                      }}
                      className="d-flex align-items-center me-2"
                    >
                      <GoPlay
                        className="c-ptr"
                        color={Colors.secondaryColor}
                        size={52}
                      />
                    </div>
                    <div className="py-2">
                      <div className="text-theme tour-text">
                        {getContent(language, "ADVERTISERS")}
                      </div>
                      <div className="text-theme-minor fw-bold take-tour-text">
                        {getContent(language, "HOME_TAKE_TOUR")}
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => openModal("advertiser")}
                    className="position-absolute fw-bold tourBox-float-button bg-theme text-white border-0 "
                  >
                    {getContent(language, "HOME_VIEW_DETAILS")}
                  </button>
                </div>
                <div className="tourBox-separator" />
                <div className="position-relative">
                  <div className="d-flex flex-row">
                    <div className="d-flex align-items-center me-2">
                      <GoPlay
                        className="c-ptr"
                        onClick={() => openModal("publisher")}
                        color={Colors.secondaryColor}
                        size={52}
                      />
                    </div>
                    <div className="py-2">
                      <div className="text-theme tour-text">
                        {getContent(language, "PUBLISHERS")}
                      </div>
                      <div className="text-theme-minor fw-bold take-tour-text">
                        {getContent(language, "HOME_TAKE_TOUR")}
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => openModal("publisher")}
                    className="position-absolute fw-bold tourBox-float-button bg-theme text-white border-0 "
                  >
                    {getContent(language, "HOME_VIEW_DETAILS")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6  d-flex flex-column justify-content-end ps-lg-5 ">
            <div className="detailsContainer  ">
              <Typography variant="header" className="">
                {getContent(language, "HOME_HERO_HEADER")}
              </Typography>
              <h5 className="fst-italic text-theme fw-normal">
                {getContent(language, "HOME_HERO_SUBHEADER")}
              </h5>
              <div className="row mt-4">
                <div className="col-md-5 ">
                  <h5 className="home-ul-header mb-3 text-theme text-center text-md-start">
                    {getContent(language, "ADVERTISERS")}
                  </h5>
                  {getContent(language, "HOME_HERO_ADVERTISER_POINTERS").map(
                    (pointer) => (
                      <div className="d-flex flex-row">
                        <div className="checkpoint-icon">
                          <IoCheckmarkCircleSharp color={Colors.primaryColor} />
                        </div>
                        <div className="checkpoint-content text-theme fst-italic mb-3">
                          {pointer}
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div className="col-md-1" />
                <div className="col-md-6 ">
                  <h5 className=" home-ul-header mb-3 text-theme text-center text-md-start ">
                    {getContent(language, "PUBLISHERS")}
                  </h5>
                  {getContent(language, "HOME_HERO_PUBLISHER_POINTERS").map(
                    (pointer) => (
                      <div className="d-flex flex-row">
                        <div className="checkpoint-icon">
                          <IoCheckmarkCircleSharp color={Colors.primaryColor} />
                        </div>
                        <div className="checkpoint-content text-theme fst-italic mb-3 ">
                          {pointer.replace(
                            "INSERT_ADVERTISER_COUNT",
                            homeConfig.records.totalAdvertisers.value
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>

              <div className="text-md-start text-center">
                {/* <Link to="/signup"> */}
                <PrimaryButton
                  onClick={() => {
                    GAevent(EVENT_NAME.header_signup_cta, [
                      CURRENT_URL + "/signup",
                    ]);
                    history.push("/signup");
                  }}
                  className="p-3 mt-3"
                >
                  {getContent(language, "HOME_HERO_CREATE_ACCOUNT_CTA")}
                </PrimaryButton>
                {/* </Link> */}
              </div>
              <h3 className="cardInfo text-center text-md-start text-theme fw-bold mt-2 mb-3">
                {getContent(language, "HOME_HERO_NO_CREDIT_CARD_REQUIRED_TEXT")}
              </h3>

              <h3 className="cardInfo text-center text-md-start text-theme fw-bold mt-2 mb-3 fs-6">
                {getContent(language, "HOME_SIGNUP_BONUS1")}$
                {homeConfig.signUpBonusAmount}
                {getContent(language, "HOME_SIGNUP_BONUS2")}
                {getContent(language, "HOME_SIGNUP_BONUS3")}
              </h3>

              <p className="mantraUsers text-theme fst-italic">
                {getContent(language, "HOME_HERO_BOTTOM_TEXT")}
              </p>
              <div className="home-circle-2" />
            </div>
          </div>
        </div>

        {homeConfig?.records?.visible === true && (
          <div className="knowUs">
            <Typography variant="header" className="text-center">
              {getContent(language, "HOME_GET_TO_KNOW_US")}
            </Typography>
            <div
              className="d-flex justify-content-center justify-content-lg-between  w-100 flex-wrap mb-2 mt-3"
              style={{ gap: 10 }}
            >
              {homeConfig?.records?.totalWebsites.visible === true && (
                <div className="d-flex mb-3 knowUs-item ">
                  <img
                    src={HomepageCard[1]?.imgSrc}
                    height="60"
                    width="60"
                    alt="office"
                  />
                  <div>
                    <Typography variant="header">
                      {homeConfig?.records?.totalWebsites.value}
                    </Typography>
                    <h6 className="text-theme">
                      {getContent(language, "HOME_TOTAL_WEBSITES")}
                    </h6>
                  </div>
                </div>
              )}
              {homeConfig?.records?.totalUsers.visible === true && (
                <div className="d-flex  mb-3 knowUs-item">
                  <img
                    src={HomepageCard[0]?.imgSrc}
                    height="60"
                    width="60"
                    alt="office"
                  />
                  <div>
                    <Typography variant="header">
                      {homeConfig?.records?.totalUsers.value}
                    </Typography>
                    <h6 className="text-theme">
                      {getContent(language, "HOME_TOTAL_PUBLISHERS")}
                    </h6>
                  </div>
                </div>
              )}

              {homeConfig?.records?.totalAdvertisers.visible === true && (
                <div className="d-flex mb-3 knowUs-item">
                  <img
                    src={HomepageCard[2]?.imgSrc}
                    height="60"
                    width="60"
                    alt="office"
                  />
                  <div>
                    <Typography variant="header">
                      {homeConfig?.records?.totalAdvertisers.value}
                    </Typography>
                    <h6 className="text-theme">
                      {getContent(language, "HOME_TOTAL_ADVERTISERS")}
                    </h6>
                  </div>
                </div>
              )}
              {homeConfig?.records?.totalOrders.visible === true && (
                <div className="d-flex  mb-3  knowUs-item">
                  <img
                    src={HomepageCard[3]?.imgSrc}
                    height="60"
                    width="60"
                    alt="office"
                  />
                  <div>
                    <Typography variant="header">
                      {homeConfig?.records?.totalOrders.value}
                    </Typography>
                    <h6 className="text-theme">
                      {getContent(language, "HOME_TOTAL_ORDERS")}
                    </h6>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="enterprises">
          <Typography
            variant="header"
            className={`${
              homeConfig?.records?.visible === false && "mt-5 mt-lg-1"
            } mb-5 text-center`}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: getContent(language, "JOIN_TEXT").replace(
                  "INSERT_KEYWORD_HERE",
                  homeConfig.records.totalAdvertisers.value
                ),
              }}
            />
          </Typography>
          <div className="mt-5 home-brand-carousel-container mb-4 w-100">
            <CarouselComp page={EVENT_NAME.home_page_brands}/>
          </div>
        </div>
        <div className="becomeMember">
          <Typography
            variant="header"
            text={getContent(language, "HOME_HOW_HELPS_HEADER")}
          />
          <Typography
            className="memberHelperText"
            variant="description"
            text={getContent(language, "HOME_HOW_HELPS_SUBHEADER")}
          />
          <div className="row mt-2 mt-md-4">
            {MemberCardData.map((card, idx) => (
              <div className=" col-md-4 mb-3">
                <Card
                  card={{
                    icon: card.icon,
                    title: getContent(language, "HOME_HOW_HELPS_CARDS")[idx]
                      .title,
                    description: getContent(language, "HOME_HOW_HELPS_CARDS")[
                      idx
                    ].description,
                    circleColor: card.circleColor,
                  }}
                  className="mx-2"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="text-center  pt-5">
          <Typography
            className="fst-italic pb-0 mb-0"
            variant="description"
            text={getContent(language, "HOME_TESTIMONIALS_HEADER")}
          />
          <Typography className=" mb-3 mt-0" variant="header">
            <div
              dangerouslySetInnerHTML={{
                __html: getContent(language, "HOME_TESTIMONIALS_SUBHEADER"),
              }}
            ></div>
          </Typography>
          <div className="row">
            <div className="col-md-6 d-flex align-items-center">
              {homeConfig?.testimonials && (
                <Carousel
                  data={homeConfig?.testimonials?.map((testimonial, idx) => ({
                    order: idx + 1,
                    authorName: testimonial.name,
                    authorOccupation: testimonial.role,
                    img: testimonial.avatar,
                    content: testimonial.message,
                  }))}
                />
              )}
            </div>
            <div className="col-md-6 d-md-block d-none position-relative">
              <div className="home-circle-translucent-2" />
              <div className="home-circle-3 shadow " />

              <ProgressiveImage
                className="testimonial-banner-as"
                src={getContent(language, "IMAGE_staticHomeTestimonial")}
                placeholder={TestimonialBanner}
                aspectRatio="attr(954px) / attr(933px)"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
