import React from "react";
import { useHistory } from "react-router-dom";
import { EVENT_NAME, GAevent } from "../../utils/Events";
import { CURRENT_URL } from "./../../utils/Events";

const AddWebsiteButton = () => {
  const history = useHistory();
  return (
    <div>
      <button
        className="text-white m-3 mt-4 fw-bold  py-2 px-4 addWebButton shadow"
        onClick={() => {
          history.push("/publisher/add-website");
          GAevent(EVENT_NAME.mywebsite_add, [
            CURRENT_URL + "/publisher/add-website",
          ]);
        }}
      >
        ADD WEBSITE
      </button>
    </div>
  );
};

export default AddWebsiteButton;
