import {
  AiOutlineBulb,
  FiArrowUpCircle,
  FiBell,
  FiBookOpen,
  FiCornerDownRight,
  FiCreditCard,
  FiFileText,
  FiGlobe,
  FiHeadphones,
  FiLink,
  FiRss,
  FiSend,
  FiTrendingUp,
  FiUsers,
} from "react-icons/all";
import Colors from "./colors";
import React from "react";

export const HomePageContent = {
  lineOne: "lorem ipsum",
  lineTwo: "lorem ipsum",
};

export const OurStoryPageContent = {
  lineOne: "lorem ipsum",
  lineTwo: "lorem ipsum",
};

export const cardIconStyle = {
  size: 50,
  color: "white",
  position: "absolute",
  bottom: 7,
  right: 10,
  strokeWidth: 1,
};
export const CardData = [
  {
    icon: <FiLink size={50} style={cardIconStyle} />,
    circleColor: Colors.lightGreen,
    title: "Don’t waste time waiting for a reply.",
    description:
      "You don't have to sit back and wait for days to get a reply from web admins, negotiate or worry about topic approval and payments. Join our platform, select sites based on their metrics, and leave the rest on us :).",
  },
  {
    icon: <FiFileText size={50} style={cardIconStyle} />,
    circleColor: Colors.lightBlue,
    title: "Huge database, and economical pricing",
    description:
      "We offer you a huge database of niche relevant and quality websites. Unlike others, we charge a genuine price for guest posts, link insertions, and content creation, so you can order without having to worry about your budget. ",
  },
  {
    icon: <FiGlobe size={50} style={cardIconStyle} />,
    circleColor: Colors.secondaryColor,
    title: "Passive earning source for webmasters.",
    description:
      "If you are a webmaster, join us, and start accepting quality guest posts on your websites, that are reader-friendly and add value to your blog. Add your site to OutreachMantra, and start receiving orders.",
  },
  {
    icon: <FiCreditCard size={50} style={cardIconStyle} />,
    circleColor: Colors.hotPink,
    title: "Fulfill your content requirements",
    description:
      "Tired of searching for a reliable wordsmith? Our team of in-house writers is expert in writing, nitpicking grammatical mistakes, and doing research that completes the reader’s search intent.",
  },
];

export const PublisherProcessData = [
  {
    circleColor: "#EE6C4D",
    header: "01",
    helperText: "Sign",
    helperText2: "Up",
    arrow: <FiCornerDownRight />,
  },
  {
    circleColor: Colors.secondaryColor,
    header: "02",
    helperText: "Get",
    helperText2: "Orders",
    arrow: <FiCornerDownRight />,
  },
  {
    circleColor: "#1EC93A",
    header: "03",
    helperText: "Complete",
    helperText2: "the orders",
    arrow: <FiCornerDownRight />,
  },
  {
    circleColor: "#ECBA09",
    header: "04",
    helperText: "Get",
    helperText2: "Paid",
  },
];

export const PublisherCardData = [
  {
    icon: <FiRss size={50} style={cardIconStyle} />,
    circleColor: Colors.lightGreen,
    title: "Higher conversions",
    description:
      "Advertisers at OutreachMantra are actively looking for quality content sites. By listing your site on OutreachMantra, your site gets noticed by thousands of advertisers around the world.",
  },
  {
    icon: <FiTrendingUp size={50} style={cardIconStyle} />,
    circleColor: Colors.lightBlue,
    title: "0 Investments",
    description:
      "Since we do not charge you a fee for joining our platform, you don’t have to worry about paying us to get listed or to stay listed.",
  },
  {
    icon: <FiUsers size={50} style={cardIconStyle} />,
    circleColor: Colors.secondaryColor,
    title: "Timely Payouts",
    description:
      "Our finance team takes care of your payments. The dues are cleared every Tuesday of the week.",
  },
  {
    icon: <FiHeadphones size={50} style={cardIconStyle} />,
    circleColor: Colors.hotPink,
    title: "24x7 Support",
    description:
      "If there’s anything bugging you, reach out to us on our email and talk to an expert.",
  },
];

export const ContentWritingCardData = [
  {
    icon: <AiOutlineBulb size={50} style={cardIconStyle} />,
    circleColor: Colors.lightGreen,
    title: "Expert Writers",
    description:
      "Our team of well-versed writers focuses on creating quality, data-rich, and research-oriented content that performs well on SERP. The content you receive passes several quality checks, such as grammatical checks, plagiarism checks, and fact checks.",
  },
  {
    icon: <FiSend size={50} style={cardIconStyle} />,
    circleColor: Colors.lightBlue,
    title: "Data-Rich content",
    description:
      "People love reading content that’s well researched and fulfills their needs. The writers at Outreachmantra collect information from various sources while writing content so that your audience remains satisfied.",
  },
  {
    icon: <FiBookOpen size={50} style={cardIconStyle} />,
    circleColor: Colors.secondaryColor,
    title: "Increase Engagement",
    description:
      "Bounce rate plays a vital role in determining the quality of your content. With advanced techniques of research and the right choice of words, we deliver engaging content that your audience will love reading. ",
  },
  {
    icon: <FiArrowUpCircle size={50} style={cardIconStyle} />,
    circleColor: Colors.hotPink,
    title: "Stay Updated with your Order",
    description:
      "Once you are done sending your content requirements, we forward the details to our writers and update you for even the tiniest activity of your order. ",
  },
];

export const CarouselImages = [
  {
    id: 1,
    imgSrc:
      "https://images.unsplash.com/photo-1549924231-f129b911e442?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
  },
  {
    id: 1,
    imgSrc:
      "https://images.unsplash.com/photo-1549924231-f129b911e442?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
  },
  {
    id: 1,
    imgSrc:
      "https://images.unsplash.com/photo-1549924231-f129b911e442?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
  },
  {
    id: 1,
    imgSrc:
      "https://images.unsplash.com/photo-1549924231-f129b911e442?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
  },
  {
    id: 1,
    imgSrc:
      "https://images.unsplash.com/photo-1549924231-f129b911e442?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
  },
  {
    id: 1,
    imgSrc:
      "https://images.unsplash.com/photo-1549924231-f129b911e442?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
  },
  {
    id: 1,
    imgSrc:
      "https://images.unsplash.com/photo-1549924231-f129b911e442?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
  },
  {
    id: 1,
    imgSrc:
      "https://images.unsplash.com/photo-1549924231-f129b911e442?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
  },
  {
    id: 1,
    imgSrc:
      "https://images.unsplash.com/photo-1549924231-f129b911e442?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
  },
  {
    id: 1,
    imgSrc:
      "https://images.unsplash.com/photo-1549924231-f129b911e442?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
  },
  {
    id: 1,
    imgSrc:
      "https://images.unsplash.com/photo-1549924231-f129b911e442?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80",
  },
];

export const AdvertiserCardData = [
  {
    icon: <AiOutlineBulb size={50} style={cardIconStyle} />,
    circleColor: Colors.lightGreen,
    title: "Filters",
    description:
      "With advanced filters & sorting, you can choose publishers that meet your requirements. There are a handful of parameters to choose from, such as DA, traffic, Price, niche etc.",
  },
  {
    icon: <FiSend size={50} style={cardIconStyle} />,
    circleColor: Colors.lightBlue,
    title: "Extensive database",
    description:
      "At OutreachMantra, our primary focus is to keep our database huge & updated. We’ve got an extensive database of sites in various niches, be it, technology, automotive, finance, health, pet, marketing sites, and so on.",
  },
  {
    icon: <FiBookOpen size={50} style={cardIconStyle} />,
    circleColor: Colors.secondaryColor,
    title: "Easy payments",
    description:
      "We provide various payment methods for you to choose from so that you only focus on choosing relevant publishers and stay free from the worries of finding a compatible credit card.",
  },
  {
    icon: <FiArrowUpCircle size={50} style={cardIconStyle} />,
    circleColor: Colors.hotPink,
    title: "Get notified",
    description:
      "Any progress that your order makes gets instantly updated in your dashboard. We also send order updates right in your inbox so that you can stay updated with your order status.",
  },
];

export const MemberCardData = [
  {
    icon: <FiGlobe size={50} style={cardIconStyle} />,
    circleColor: Colors.secondaryColor,
    title: "High authority & traffic sites",
    description:
      "OutreachMantra connects you with webmasters having high authority & genuine sites. Our team manually verify & approve sites, which saves your time.",
  },
  {
    icon: <FiBookOpen size={50} style={cardIconStyle} />,
    circleColor: Colors.hotPink,
    title: "Hassle-free publishing ",
    description:
      "As soon as an order is placed, our priority is to get it published as fast as possible.",
  },
  {
    icon: <FiLink size={50} style={cardIconStyle} />,
    circleColor: Colors.lightGreen,
    title: "Filter according to your needs",
    description:
      "Our user-oriented filters help you to quickly find sites according to your requirements. The filters help you pick the best ones from our database.",
  },
  {
    icon: <FiBell size={50} style={cardIconStyle} />,
    circleColor: Colors.yellow,
    title: "Track the progress",
    description:
      "Once your order is placed, all information related to it can be tracked on the dashboard. Our automated systems even send your emails as soon as the content gets published.",
  },
  {
    icon: <FiFileText size={50} style={cardIconStyle} />,
    circleColor: Colors.lightBlue,
    title: "Dofollow Links",
    description:
      "OutreachMantra understands the importance of link juice, so your links are marked as Dofollow.",
  },
  {
    icon: <FiUsers size={50} style={cardIconStyle} />,
    circleColor: Colors.orange,
    title: "Content Creation",
    description:
      "Content is the king! OutreachMantra understands the importance of quality content. Our wordsmith writers are well versed in writing content with facts & figures.",
  },
];
