import React from "react";
import errorAlert from "../../assets/Lottie/error.webp";
import PrimaryButton from "../PrimaryButton";
import { useSelector } from "react-redux";
import { getContent } from "../../localization/index";

const DashboardTableError = ({ onRetry, customMessage }) => {
  const language = useSelector((state) => state.language);

  return (
    <div className="d-flex flex-column flex-grow-1  justify-content-center align-items-center">
      <div className="d-flex justify-content-center ">
        <img src={errorAlert} height={120} width={140} />
      </div>
      <h4 className="text-center text-theme fw-bold mt-2">
        {/* {customMessage || "Error while fetching orders"} */}
        {getContent(language, "ERROR_HEADER")}
      </h4>
      <h6 className="text-theme mb-3">
        {getContent(language, "ERROR_DESCRIPTION")}
      </h6>
      <div className="d-flex flex-row align-items-center justify-content-center">
        <PrimaryButton className="text-center" onClick={onRetry}>
          Retry
        </PrimaryButton>
      </div>
    </div>
  );
};

export default DashboardTableError;
