import React, { useEffect, useState } from "react";
import PaypalLogo from "../assets/logo_paypal.png";
import RazorpayLogo from "../assets/logo_razorpay.png";
import ThemeButton from "./ThemeButton";
import Modal from "react-modal";
import {
  approvePaypalPayment,
  createPaypalPayment,
} from "../services/BillingAndFundsService";
import { getExchangeRate } from "../services/ExchangeRateService";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  clearReduxInitial,
  setPaymentStatusData,
  setPaymentStatusOrderId,
  setRazorpayPaymentId,
  setReloadBillingAndFunds,
  setUserProfileData,
} from "../redux/auth/ActionCreator";
import Chips from "./Chips";
import { useHistory } from "react-router-dom";
import { handleErrorBlock } from "../utils/error";
import ModalWrapper from "./ModalWrapper";
import successLottie from "../assets/Lottie/tick.png";
import alertLottie from "../assets/Lottie/prod-error.png";
import Colors from "../constants/colors";
import { getContent } from "../localization/index";
import InScreenLoader from "./InScreenLoader";
import DashboardTableError from "./DashboardComponents/DashboardTableError";

const AddFundsModal = ({
  setShowFullScreenLoader,
  setFullScreenLoaderText,
  isOpen,
  closeModal,
  cart,
  defaultAmount,
  updateList,
}) => {
  const language = useSelector((state) => state.language);

  const [addFundsSuccessText, setaddFundsSuccessText] = useState("");

  const [exchangeRate, setExchangeRate] = useState(0);

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const openSuccessModal = () => setSuccessModalOpen(true);
  const closeSuccessModal = () => setSuccessModalOpen(false);

  const [orderId, setOrderId] = useState("");
  const [amount, setAmount] = useState(defaultAmount || null);
  const dispatch = useDispatch();
  const history = useHistory();

  const profileDetails = useSelector((state) => state.profileDetails);
  const paymentStatusData = useSelector((data) => data.paymentStatusData);
  const paymentStatusOrderId = useSelector(
    (data) => data.paymentStatusDataOrderId
  );

  const minimumPaymentAmount =
    useSelector((state) => state.dashboardConfig.minimumPaymentAmount) || 0;
  const transactionCharge =
    useSelector((state) => state.dashboardConfig.transactionCharge) || 0;

  const transactionChargeDtoMap = useSelector(
    (state) => state.dashboardConfig?.transactionChargeDtoMap?.PAYPAL
  );

  const razorPayTransactionChargeDtoMap = useSelector(
    (state) => state.dashboardConfig?.transactionChargeDtoMap?.RAZORPAY
  );

  const addFundsAmountListing =
    useSelector((state) => state.dashboardConfig.addFundsAmountListing) || [];

  const razorpayPaymentId = useSelector((state) => state.razorpayPaymentId);

  const [amountError, setAmountError] = useState("");
  const [razorpayAmountError, setRazorpayAmountError] = useState("");

  //
  const [razorpayAmount, setRazorpayAmount] = useState(null);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [retry, setRetry] = useState(false);

  const handleRetry = () => {
    setIsLoading(true);
    fetchExchangeRate();
  };

  // useEffect(() => {
  //   fetchExchangeRate()
  // }, [retry])

  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    setShowFullScreenLoader(false);

    if (
      localStorage.getItem("payment_response_status")?.length > 0 &&
      localStorage.getItem("payment_response_message").length > 0
    ) {
      // Preserving message and status
      setStatus(localStorage.getItem("payment_response_status"));
      setMessage(localStorage.getItem("payment_response_message"));

      openSuccessModal();

      // Resetting values
      localStorage.setItem("payment_response_status", "");
      localStorage.setItem("payment_response_message", "");

      dispatch(setReloadBillingAndFunds(true));
    }
  }, [
    localStorage.getItem("payment_response_status"),
    localStorage.getItem("payment_response_message"),
  ]);

  useEffect(() => {
    if (isOpen === true) {
      fetchExchangeRate();
    }
  }, [isOpen]);

  async function fetchExchangeRate() {
    setIsLoading(true);

    try {
      // setShowFullScreenLoader(true);

      const response = await getExchangeRate();
      setExchangeRate(response?.data?.exchangeRate);

      setError(false);
      // setShowFullScreenLoader(false);

      setIsLoading(false);
    } catch (e) {
      setError(true);
      // setShowFullScreenLoader(false);

      handleErrorBlock("Get Exchange Rate: Error ->", e, history, dispatch);

      setIsLoading(false);
    }
  }

  useEffect(() => {
    setAmount(defaultAmount);
  }, [defaultAmount]);

  useEffect(() => {
    console.log("orderId", orderId);
    console.log("paymentStatusData", paymentStatusData);
    // if (paymentStatusData !== "" && orderId !== "") {
    //   approvePaypal();
    // }
  }, [paymentStatusData, orderId]);

  // useEffect(() => {
  //   if (razorpayPaymentId !== "" && orderId !== "") {
  //     approveRazorpay();
  //   }
  // }, [razorpayPaymentId, orderId]);

  async function approveRazorpay() {
    let localRazorPaymentId = localStorage.getItem("razorpayPaymentId");
    let localOrderId = localStorage.getItem("orderId");
    try {
      setShowFullScreenLoader(true);
      setFullScreenLoaderText(getContent(language, "MODAL_FINALIZING"));
      const response = await approvePaypalPayment({
        orderId: localOrderId,
        paymentType: "RAZORPAY",
        paymentOrderId: razorpayPaymentId || localRazorPaymentId,
      });

      setaddFundsSuccessText(response?.data?.message);

      localStorage.removeItem("razorpayPaymentId");
      dispatch(setRazorpayPaymentId(""));
      setRazorpayAmount("");
      openSuccessModal();
      setShowFullScreenLoader(false);
      localStorage.removeItem("orderId");
      dispatch(
        setUserProfileData({
          ...profileDetails,
          totalAmount: response.data.totalAmount,
          amount: response.data.amount,
          inventoryAccess: response.data.inventoryAccess,
        })
      );

      updateList && updateList();

      dispatch(setReloadBillingAndFunds(true));
    } catch (e) {
      dispatch(clearReduxInitial());

      handleErrorBlock("Approve Razorpay: Error ->", e, history, dispatch);

      setFullScreenLoaderText("");
      setShowFullScreenLoader(false);

      updateList && updateList();

      dispatch(setReloadBillingAndFunds(true));
    }
  }

  useEffect(() => {
    console.log(orderId, "this is order id");
  }, [orderId]);

  async function approvePaypal() {
    let localPaypalOrderId = localStorage.getItem("paypalOrderId");
    let localPayerId = localStorage.getItem("payerId");
    let localOrderId = localStorage.getItem("orderId");
    console.log("payment approved");

    try {
      setShowFullScreenLoader(true);
      setFullScreenLoaderText(getContent(language, "MODAL_FINALIZING"));
      const response = await approvePaypalPayment({
        orderId: localOrderId,
        paymentOrderId: paymentStatusData.paypalOrderId || localPaypalOrderId,
        payerId: paymentStatusData.payerId || localPayerId,
        paymentType: "PAYPAL",
      });

      setaddFundsSuccessText(response?.data?.message);

      dispatch(setPaymentStatusOrderId(""));
      dispatch(setPaymentStatusData(""));
      // setOrderId("");
      setAmount("");
      setShowFullScreenLoader(false);
      openSuccessModal();
      dispatch(
        setUserProfileData({
          ...profileDetails,
          totalAmount: response.data.totalAmount,
          amount: response.data.amount,
          inventoryAccess: response.data.inventoryAccess,
        })
      );
      localStorage.removeItem("paypalOrderId");
      localStorage.removeItem("payerId");
      localStorage.removeItem("orderId");
      updateList && updateList();

      dispatch(setReloadBillingAndFunds(true));
    } catch (e) {
      dispatch(clearReduxInitial());

      handleErrorBlock("Approve Paypal: Error ->", e, history, dispatch);

      setFullScreenLoaderText("");
      setShowFullScreenLoader(false);

      updateList && updateList();

      dispatch(setReloadBillingAndFunds(true));
    }
  }

  async function add(type) {
    // Validation
    if (type === "PAYPAL") {
      if (!amount || amount <= 0) {
        setAmountError("Amount is required");
        toast.error("Amount is required");
        return;
      } else if (!cart && amount < minimumPaymentAmount) {
        setAmountError(`Minimum Payment Amount is $${minimumPaymentAmount}`);
        toast.error(`Minimum Payment Amount is $${minimumPaymentAmount}`);
        return;
      } else {
        setAmountError("");
      }
    }

    if (type === "RAZORPAY") {
      if (!razorpayAmount || razorpayAmount <= 0) {
        setRazorpayAmountError("Amount is required");
        toast.error("Amount is required");
        return;
      } else if (!cart && razorpayAmount < minimumPaymentAmount) {
        setRazorpayAmountError(
          `Minimum Payment Amount is $${minimumPaymentAmount}`
        );
        toast.error(`Minimum Payment Amount is $${minimumPaymentAmount}`);
        return;
      } else {
        setRazorpayAmountError("");
      }
    }

    console.log(window.location.pathname);
    localStorage.setItem("REDIRECT_PATH", window.location.pathname);

    try {
      closeModal();
      setShowFullScreenLoader(true);
      setFullScreenLoaderText(
        getContent(language, "MODAL_PAYMENT_IN_PROGRESS")
      );

      const body = {
        amount:
          type === "RAZORPAY" ? parseInt(razorpayAmount) : parseInt(amount),
        paymentType: type,
        transactionAmount:
          type === "RAZORPAY"
            ? (parseInt(razorpayAmount) *
                exchangeRate *
                razorPayTransactionChargeDtoMap.transactionCharge) /
              100
            : +(
                (parseInt(amount) * transactionChargeDtoMap.transactionCharge) /
                100
              ).toFixed(2),
        totalAmount:
          type === "RAZORPAY"
            ? parseInt(razorpayAmount * exchangeRate) +
              (parseInt(razorpayAmount) *
                exchangeRate *
                razorPayTransactionChargeDtoMap.transactionCharge) /
                100
            : +(
                parseInt(amount) +
                (parseInt(amount) * transactionChargeDtoMap.transactionCharge) /
                  100
              ).toFixed(2),
        transactionChargeDto:
          type === "RAZORPAY"
            ? razorPayTransactionChargeDtoMap
            : transactionChargeDtoMap,
      };

      const response = await createPaypalPayment(body);
      localStorage.setItem("orderId", response.data.orderId);
      setOrderId(response.data.orderId);
      dispatch(setPaymentStatusOrderId({ orderId: response.data.orderId }));
      localStorage.setItem("orderId", response.data.orderId);

      const opener =
        type === "PAYPAL"
          ? window.open(response.data.paymentLink, "_self")
          : window.open(response.data.paymentLink, "_self");

      const timer = setInterval(function () {
        if (opener?.closed) {
          if (localStorage.getItem("addFundsClose") === "true") {
            type === "PAYPAL" && approvePaypal();
            type === "RAZORPAY" && approveRazorpay();
            localStorage.setItem("addFundsClose", "false");

            setFullScreenLoaderText(getContent(language, "MODAL_FINALIZING"));

            console.log("loader close");
            console.log(
              "paymentStatusData from close event",
              paymentStatusData,
              "orderID from close event",
              orderId
            );
            // if (paymentStatusData !== "" && orderId !== "") {
            //   approvePaypal();
            // }
          } else {
            if (opener.document === undefined) {
              toast.info("Payment not completed by user");
              setFullScreenLoaderText("");
              setShowFullScreenLoader(false);
            }

            updateList && updateList();
            // console.log("payment closed by user");
            dispatch(clearReduxInitial());
          }

          clearInterval(timer);
        }
      }, 1000);
    } catch (e) {
      handleErrorBlock("Create Paypal Payment: Error ->", e, history, dispatch);
      console.log("error in payment");
      setFullScreenLoaderText("");
      setShowFullScreenLoader(false);
      console.log(window.location.pathname);
      localStorage.setItem("REDIRECT_PATH", "");
      updateList && updateList();
    }
  }

  function handleAddAmount(amountToAdd, type) {
    if (type === "paypal") {
      isNaN(parseInt(amount))
        ? setAmount(amountToAdd)
        : setAmount(`${parseInt(amount) + parseInt(amountToAdd)}`);
    }

    if (type === "razorpay") {
      isNaN(parseInt(razorpayAmount))
        ? setRazorpayAmount(amountToAdd)
        : setRazorpayAmount(
            `${parseInt(razorpayAmount) + parseInt(amountToAdd)}`
          );
    }
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "100%",
    },
    overlay: {
      background: "rgba(0,0,0,0.4)",
      zIndex: 20,
    },
  };
  if (successModalOpen === true) {
    return (
      <ModalWrapper
        // title={getContent(language, "MODAL_ADD_FUNDS_SUCCESS")}
        // title={addFundsSuccessText}
        title={message}
        icon={
          status.toLowerCase() === "completed" ||
          status.toLowerCase() === "paid" ||
          status.toLowerCase() === "success"
            ? successLottie
            : alertLottie
        }
        cta={[
          {
            title: "Go Back",
            backgroundColor: Colors.lavender,
            onClick: () => closeSuccessModal(),
          },
        ]}
        isOpen={successModalOpen}
        close={closeModal}
        outsideClickClose={true}
      />
      // <Modal
      //   style={customStyles}
      //   isOpen={successModalOpen}
      //   onRequestClose={closeModal}
      // >
      //   <div>
      //     <Lottie options={defaultOptions} height={150} width={150} />
      //   </div>
      //   <p className="text-theme fw-bold">Funds Added Successfully</p>

      //   <div className="d-flex justify-content-center">
      //     <PrimaryButton onClick={() => closeSuccessModal()}>
      //       Go Back
      //     </PrimaryButton>
      //   </div>
      // </Modal>
    );
  }

  if (isOpen === true) {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        onAfterClose={() => {
          if (!cart) {
            setAmount("");
            setRazorpayAmount("");
          }
        }}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="d-flex flex-wrap ">
          {/* Paypal */}
          <div className="mt-5 mb-1 mx-4 pe-lg-5 border-lg-end">
            <div className="d-flex flex-column align-items-center">
              {/* <FaPaypal size={"6em"} color="#BE98E8" /> */}
              <img
                style={{ width: "3.5em", height: "3.5em" }}
                src={PaypalLogo}
                alt="Paypal"
              />

              <h4 className="text-theme fw-boldest mt-1 mb-3">PAYPAL</h4>
              <h6 className="fst-italic text-theme fw-bold  mb-3">
                Add amount in wallet using PayPal
              </h6>
            </div>
            {/*{!cart && (*/}
            {/*  <p*/}
            {/*    className="m-0  text-center text-danger mb-2 "*/}
            {/*    style={{ fontSize: ".8em" }}*/}
            {/*  >*/}
            {/*    Minimum amount added will be ${minimumPaymentAmount}*/}
            {/*  </p>*/}
            {/*)}*/}
            <div className="d-flex flex-column">
              <label
                htmlFor=""
                className="billing-modal-label order-filter-label"
              >
                {getContent(language, "LABEL_ADD_FUNDS_ENTER_AMOUNT")}
              </label>
              <div className="d-flex position-relative">
                <span
                  className="position-absolute text-theme fw-bold "
                  style={{ left: 8, top: 7 }}
                >
                  $
                </span>
                <input
                  autoFocus={true}
                  value={amount}
                  onChange={(e) => {
                    if (e.target.value > -1) {
                      setAmount(e.target.value);
                    }
                  }}
                  type="number"
                  min={0}
                  placeholder={getContent(
                    language,
                    "PH_ADD_FUNDS_ENTER_AMOUNT"
                  )}
                  className="form-control text-theme fw-bold bnf-modal-input ps-4 "
                />
              </div>

              {/* <p
              style={{ height: "24px", fontSize: "0.8em" }}
              className="text-danger m-0"
            >
              {amountError}
            </p> */}

              <p
                style={{ height: "24px", fontSize: "0.7em", color: "darkgray" }}
                className="m-0 mb-1"
              >
                Minimum Payment Amount is ${minimumPaymentAmount}
              </p>
            </div>

            <div
              className="d-flex mb-3"
              style={{ height: "24px", fontSize: "0.8em" }}
            >
              {addFundsAmountListing.map((amountToAdd) => (
                <div
                  className="c-ptr"
                  onClick={() => handleAddAmount(amountToAdd, "paypal")}
                >
                  <Chips text={`+$${amountToAdd}`} />
                </div>
              ))}
            </div>

            <div
              className="d-flex justify-content-end align-items-center"
              style={{ height: "24px" }}
            ></div>

            {!isNaN(
              (parseInt(amount) * transactionChargeDtoMap.transactionCharge) /
                100
            ) ? (
              <div
                className="d-flex justify-content-end align-items-center"
                style={{ height: "24px" }}
              >
                {(parseInt(amount) *
                  transactionChargeDtoMap.transactionCharge) /
                  100 >
                  0 && (
                  <div className="d-flex align-items-center">
                    <p
                      className="m-0 me-2 text-theme fw-semiBold "
                      style={{ fontSize: "0.8em" }}
                    >
                      TRANSACTION CHARGE
                    </p>
                    <p className="m-0 text-theme fw-bold">
                      $
                      {(
                        (parseInt(amount) *
                          transactionChargeDtoMap.transactionCharge) /
                        100
                      ).toFixed(2)}
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div style={{ height: "24px" }}></div>
            )}

            <hr className="m-0 bg-dark text-dark" />

            <div className="d-flex justify-content-end align-items-center">
              <p
                className="m-0 me-2 text-theme fw-semiBold "
                style={{ fontSize: "0.8em" }}
              >
                TOTAL
              </p>
              <p className="m-0 text-theme fw-bold">
                $
                {(
                  (parseInt(amount) *
                    transactionChargeDtoMap.transactionCharge) /
                    100 +
                    parseInt(amount) || 0
                ).toFixed(2)}
              </p>
            </div>

            <div className="d-flex justify-content-center my-2 mt-4">
              <ThemeButton onClick={() => add("PAYPAL")} className="px-5 py-2">
                ADD
              </ThemeButton>
            </div>
          </div>

          {/* Razorpay */}
          {isLoading === true && (
            <div style={{ width: 365 }} className="mt-5 mb-1">
              <InScreenLoader />
            </div>
          )}

          {error === true && isLoading === false && (
            <div style={{ width: 365 }} className="mt-5 mb-1 mx-4">
              <DashboardTableError onRetry={handleRetry} />
            </div>
          )}

          {error === false && isLoading === false && (
            <div className="mt-5 mb-1 mx-4">
              <div className="d-flex flex-column align-items-center">
                {/* <FaPaypal size={"6em"} color="#BE98E8" /> */}
                <img
                  style={{ width: "3.5em", height: "3.5em" }}
                  src={RazorpayLogo}
                  alt="Razorpay"
                />

                <h4 className="text-theme fw-boldest mt-1 mb-3">RAZORPAY</h4>
                <h6 className="fst-italic text-theme fw-bold  mb-3">
                  Add amount in wallet using Razorpay
                </h6>
              </div>
              {/*{!cart && (*/}
              {/*  <p*/}
              {/*    className="m-0  text-center text-danger mb-2 "*/}
              {/*    style={{ fontSize: ".8em" }}*/}
              {/*  >*/}
              {/*    Minimum amount added will be ${minimumPaymentAmount}*/}
              {/*  </p>*/}
              {/*)}*/}
              <div className="d-flex flex-column">
                <label
                  htmlFor=""
                  className="billing-modal-label order-filter-label"
                >
                  {getContent(language, "LABEL_ADD_FUNDS_ENTER_AMOUNT")}
                </label>
                <div className="d-flex position-relative">
                  <span
                    className="position-absolute text-theme fw-bold "
                    style={{ left: 8, top: 7 }}
                  >
                    $
                  </span>
                  <input
                    value={razorpayAmount}
                    onChange={(e) => {
                      if (e.target.value > -1) {
                        setRazorpayAmount(e.target.value);
                      }
                    }}
                    type="number"
                    min={0}
                    placeholder={getContent(
                      language,
                      "PH_ADD_FUNDS_ENTER_AMOUNT"
                    )}
                    className="form-control text-theme fw-bold bnf-modal-input ps-4 "
                  />
                </div>

                {/* <p
              style={{ height: "24px", fontSize: "0.8em" }}
              className="text-danger m-0"
            >
              {amountError}
            </p> */}

                <p
                  style={{
                    height: "24px",
                    fontSize: "0.7em",
                    color: "darkgray",
                  }}
                  className="m-0 mb-1"
                >
                  Minimum Payment Amount is ${minimumPaymentAmount}
                </p>
              </div>

              <div
                className="d-flex mb-3"
                style={{ height: "24px", fontSize: "0.8em" }}
              >
                {addFundsAmountListing.map((amountToAdd) => (
                  <div
                    className="c-ptr"
                    onClick={() => handleAddAmount(amountToAdd, "razorpay")}
                  >
                    <Chips text={`+$${amountToAdd}`} />
                  </div>
                ))}
              </div>

              <div
                className="d-flex justify-content-end align-items-center"
                style={{ height: "24px" }}
              >
                {razorpayAmount && parseInt(razorpayAmount) > 0 && (
                  <div className="d-flex align-items-center">
                    <p
                      className="m-0 me-2 text-theme fw-semiBold "
                      style={{ fontSize: "0.8em" }}
                    >
                      SUBTOTAL
                    </p>
                    <p className="m-0 text-theme fw-bold">
                      ₹{+(exchangeRate * razorpayAmount).toFixed(2)}
                    </p>
                  </div>
                )}
              </div>

              {!isNaN(
                (parseInt(razorpayAmount) *
                  razorPayTransactionChargeDtoMap.transactionCharge) /
                  100
              ) ? (
                <div
                  className="d-flex justify-content-end align-items-center"
                  style={{ height: "24px" }}
                >
                  {(parseInt(razorpayAmount) *
                    razorPayTransactionChargeDtoMap.transactionCharge) /
                    100 >
                    0 && (
                    <div className="d-flex align-items-center">
                      <p
                        className="m-0 me-2 text-theme fw-semiBold "
                        style={{ fontSize: "0.8em" }}
                      >
                        {razorPayTransactionChargeDtoMap?.transactionCharge}%
                        GST
                      </p>
                      <p className="m-0 text-theme fw-bold">
                        ₹
                        {(
                          (parseInt(razorpayAmount) *
                            exchangeRate *
                            razorPayTransactionChargeDtoMap.transactionCharge) /
                          100
                        ).toFixed(2)}
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <div style={{ height: "24px" }}></div>
              )}

              <hr className="m-0 bg-dark text-dark" />

              <div className="d-flex justify-content-end align-items-center">
                <p
                  className="m-0 me-2 text-theme fw-semiBold "
                  style={{ fontSize: "0.8em" }}
                >
                  TOTAL
                </p>
                <p className="m-0 text-theme fw-bold">
                  ₹
                  {(
                    parseInt(razorpayAmount * exchangeRate) +
                      (parseInt(razorpayAmount) *
                        exchangeRate *
                        razorPayTransactionChargeDtoMap.transactionCharge) /
                        100 || 0
                  ).toFixed(2)}
                </p>
              </div>

              <div className="d-flex justify-content-center my-2 mt-4">
                <ThemeButton
                  onClick={() => add("RAZORPAY")}
                  className="px-5 py-2"
                >
                  ADD
                </ThemeButton>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-3">
          {getContent(language, "LABEL_CREATE_PAYPAL_INVOICE")}
          <div className="d-flex justify-content-center my-2">
            <ThemeButton
              onClick={() => {
                history.push("/advertiser/paypal-invoice/create");
                closeModal();
              }}
              className="px-5 py-2"
            >
              Create Paypal Invoice
            </ThemeButton>
          </div>
        </div>
      </Modal>
    );
  } else {
    return <></>;
  }
};

export default AddFundsModal;
