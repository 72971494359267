import React from "react";
import { useSelector } from "react-redux";
import { getContent } from "../localization/index";

const EmptyComponent = ({ message, lottieFile, flexGrow = "1" }) => {
  const language = useSelector(state => state.language);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: lottieFile,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      className={`d-flex flex-grow-${flexGrow} flex-column justify-content-center align-items-center`}
    >
      <div className="d-flex justify-content-center">
        <img src={lottieFile} height={120} width={140} />
      </div>
      {/* <h4 className="text-theme fw-bold">{message}</h4> */}
      <h4 className="text-theme fw-bold mt-3">
        {getContent(language, "EMPTY_LISTING_HEADER")}
      </h4>
      <h6 className="text-theme">
        {message ? message : getContent(language, "EMPTY_LISTING_SUBHEADER")}
      </h6>
    </div>
  );
};

export default EmptyComponent;
