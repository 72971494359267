import axios from "axios";
import { fetchJWTToken } from "../utils/auth";
import { BASE_URL } from "../constants/Endpoints";

export function getNotifications({ pageNumber, preference }) {
  return axios({
    method: "GET",
    url: `${BASE_URL}/om/notification/list?pageNumber=${pageNumber}&userType=${preference}&pageSize=${10}`,
    headers: {
      Authorization: fetchJWTToken(),
    },
  });
}

export function readAllNotifications({ userType }) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/om/notification/read-all`,
    data: { userType },
    headers: {
      Authorization: fetchJWTToken(),
    },
  });
}
