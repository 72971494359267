import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { setUserProfileData } from "../redux/auth/ActionCreator";
import { logoutUser } from "../services/UserService";
import { fetchJWTToken, removeJWTToken } from "../utils/auth";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { handleErrorBlock } from "../utils/error";
import { getContent } from "../localization/index";
import { CURRENT_URL, EVENT_NAME, GAevent } from "../utils/Events";

const ProfileDropdownMenu = ({
  preference,
  profileDetails,
  setShowFullScreenLoader,
}) => {
  const language = useSelector((state) => state.language);

  const dispatch = useDispatch();
  const history = useHistory();
  const [visibility, setVisibility] = useState(false);
  const [showName, setShowName] = useState(false);
  async function logout() {
    try {
      const jwtToken = fetchJWTToken();
      if (!jwtToken) {
        dispatch(setUserProfileData(""));
        window.location.replace("/login");
      }
      setShowFullScreenLoader(true);
      const response = await logoutUser(jwtToken);
      setShowFullScreenLoader(false);
      dispatch(setUserProfileData(""));
      removeJWTToken();
      window.location.replace("/");
      toast.info("Logged Out Successfully");
    } catch (e) {
      handleErrorBlock("Logout -> Error ->", e, history, dispatch);
      setShowFullScreenLoader(false);
    }
  }
  const ref = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (visibility && ref.current && !ref.current.contains(e.target)) {
        setVisibility(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [visibility]);

  // useEffect(() => {
  //   if (profileDetails.avatar === "" && profileDetails.avatar === null) {
  //     setShowName(true);
  //   } else {
  //     setShowName(false);
  //   }
  // }, [profileDetails]);
  return (
    <div ref={ref} className="position-relative">
      <div
        className="c-ptr"
        onClick={() => {
          history.push("/user/profile");
          GAevent(EVENT_NAME.myprofile, [
            CURRENT_URL + "/user/profile",
            profileDetails?.preference,
          ]);
        }}
      >
        {profileDetails?.avatar === "" || profileDetails?.avatar == null ? (
          <div
            style={{ backgroundColor: "#9847EE" }}
            className="user-nav-name-avatar shadow"
          >
            {profileDetails && profileDetails?.name?.charAt(0).toUpperCase()}
          </div>
        ) : (
          <img
            src={profileDetails?.avatar}
            alt=""
            className="user-nav-avatar"
          />
        )}
        <div className="user-nav-avatar-badge" />
      </div>
      {visibility && (
        <div className="profile-menu-container rounded shadow">
          <div className="d-flex flex-column profile-menu">
            <div
              className=" p-3 py-2 text-center c-ptr text-theme "
              onClick={() => history.push("/change-password")}
            >
              {getContent(language, "PROFILE_DROPDOWN_CHANGE_PASSWORD")}
            </div>
            <hr className="m-0" />
            <div
              className=" text-center c-ptr text-theme p-3 py-2 "
              onClick={logout}
            >
              {getContent(language, "PROFILE_DROPDOWN_LOGOUT")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileDropdownMenu;
