import {
  SET_ADVERTISER_NOTIFICATIONS_COUNT,
  SET_DASHBOARD_BADGES,
  SET_DASHBOARD_CONFIG,
  SET_EDIT_WEBSITE_DATA,
  SET_GA_AUTH_CODE,
  SET_LANGUAGE,
  SET_MARKETPLACE_CHECKOUT_DATA,
  SET_MY_WEBSITE_CONSENT_STATUS_CODE,
  SET_PAYMENT_STATUS_DATA,
  SET_PAYMENT_STATUS_ORDER_ID,
  SET_PUBLISHER_NOTIFICATIONS_COUNT,
  SET_USER_PROFILE_DATA,
  UPDATE_CART_DELETE_PREFERENCE,
  UPDATE_USER_PREFERENCE,
  UPDATE_WISHLIST_DELETE_PREFERENCE,
  SET_RAZORPAY_PAYMENT_ID,
  CLEAR_REDUX_INITIAL,
  SET_RELOAD_BILLING_AND_FUNDS
} from "./ActionTypes";

export const setReloadBillingAndFunds = bool => {
  return {
    type: SET_RELOAD_BILLING_AND_FUNDS,
    payload: bool,
  };
};

export const setUserProfileData = profileData => {
  return {
    type: SET_USER_PROFILE_DATA,
    payload: profileData,
  };
};

export const updateUserPreference = updatedUserData => {
  return {
    type: UPDATE_USER_PREFERENCE,
    payload: updatedUserData,
  };
};

export const setDashboardBadges = badgeData => {
  return {
    type: SET_DASHBOARD_BADGES,
    payload: badgeData,
  };
};

export const setDashboardConfig = dashboardConfigData => {
  return {
    type: SET_DASHBOARD_CONFIG,
    payload: dashboardConfigData,
  };
};

export const setGaAuthCode = authCode => {
  return {
    type: SET_GA_AUTH_CODE,
    payload: authCode,
  };
};

export const setEditWebsiteData = websiteData => {
  return {
    type: SET_EDIT_WEBSITE_DATA,
    payload: websiteData,
  };
};

export const updateCartDeletePreference = preference => {
  return {
    type: UPDATE_CART_DELETE_PREFERENCE,
    payload: preference,
  };
};

export const updateWishlistDeletePreference = preference => {
  return {
    type: UPDATE_WISHLIST_DELETE_PREFERENCE,
    payload: preference,
  };
};

export const setAdvertiserNotificationsCount = count => {
  return {
    type: SET_ADVERTISER_NOTIFICATIONS_COUNT,
    payload: count,
  };
};

export const setPublisherNotificationsCount = count => {
  return {
    type: SET_PUBLISHER_NOTIFICATIONS_COUNT,
    payload: count,
  };
};

export const setPaymentStatusData = paymentStatusData => {
  return {
    type: SET_PAYMENT_STATUS_DATA,
    payload: paymentStatusData,
  };
};

export const setPaymentStatusOrderId = paymentStatusOrderId => {
  return {
    type: SET_PAYMENT_STATUS_ORDER_ID,
    payload: paymentStatusOrderId,
  };
};

export const setMyWebsiteConsentStatusCode = code => {
  return {
    type: SET_MY_WEBSITE_CONSENT_STATUS_CODE,
    payload: code,
  };
};

export const setMarketplaceCheckoutData = data => {
  return {
    type: SET_MARKETPLACE_CHECKOUT_DATA,
    payload: data,
  };
};

export const setLanguage = language => {
  return {
    type: SET_LANGUAGE,
    payload: language,
  };
};

export const setRazorpayPaymentId = id => {
  return {
    type: SET_RAZORPAY_PAYMENT_ID,
    payload: id,
  };
};

export const clearReduxInitial = () => {
  return {
    type: CLEAR_REDUX_INITIAL,
    payload: "",
  };
};
