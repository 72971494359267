export default function Chips({
  text,
  onClick,
  className,
  containerStyle,
  style,
  chipContainerClass,
}) {
  return (
    <div
      className={`chips-container d-flex me-1 ${chipContainerClass} `}
      onClick={onClick}
      style={containerStyle}
    >
      <div
        className={`textContainer px-2 py-1 text-nowrap ${className}`}
        style={style}
      >
        {text}
      </div>
    </div>
  );
}
