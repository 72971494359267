import React from "react";
import OrderContentButton from "./OrderContentButton";
import AddWebisteButton from "./AddWebisteButton";
import AddPaypalInvoiceButton from "./AddPaypalInvoiceButton";

export const UserNavData = [
  {
    route: "/advertiser/dashboard",
    pageTitle: "DASHBOARD",
    customButton: "",
  },
  {
    route: "/advertiser/marketplace",
    pageTitle: "MARKETPLACE",
    customButton: "",
  },

  {
    route: "/advertiser/my-orders",
    pageTitle: "MY ORDERS",
    customButton: "",
  },
  {
    route: "/advertiser/content-writing",
    pageTitle: "CONTENT WRITING",
    customButton: <OrderContentButton />,
  },
  {
    route: "/advertiser/paypal-invoice",
    pageTitle: "Paypal Invoices",
    customButton: <AddPaypalInvoiceButton/>,
  },
  {
    route: "/advertiser/paypal-invoice/create",
    pageTitle: "Create Invoice",
    customButton: "",
  },
  
  {
    route: "/advertiser/paypal-invoice/edit",
    pageTitle: "Edit Invoice",
    customButton: "",
  },

  {
    route: "/advertiser/billing-funds",
    pageTitle: "BILLING & FUNDS",
    customButton: "",
  },
  {
    route: "/advertiser/reports",
    pageTitle: "REPORTS",
    customButton: "",
  },
  {
    route: "/publisher/dashboard",
    pageTitle: "DASHBOARD",
    customButton: "",
  },
  {
    route: "/publisher/my-website",
    pageTitle: "MY WEBSITES",
    customButton: <AddWebisteButton />,
  },
  {
    route: "/publisher/my-orders",
    pageTitle: "MY ORDERS",
    customButton: "",
  },
  {
    route: "/publisher/payments",
    pageTitle: "PAYMENTS",
    customButton: "",
  },
  {
    route: "/publisher/reports",
    pageTitle: "REPORTS",
    customButton: "",
  },
  {
    route: "/advertiser/order-content-writing",
    pageTitle: "ORDER CONTENT",
    customButton: "",
  },
  {
    route: "/publisher/add-website",
    pageTitle: "ADD WEBSITE",
    customButton: "",
  },
  {
    route: "/publisher/my-website/edit",
    pageTitle: "EDIT WEBSITE",
    customButton: "",
  },
  {
    route: "/publisher/my-website/validate",
    pageTitle: "UPDATE TRAFFIC",
    customButton: "",
  },
  {
    route: "/advertiser/cart",
    pageTitle: "CART",
    customButton: "",
  },
  {
    route: "/wishlist",
    pageTitle: "WISHLIST",
    customButton: "",
  },
  {
    route: "/advertiser/marketplace/checkout",
    pageTitle: "CART",
    customButton: "",
  },
  {
    route: "/user/profile",
    pageTitle: "My Profile",
    customButton: "",
  },
];
