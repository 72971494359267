import React from "react";
import { Link } from "react-router-dom";
import { getContent } from "../../localization/index";
import { useSelector } from "react-redux";
import { NavDropdown } from "react-bootstrap";
import { FiArrowRight, RiArrowDownSLine } from "react-icons/all";
import { GAevent } from "../../utils/Events";
import { EVENT_NAME, CURRENT_URL } from "./../../utils/Events";

const DashboardFooter = () => {
  const language = useSelector((state) => state.language);

  return (
    <div className="w-100 dashboard-footer ">
      <div className=" d-flex flex-row flex-wrap justify-content-between align-items-center  py-3 ">
        <div className="d-flex flex-row align-items-center flex-wrap">
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <Link
              onClick={() => {
                GAevent(EVENT_NAME.landing_our_story, [
                  CURRENT_URL + "/our-story",
                ]);
              }}
              className={`footer-bottom-text text-theme text-decoration-none px-2 text-center text-decoration-none underline`}
              to="/our-story"
              target="_blank"
            >
              Our Story
            </Link>
            |
            <NavDropdown
              title={
                <div className="text-theme react-link me-0  ">
                  <span className="text-decoration-underline">Solutions</span>
                  <RiArrowDownSLine size={20} style={{ marginTop: -2 }} />
                </div>
              }
              className={` navLinks react-link text-theme fw-semiBold text-theme`}
              style={{
                marginRight: -8,
                marginLeft: 0,
                textDecorationColor: "red",
              }}
              id="navDropdown"
            >
              <Link
                id="dropdownLinks"
                className="text-decoration-none"
                to="/guest-posts"
                target="_blank"
                onClick={() => {
                  GAevent(EVENT_NAME.landing_advertiser, [
                    CURRENT_URL + "/guest-posts",
                  ]);
                }}
              >
                ADVERTISERS <FiArrowRight className="arrowIcon" />
              </Link>
              <Link
                className="text-decoration-none nav-item-active"
                id="dropdownLinks"
                to="/publisher"
                target="_blank"
                onClick={() => {
                  GAevent(EVENT_NAME.landing_publisher, [
                    CURRENT_URL + "/publisher",
                  ]);
                }}
              >
                PUBLISHERS <FiArrowRight className="arrowIcon" />
              </Link>
              <Link
                id="dropdownLinks"
                to="/content-writing-services"
                target="_blank"
                className="text-decoration-none text-nowrap "
                onClick={() => {
                  GAevent(EVENT_NAME.landing_solutions_content_writing, [
                    CURRENT_URL + "/content-writing-services",
                  ]);
                }}
              >
                CONTENT WRITING <FiArrowRight className="arrowIcon" />
              </Link>
            </NavDropdown>
            <span style={{ marginLeft: 4 }}>|</span>
            <a
              className="footer-bottom-text text-theme text-decoration-none px-2 text-center text-decoration-none underline"
              href="https://blog.outreachmantra.com/"
              target="_blank"
              onClick={() => {
                GAevent(EVENT_NAME.landing_blog, [
                  "https://blog.outreachmantra.com/",
                ]);
              }}
            >
              Blog
            </a>
            |
            <Link
              className={`footer-bottom-text text-theme text-decoration-none px-2 text-center text-decoration-none underline`}
              to="/faq"
              target="_blank"
              onClick={() => {
                GAevent(EVENT_NAME.landing_faqs, [CURRENT_URL + "/faq", ,]);
              }}
            >
              FAQs
            </Link>
            |
            <Link
              className={`footer-bottom-text text-theme text-decoration-none px-2 text-center text-decoration-none underline`}
              to="/contact-us"
              target="_blank"
              onClick={() => {
                GAevent(EVENT_NAME.landing_contact_us, [
                  CURRENT_URL + "/contact-us",
                  ,
                ]);
              }}
            >
              Contact Us
            </Link>
            |
            <Link
              onClick={() => {
                GAevent(EVENT_NAME.landing_refund_policy, [
                  CURRENT_URL + "/refund-policy",
                  ,
                ]);
              }}
              to="/refund-policy"
              target="_blank"
              className=" footer-bottom-text text-theme text-decoration-none px-2 text-center text-decoration-none underline"
            >
              Refund Policy
            </Link>
            |
            <Link
              onClick={() => {
                GAevent(EVENT_NAME.landing_terms_conditions, [
                  CURRENT_URL + "/terms-and-conditions",
                  ,
                ]);
              }}
              to="/terms-and-conditions"
              target="_blank"
              className=" footer-bottom-text text-theme text-decoration-none px-2 text-center text-decoration-none underline"
            >
              Terms & Conditions
            </Link>
            |
            <Link
              onClick={() => {
                GAevent(EVENT_NAME.landing_privacy_policy, [
                  CURRENT_URL + "/privacy-policy",
                  ,
                ]);
              }}
              to="/privacy-policy"
              target="_blank"
              className=" footer-bottom-text text-theme text-decoration-none px-2 text-center underline"
            >
              Privacy Policy
            </Link>
            |
            <Link
              onClick={() => {
                GAevent(EVENT_NAME.landing_data_deletion_policy, [
                  CURRENT_URL + "/data-deletion-instructions",
                  ,
                ]);
              }}
              to="/data-deletion-instructions"
              target="_blank"
              className=" footer-bottom-text text-theme text-decoration-none px-2 text-center text-decoration-none underline"
            >
              Data Deletion Policy
            </Link>
          </div>
        </div>
        <div className="text-theme footer-bottom-one flex-grow-1 ">
          <p className="border-0 my-md-0 my-2 footer-bottom-text d-flex align-items-center justify-content-center justify-content-lg-end text-decoration-none">
            {getContent(language, "FOOTER_COPYRIGHT")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DashboardFooter;
