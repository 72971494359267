import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {fetchJWTToken} from "../utils/auth";
import {fetchAppConfig} from "../services/ConfigService";
import {clearReduxInitial, setDashboardConfig, setUserProfileData} from "../redux/auth/ActionCreator";
import {handleErrorBlock} from "../utils/error";
import {getProfileDetails} from "../services/UserService";
import FullScreenLoader from "./FullScreenLoader";
import {toast} from "react-toastify";

const AuthWrapper = (props) => {
    const profileDetails = useSelector(state => state.profileDetails);
    const [isLoading, setIsLoading] = useState(true);
    const userPreference = profileDetails?.preference?.toLowerCase();
    const history = useHistory();
    const jwtToken = fetchJWTToken();
    const dispatch = useDispatch();
    const homeConfig = useSelector(state => state.dashboardConfig);
    useEffect(() => {
        (async() => {
            try {
                const response = await fetchAppConfig();
                dispatch(setDashboardConfig(response.data));
                if(jwtToken){
                    getUserDetails();
                }else{
                    setIsLoading(false)
                }
            } catch (e) {
                handleErrorBlock("Home Config (Reload): Error ->", e, history, dispatch);
                if( homeConfig === ""){
                    toast.error('Something Went Wrong')
                }
                setIsLoading(false);
            }
        })()
    },[])



    const getUserDetails = async() => {
        try {
            const response = await getProfileDetails(jwtToken);
            dispatch(
                setUserProfileData({
                    ...response.data,
                    jwtToken: response.headers.authorization,
                })
            );
            const preference = response.data.preference;
            if (preference === "NA") {
                history.push('/set-preference');
            }
            setIsLoading(false);
        } catch (e) {
            handleErrorBlock("Profile Details: Error ->", e, history, dispatch);
            setIsLoading(false);
        }
    }
    return (
  <>
      {isLoading ? <FullScreenLoader/> : props.children}
  </>
    );
};

export default AuthWrapper;
