import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfileData } from "../../redux/auth/ActionCreator";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PaypalResponse = () => {
  let query = useQuery();
  const status = query.get("status");
  const message = query.get("message");
  const amount = query.get("amount");
  const totalAmount = query.get("totalAmount");
  const inventoryAccess = query.get("inventoryAccess");

  const dispatch = useDispatch();
  const profileDetails = useSelector(state => state.profileDetails);

  useEffect(() => {
    if (
      status?.toLowerCase() === "completed" ||
      status?.toLowerCase() === "paid" ||
      status?.toLowerCase() === "success"
    ) {
      dispatch(
        setUserProfileData({
          ...profileDetails,
          totalAmount: Number(totalAmount),
          amount: Number(amount),
          inventoryAccess: JSON.parse(inventoryAccess),
        })
      );
    }

    localStorage.setItem("payment_response_status", status);
    localStorage.setItem("payment_response_message", message);

    const path =  localStorage.getItem("REDIRECT_PATH");
    window.location.href=path;
    
  }, []);

  return <></>;
};

export default PaypalResponse;
