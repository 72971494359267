import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TawkTo from "tawkto-react";
import { TawkContext } from "../context/TawkContext";

const TawkToWrapper = (props) => {
  const location = useLocation();
  const [tawkEnabled, setTawkEnabled] = useState(false);

  const [isLoaded, setIsLoaded, showChatbot, setShowChatbot] =
    useContext(TawkContext);

  useEffect(() => {
    // var tawk = new TawkTo("605c20dbf7ce18270933af71", "1f1ju0nbi");
    var tawk = new TawkTo(
      process.env.REACT_APP_ENVIRONMENT === "DEV"
        ? process.env.REACT_APP_TAWKTO_PROPERTY_ID_DEV
        : process.env.REACT_APP_TAWKTO_PROPERTY_ID_PROD,
      process.env.REACT_APP_ENVIRONMENT === "DEV"
        ? process.env.REACT_APP_TAWKTO_WIDGET_ID_DEV
        : process.env.REACT_APP_TAWKTO_WIDGET_ID_PROD
    );
    tawk.onLoad(() => {
      setTawkEnabled(true);
      setIsLoaded(true);
      tawk.hideWidget();
    });
  }, []);
  return <>{props.children}</>;
};

export default TawkToWrapper;
