import React, { useEffect, useRef } from "react";
import InScreenLoader from "./InScreenLoader";
import DashboardTableError from "./DashboardComponents/DashboardTableError";
import EmptyComponent from "./EmptyComponent";
import alertAnimation from "../assets/Lottie/error.webp";
import LoadMoreLoader from "./LoadMoreLoader";
import Colors from "../constants/colors";
import { FiBell } from "react-icons/all";
import { getContent } from "../localization/index";
import { useSelector } from "react-redux";
import { EVENT_NAME, GAevent } from "../utils/Events";

const NotificationsDropdownMenu = ({
  preference,
  visibility,
  setVisibility,
  isLoading,
  data,
  setData,
  handleRetry,
  error,
  showLoadMore,
  showLoadMoreLoader,
  handleLoadMore,
  handleNotificationsList,
  notificationsCount,
}) => {
  const language = useSelector((state) => state.language);

  useEffect(() => {
    if (error === true) {
      setData([]);
    }
  }, [error]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (visibility && ref.current && !ref.current.contains(e.target)) {
        setVisibility(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [visibility]);

  const ref = useRef(null);
  return (
    <div className="position-relative" ref={ref}>
      <div
        onClick={() => {
          if (visibility === false) {
            handleNotificationsList();
            setVisibility(true);
          } else {
            setVisibility(false);
          }
          GAevent(EVENT_NAME.notification_option, [
            notificationsCount,
            preference,
          ]);
        }}
        className="c-ptr"
      >
        <FiBell className="user-nav-icon c-ptr" />
        {notificationsCount > 0 && (
          <div
            style={{ right: 3 }}
            className="user-nav-badge d-flex align-items-center justify-content-center p-2 text-white fw-bold "
          >
            {notificationsCount > 99 ? "99" : notificationsCount}
          </div>
        )}
      </div>
      {visibility && (
        <div className=" dropdown-menu-container rounded shadow">
          <div className="notification-dropdown-menu no-scrollbar ">
            <h5 className="text-theme">
              {getContent(language, "NOTIFICATIONS_TITLE")}
            </h5>
            <hr className="mb-0" />
            {isLoading && <InScreenLoader style={{ height: 400 }} />}
            {data.length === 0 && error === false && isLoading === false ? (
              <EmptyComponent
                message="Data Not Found"
                lottieFile={alertAnimation}
              />
            ) : (
              <div className="">
                {error === true && isLoading === false && (
                  <div
                    style={{ marginTop: "3.65em" }}
                    className="flex-grow-1 d-flex justify-content-center align-items-center"
                  >
                    <DashboardTableError onRetry={handleRetry} />
                  </div>
                )}

                {isLoading === false &&
                  data?.map((item) => (
                    <div className="d-flex flex-column notification-menu-item py-2  ">
                      <p className=" text-theme m-0 p-0">{item.message}</p>
                      <div className="text-muted m-0">{item?.createdOn}</div>
                    </div>
                  ))}
              </div>
            )}

            {showLoadMore && data.length >= 10 && isLoading === false && (
              <div className="d-flex justify-content-center my-3">
                <button
                  onClick={handleLoadMore}
                  className="btn text-white shadow px-3 py-1 orders-button fw-bold "
                  style={{ backgroundColor: Colors.secondaryColor }}
                >
                  {getContent(language, "LOAD_MORE_BUTTON_TEXT")}
                </button>
              </div>
            )}

            {showLoadMoreLoader === true && <LoadMoreLoader />}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationsDropdownMenu;
