import React, { useContext, useState } from "react";
import Logo from "../assets/logo.png";
import { FaMoneyCheckAlt } from "react-icons/all";
import { NavLink } from "react-router-dom";
import Colors from "../constants/colors";
import { truncate } from "../utils/Genutils";
import FullScreenLoader from "./FullScreenLoader";
import AddFundsModal from "./AddFundsModal";
import { TawkContext } from "../context/TawkContext";
import { AdvertiserRoutes, PublisherRoutes } from "./sidebarRoutes";
import { CURRENT_URL, EVENT_NAME, GAevent } from "../utils/Events";

const Sidebar = ({ preference, profileDetails }) => {
  const [showFullScreenLoader, setShowFullScreenLoader] = useState(null);
  const [fullScreenLoaderText, setFullScreenLoaderText] = useState("");
  const [isOpenAddFundsModal, setOpenAddFundsModal] = useState(null);
  const [helpOpen, setHelpOpen] = useState(false);

  const [isLoaded, setIsLoaded, showChatbot, setShowChatbot] =
    useContext(TawkContext);

  function openModal() {
    setOpenAddFundsModal(true);
  }
  function closeModal() {
    setOpenAddFundsModal(false);
  }

  const toggleTawk = (attribute, callback) => {
    // if (window.Tawk_API.isChatHidden()) {
    if (showChatbot === false) {
      window.Tawk_API.showWidget();
      window.Tawk_API.maximize();
      window.Tawk_API.setAttributes(
        {
          name: profileDetails.name,
          email: profileDetails.email,
        },
        (e) => console.log(e)
      );
      setHelpOpen(true);
      GAevent(
        profileDetails.preference === "ADVERTISER"
          ? EVENT_NAME.advertiser_need_help
          : EVENT_NAME.publisher_need_help,
        ["EXPAND"]
      );
      setShowChatbot(true);
    } else {
      window.Tawk_API.minimize();
      window.Tawk_API.hideWidget();
      setHelpOpen(false);
      GAevent(
        profileDetails.preference === "ADVERTISER"
          ? EVENT_NAME.advertiser_need_help
          : EVENT_NAME.publisher_need_help,
        ["COLLAPSE"]
      );
      setShowChatbot(false);
    }
  };

  return (
    <>
      {showFullScreenLoader && <FullScreenLoader text={fullScreenLoaderText} />}

      <AddFundsModal
        showFullScreenLoader={showFullScreenLoader}
        setShowFullScreenLoader={setShowFullScreenLoader}
        setFullScreenLoaderText={setFullScreenLoaderText}
        isOpen={isOpenAddFundsModal}
        closeModal={closeModal}
      />

      <div className="sidebar vh-100 pt-5  ">
        <div className="sidebar-container px-4 no-scrollbar ">
          <div className="mb-4">
            <img src={Logo} className="img-fluid w-100" alt="" />
            <div className="mt-3 text-theme d-flex align-items-baseline flex-wrap ">
              <p style={{ fontSize: "0.8rem" }} className="mb-0">
                Welcome
              </p>
              &nbsp;
              <h6 className="fw-bold">
                {profileDetails &&
                  profileDetails !== "" &&
                  truncate(profileDetails?.name.split(" ")[0], 10)}
              </h6>
            </div>
          </div>

          <div
            className=" p-3 my-3 mt-1 wallet-summary shadow"
            style={{ marginTop: -2 }}
          >
            {preference !== "ADVERTISER" && (
              <FaMoneyCheckAlt size={32} color={Colors.peachPink} />
            )}
            <p className="text-theme mb-2">Wallet Balance</p>
            <h2 className="text-theme">
              {profileDetails.amount >= 0
                ? `$${profileDetails.amount}`
                : `-$${Math.abs(profileDetails.amount)}`}
            </h2>
            <p className="text-theme mb-1">
              {preference === "ADVERTISER"
                ? "Total Amount Added"
                : "Total Earned"}
            </p>
            <h5 className="text-theme">
              {profileDetails.totalAmount >= 0
                ? `$${profileDetails.totalAmount}`
                : `-$${Math.abs(profileDetails.totalAmount)}`}
            </h5>
            {preference === "ADVERTISER" && (
              <button
                onClick={openModal}
                className=" w-100 shadow text-white fw-bold"
              >
                ADD FUNDS
              </button>
            )}
          </div>
          <div>
            {preference === "ADVERTISER"
              ? AdvertiserRoutes.map((item) => (
                  <NavLink
                    onClick={() => {
                      GAevent(item.analytics_key, [CURRENT_URL + item.to]);
                    }}
                    activeClassName="side-route-active"
                    to={item.to}
                    className="d-flex align-content-end text-uppercase side-route text-decoration-none "
                  >
                    {item.icon}
                    <p className=" my-auto">{item.name}</p>
                  </NavLink>
                ))
              : PublisherRoutes.map((item) => (
                  <NavLink
                    onClick={() => {
                      GAevent(item.analytics_key, [CURRENT_URL + item.to]);
                    }}
                    activeClassName="side-route-active"
                    to={item.to}
                    className="d-flex align-content-end text-uppercase side-route text-decoration-none "
                  >
                    {item.icon}
                    <p className=" my-auto">{item.name}</p>
                  </NavLink>
                ))}
          </div>
          {isLoaded && (
            <div className=" pt-2">
              <button
                className={`${
                  showChatbot
                    ? "bg-danger"
                    : profileDetails?.preference === "ADVERTISER"
                    ? "bg-theme-minor"
                    : "bg-peachPink"
                }  btn text-white`}
                onClick={toggleTawk}
              >
                {showChatbot ? "Close Help" : "Need Help?"}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
