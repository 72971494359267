import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import TawkToWrapper from "./components/TawkToWrapper";
import { TawkProvider } from "./context/TawkContext";
import PaymentStatusRedirect from "./pages/PaymentStatusRedirect";
import CreateDomainStatusRedirect from "./components/CreateDomainStatusRedirect";
import AuthWrapper from "./components/AuthWrapper";
import Homepage from "./pages/static/Homepage";
import ContactUs from "./pages/static/ContactUs";
import PublisherPage from "./pages/static/PublisherPage";
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import DashboardContainer from "./components/DashboardContainer";
import { fetchJWTToken } from "./utils/auth";
import RazorpayPaymentRedirect from "./pages/RazorpayPaymentRedirect";
import { clearReduxInitial } from "./redux/auth/ActionCreator";
import FullScreenLoader from "./components/FullScreenLoader";
import ErrorBoundary from "./components/ErrorBoundary";
import { toast } from "react-toastify";
import PaypalResponse from "./components/PaymentResponse/PaypalResponse";
import RazorpayResponse from "./components/PaymentResponse/RazorpayResponse";
import Bowser from "bowser";
import alertLottie from "./assets/Lottie/prod-error.png";
import ModalWrapper from "./components/ModalWrapper";
import Colors from "./constants/colors";
import { getContent } from "./localization";
import InviteFriend from "./pages/InviteFriend";
import TrackReferral from "./pages/TrackReferral";
import AddPaypalInvoicePage from "./pages/AddPaypalInvoicePage";
import EditPaypalInvoicePage from "./pages/EditPaypalInvoicePage";
import { GApageView, initGA } from "./utils/Events";
import ReactGA from "react-ga4";

const AnalyticsRedirect = React.lazy(() => import("./pages/AnalyticsRedirect"));
const MarketplaceCheckout = React.lazy(() =>
  import("./pages/MarketplaceCheckout")
);
const VerificationComp = React.lazy(() =>
  import("./pages/auth/VerificationComp")
);
const SolutionContentWriting = React.lazy(() =>
  import("./pages/static/SolutionContentWriting")
);
const OurStory = React.lazy(() => import("./pages/static/OurStory"));
const FAQPage = React.lazy(() => import("./pages/static/FAQPage"));
const Advertisers = React.lazy(() => import("./pages/static/Advertisers"));
const ErrorPage = React.lazy(() => import("./pages/ErrorPage"));
const Page404 = React.lazy(() => import("./pages/Page404"));
const WishlistPage = React.lazy(() => import("./pages/WishlistPage"));
const PrivacyPolicy = React.lazy(() => import("./pages/static/PrivacyPolicy"));
const DataDeletion = React.lazy(() => import("./pages/static/DataDeletion"));
const RefundPolicy = React.lazy(() => import("./pages/static/RefundPolicy"));
const UserProfile = React.lazy(() => import("./pages/UserProfile"));
const TermsAndConditions = React.lazy(() =>
  import("./pages/static/TermsAndConditions")
);
const ChangePassword = React.lazy(() => import("./pages/ChangePassword"));
const SetPreferencePage = React.lazy(() =>
  import("./pages/auth/SetPreferencePage")
);
const CheckMail = React.lazy(() => import("./pages/auth/CheckMail"));
const PasswordReset = React.lazy(() => import("./pages/auth/PasswordReset"));
const FinishSetupPage = React.lazy(() => import("./pages/auth/FinishSetup"));
const LoginPage = React.lazy(() => import("./pages/auth/LoginPage"));
const SignupPage = React.lazy(() => import("./pages/auth/SignupPage"));
const AddWebsitePage = React.lazy(() => import("./pages/AddWebsitePage"));
const MyWebsitePage = React.lazy(() => import("./pages/MyWebsitePage"));
const PaypalInvoicePage = React.lazy(() => import("./pages/PaypalInvoicePage"));
const OrderContentWritingPage = React.lazy(() =>
  import("./pages/OrderContentWritingPage")
);
const EditWebsitePage = React.lazy(() => import("./pages/EditWebsitePage"));
const Reports = React.lazy(() => import("./pages/Reports"));
const ValidateWebsitePage = React.lazy(() =>
  import("./pages/ValidateWebsitePage")
);
const BillingAndFunds = React.lazy(() => import("./pages/BillingAndFunds"));
const PaymentsPage = React.lazy(() => import("./pages/PaymentsPage"));
// const WishlistPage = React.lazy(() => import("./pages/WishlistPage"));
const CartPage = React.lazy(() => import("./pages/CartPage"));
const ContentWritingPage = React.lazy(() =>
  import("./pages/ContentWritingPage")
);
const MyOrdersPage = React.lazy(() => import("./pages/MyOrdersPage"));
const MarketplacePage = React.lazy(() => import("./pages/MarketplacePage"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));

require("dotenv").config();

const ProtectedRoute = ({
  auth,
  component: Component,
  userPreference,
  ...rest
}) => {
  const jwtToken = fetchJWTToken();

  return (
    <Route
      {...rest}
      render={() =>
        auth && jwtToken ? (
          <Redirect
            to={
              userPreference !== "na"
                ? `/${userPreference}/dashboard`
                : "/set-preference"
            }
          />
        ) : (
          <Component />
        )
      }
    />
  );
};

// const ProtectedDashboardRoute = ({
//   userData,
//   component: Component,
//   ...rest
// }) => {
//   return (
//     <Route
//       {...rest}
//       render={() =>
//         userData !== "" && userData.preference !== "NA" ? (
//           // Cookies.get("om-auth-token") !== undefined ? (
//           <Component />
//         ) : userData.preference !== "NA" ? (
//           <Redirect to="/login" />
//         ) : (
//           <Redirect to="/set-preference" />
//         )
//       }
//     />
//   );
// };

function App() {
  const language = useSelector((state) => state.language);

  const profileDetails = useSelector((state) => state.profileDetails);
  const userPreference = profileDetails?.preference?.toLowerCase();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isOnline, setIsOnline] = useState(null);

  const jwtToken = fetchJWTToken() || profileDetails.jwtToken;

  const [showSafariModal, setShowSafariModal] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "DEV") {
      GApageView(window.location.pathname + window.location.search);
    }
    const browser = Bowser.getParser(window.navigator.userAgent).getBrowser()
      .name;
    const version = parseInt(
      Bowser.getParser(window.navigator.userAgent)
        .getBrowser()
        .version.split(".")[0]
    );

    if (!jwtToken && browser.toLowerCase() === "safari" && version < 14) {
      setShowSafariModal(true);
    }
  }, []);

  useEffect(() => {
    if (isOnline === false) {
      // toast.error("No internet connection");
    }
  }, [isOnline]);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "DEV") {
      const userId = profileDetails?.userUniqueId
        ? profileDetails?.userUniqueId
        : "";
      initGA(userId);
    }
  }, [profileDetails]);

  useEffect(() => {
    // const firebaseConfig = {
    //   apiKey: "AIzaSyCabGKCNjN6BKJnJ4YkFIqp4zqpeudQQgI",
    //   authDomain: "outreachmantra-qa.firebaseapp.com",
    //   projectId: "outreachmantra-qa",
    //   storageBucket: "outreachmantra-qa.apps.com",
    //   messagingSenderId: "316752657647",
    //   appId:
    //     process.env.REACT_APP_ENVIRONMENT === "DEV"
    //       ? process.env.REACT_APP_FIREBASE_APP_ID_DEV
    //       : process.env.REACT_APP_FIREBASE_APP_ID_PROD,
    //   measurementId:
    //     process.env.REACT_APP_ENVIRONMENT === "DEV"
    //       ? process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_DEV
    //       : process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_PROD,
    // };

    // const app = initializeApp(base);
    // const analytics = getAnalytics(app);

    // console.log("Firebase Analytics: Triggered");

    dispatch(clearReduxInitial());
  }, []);

  return (
    <div>
      <ModalWrapper
        isOpen={showSafariModal}
        title={getContent(language, "SAFARI_VERSION_LOWER_TEXT")}
        icon={alertLottie}
        cta={[
          {
            title: "Accept and Continue",
            backgroundColor: Colors.lavender,
            onClick: () => setShowSafariModal(false),
          },
        ]}
      />
      <Router history={history}>
        <AuthWrapper>
          <TawkProvider>
            <TawkToWrapper>
              <ScrollToTop>
                <ErrorBoundary>
                  <Suspense fallback={<FullScreenLoader />}>
                    <Switch>
                      <Route path="/paypal-status" component={PaypalResponse} />
                      <Route
                        path="/razorpay/payment-link"
                        component={RazorpayResponse}
                      />
                      <Route
                        path="/undefined/dashboard"
                        render={() => <Redirect to="/" />}
                      />
                      <Route
                        path="/razorpay/payment-link"
                        component={RazorpayPaymentRedirect}
                      />
                      <Route exact path="/" component={Homepage} />
                      <Route
                        exact
                        path="/analytics-redirect"
                        component={AnalyticsRedirect}
                      />
                      <Route
                        exact
                        path="/payment-status"
                        component={PaymentStatusRedirect}
                      />
                      <Route
                        exact
                        path="/create-domain-status"
                        component={CreateDomainStatusRedirect}
                      />
                      <Route
                        exact
                        path="/verify-domain-status"
                        component={CreateDomainStatusRedirect}
                      />
                      <Route
                        exact
                        path="/advertiser/marketplace/checkout"
                        component={MarketplaceCheckout}
                      />
                      <Route exact path="/solutions/new">
                        <TermsAndConditions />
                      </Route>
                      <Route
                        exact
                        path="/our-story"
                        component={OurStory}
                        auth={profileDetails}
                        userPreference={userPreference}
                      />
                      <Route
                        exact
                        path="/faq"
                        component={FAQPage}
                        auth={profileDetails}
                        userPreference={userPreference}
                      />
                      <Route
                        exact
                        path="/contact-us"
                        component={ContactUs}
                        auth={profileDetails}
                        userPreference={userPreference}
                      />
                      <Route
                        exact
                        path="/publisher"
                        component={PublisherPage}
                        auth={profileDetails}
                        userPreference={userPreference}
                      />
                      <Route
                        exact
                        path="/content-writing-services"
                        component={SolutionContentWriting}
                        auth={profileDetails}
                        userPreference={userPreference}
                      />
                      <Route
                        exact
                        path="/guest-posts"
                        component={Advertisers}
                      />
                      {/* TODO: secondary static Pages */}
                      <Route
                        exact
                        path={`/data-deletion-instructions`}
                        component={DataDeletion}
                      />
                      <Route
                        exact
                        path="/privacy-policy"
                        component={PrivacyPolicy}
                      />
                      <Route
                        exact
                        path="/terms-and-conditions"
                        component={TermsAndConditions}
                      />
                      <Route
                        exact
                        path="/refund-policy"
                        component={RefundPolicy}
                      />
                      {/*TODO: Auth Pages*/}
                      <ProtectedRoute
                        exact
                        path="/finish-setup"
                        component={FinishSetupPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/check-mail"
                        component={CheckMail}
                      />
                      <ProtectedRoute
                        exact
                        path="/verification-step"
                        component={VerificationComp}
                      />
                      <Route
                        exact
                        path="/change-password"
                        component={ChangePassword}
                      />
                      <ProtectedRoute
                        exact
                        path="/user/referral"
                        component={InviteFriend}
                      />
                      <ProtectedRoute
                        exact
                        path="/user/referral/status"
                        component={TrackReferral}
                      />
                      <ProtectedRoute
                        exact
                        path="/forgot-password"
                        component={PasswordReset}
                      />
                      <ProtectedRoute
                        exact
                        path="/error-page"
                        component={ErrorPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/signup"
                        component={SignupPage}
                        auth={profileDetails}
                        userPreference={userPreference}
                      />
                      <ProtectedRoute
                        exact
                        path="/login"
                        component={LoginPage}
                        auth={profileDetails}
                        userPreference={userPreference}
                      />
                      <Route
                        exact
                        path="/set-preference"
                        component={SetPreferencePage}
                      />
                      <Route exact path={`/${userPreference}/dashboard`}>
                        <DashboardContainer component={<Dashboard />} />
                      </Route>
                      <Route exact path="/advertiser/marketplace">
                        <DashboardContainer component={<MarketplacePage />} />
                      </Route>

                      <Route exact path="/advertiser/paypal-invoice/create">
                        <DashboardContainer
                          component={<AddPaypalInvoicePage />}
                        />
                      </Route>

                      <Route exact path="/advertiser/paypal-invoice/edit">
                        <DashboardContainer
                          component={<EditPaypalInvoicePage />}
                        />
                      </Route>

                      <Route exact path="/advertiser/paypal-invoice">
                        <DashboardContainer component={<PaypalInvoicePage />} />
                      </Route>

                      <Route exact path={`/${userPreference}/my-orders`}>
                        <DashboardContainer component={<MyOrdersPage />} />
                      </Route>
                      <Route exact path={`/advertiser/content-writing`}>
                        <DashboardContainer
                          component={<ContentWritingPage />}
                        />
                      </Route>
                      <Route exact path={`/${userPreference}/cart`}>
                        <DashboardContainer component={<CartPage />} />
                      </Route>
                      <Route exact path="/publisher/my-website">
                        <DashboardContainer component={<MyWebsitePage />} />
                      </Route>
                      <Route exact path="/publisher/add-website">
                        <DashboardContainer component={<AddWebsitePage />} />
                      </Route>
                      <Route exact path={`/publisher/payments`}>
                        <DashboardContainer component={<PaymentsPage />} />
                      </Route>
                      <Route exact path={`/advertiser/billing-funds`}>
                        <DashboardContainer component={<BillingAndFunds />} />
                      </Route>
                      <Route exact path="/wishlist" component={WishlistPage} />
                      <Route exact path="/advertiser/order-content-writing">
                        <DashboardContainer
                          component={<OrderContentWritingPage />}
                        />
                      </Route>
                      <Route exact path="/publisher/my-website/edit">
                        <DashboardContainer component={<EditWebsitePage />} />
                      </Route>
                      <Route exact path="/publisher/my-website/validate">
                        <DashboardContainer
                          component={<ValidateWebsitePage />}
                        />
                      </Route>
                      <Route exact path={`/${userPreference}/reports`}>
                        <DashboardContainer component={<Reports />} />
                      </Route>

                      <Route exact path={`/user/profile`}>
                        <DashboardContainer component={<UserProfile />} />
                      </Route>

                      <Route path="*" component={Page404} />
                    </Switch>
                  </Suspense>
                </ErrorBoundary>
              </ScrollToTop>
            </TawkToWrapper>
          </TawkProvider>
        </AuthWrapper>
      </Router>
    </div>
  );
}
export default App;
