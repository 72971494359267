import React from "react";
import "../styles/pages/comingsoon.css";
import { Helmet } from "react-helmet";
import { getContent } from "../localization";
import { useDispatch, useSelector } from "react-redux";

const TrackReferral = () => {
  const language = useSelector((state) => state.language);

  return (
    <div
      className="flex-1 d-flex justify-content-center align-items-center"
      style={{ height: "100vh", width: "100vw" }}
    >
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <title>{getContent(language, "META_TITLE_TRACK_REFERRAL")}</title>
        <meta
          name="description"
          content={getContent(language, "META_DESCRIPTION_HOME")}
        />
        <link rel="canonical" href="/" />
        <meta
          property="og:title"
          content={getContent(language, "META_TITLE_HOME")}
        />
        <meta
          property="og:site_name"
          content={getContent(language, "META_TITLE_HOME")}
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:description"
          content={getContent(language, "META_DESCRIPTION_HOME")}
        />
        <meta property="og:type" content="website"></meta>
      </Helmet>
      <h3>
        <i>
          <b>Coming Soon....</b>
        </i>
      </h3>
    </div>
  );
};

export default TrackReferral;
