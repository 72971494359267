import React from "react";
import { SpinLoading } from "respinner";

const InScreenLoader = ({ style }) => {
  return (
    <div className="in-screen-loader-container" style={style}>
      <SpinLoading fill="#34006B" borderRadius={4} count={12} />
    </div>
  );
};

export default InScreenLoader;
