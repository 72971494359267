import React from "react";
import Logo from "../assets/Logo.webp";
import {
  FaPinterestP,
  FaSkype,
  FiFacebook,
  FiInstagram,
  FiLinkedin,
  FiMail,
  FiTwitter,
  FiYoutube,
} from "react-icons/all";
import Colors from "../constants/colors";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getContent } from "../localization/index";
import { CURRENT_URL, EVENT_NAME, GAevent } from "../utils/Events";

function Footer() {
  const homeConfig = useSelector((state) => state.dashboardConfig);
  const language = useSelector((state) => state.language);

  function goToNewTab(url) {
    window.open(url, "_blank");
  }

  return (
    <div className="p-3 container">
      <div className="row">
        <div className="col-lg-3 text-md-start text-center col-my-3">
          <Link
            to="/"
            onClick={() => GAevent(EVENT_NAME.footer_logo_icon, [CURRENT_URL])}
          >
            <img src={Logo} alt="logo" className="footer-logo mb-2 c-ptr" />
          </Link>

          <p className="text-theme footer-desc me-md-5">
            {getContent(language, "FOOTER_TEXT")}
          </p>
        </div>
        <div className="col-lg-6">
          <div className="row">
            <div className=" col text-md-start text-center my-3">
              <h5 className="footer-headings text-theme">QUICK LINKS</h5>
              <Link
                to="/"
                className="footer-links text-theme d-block "
                onClick={() =>
                  GAevent(EVENT_NAME.footer_home_text, [CURRENT_URL + "/"])
                }
              >
                HOME
              </Link>
              <Link
                to="/our-story"
                className="footer-links d-block text-theme "
                onClick={() =>
                  GAevent(EVENT_NAME.footer_our_story, [
                    CURRENT_URL + "/our-story",
                  ])
                }
              >
                OUR STORY
              </Link>
              <Link
                to={{ pathname: "https://blog.outreachmantra.com" }}
                target="_blank"
                className="footer-links d-block text-theme "
                onClick={() =>
                  GAevent(EVENT_NAME.footer_blog, [
                    "https://blog.outreachmantra.com",
                  ])
                }
              >
                BLOG
              </Link>
              <Link to="/faq" className="footer-links d-block text-theme ">
                FAQs
              </Link>
              <Link
                to="/contact-us"
                className="footer-links d-block text-theme"
                onClick={() =>
                  GAevent(EVENT_NAME.footer_contact_us, [
                    CURRENT_URL + "/contact-us",
                  ])
                }
              >
                CONTACT US
              </Link>
            </div>
            <div className="col text-md-start text-center my-3 ">
              <h5 className="footer-headings text-theme  ">SOLUTIONS</h5>
              <div className="">
                <Link
                  to="/guest-posts"
                  className="footer-links d-block text-theme"
                  onClick={() =>
                    GAevent(EVENT_NAME.footer_solutions_advertisers, [
                      CURRENT_URL + "/guest-posts",
                    ])
                  }
                >
                  ADVERTISERS
                </Link>
                <Link
                  to="/publisher"
                  className="footer-links d-block text-theme"
                  onClick={() =>
                    GAevent(EVENT_NAME.footer_solutions_publishers, [
                      CURRENT_URL + "/publisher",
                    ])
                  }
                >
                  PUBLISHERS
                </Link>
                <Link
                  to="/content-writing-services"
                  className="footer-links d-block text-theme"
                  onClick={() =>
                    GAevent(EVENT_NAME.footer_solutions_content_writing, [
                      CURRENT_URL + "/content-writing-services",
                    ])
                  }
                >
                  CONTENT WRITING
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 p-0">
          <div className=" text-md-start text-center d-flex flex-column align-items-center  align-items-lg-start  mt-3 mb-3">
            <h5 className="footer-headings text-theme  ">FOLLOW US</h5>
            <IconContext.Provider
              value={{
                color: Colors.primaryColor,
                className: "footer-icons",
                size: "1.9em",
              }}
            >
              <div className="" style={{ marginLeft: "-5px" }}>
                <a
                  href="https://www.facebook.com/outreachmantra/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    GAevent(EVENT_NAME.footer_facebook, [
                      "https://www.facebook.com/outreachmantra/",
                    ])
                  }
                >
                  <FiFacebook className="c-ptr" />
                </a>

                <a
                  href="https://www.linkedin.com/company/outreachmantra/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    GAevent(EVENT_NAME.footer_linkedin, [
                      "https://www.linkedin.com/company/outreachmantra/",
                    ])
                  }
                >
                  <FiLinkedin className="c-ptr" />
                </a>

                <a
                  href="https://twitter.com/OutreachMantra"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    GAevent(EVENT_NAME.footer_twitter, [
                      "https://twitter.com/OutreachMantra",
                    ])
                  }
                >
                  <FiTwitter className="c-ptr" />
                </a>

                <a
                  href="https://www.instagram.com/outreachmantra/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    GAevent(EVENT_NAME.footer_instagram, [
                      "https://www.instagram.com/outreachmantra/",
                    ])
                  }
                >
                  <FiInstagram className="c-ptr" />
                </a>

                <a
                  href="https://www.youtube.com/channel/UCTbFGqfOeAd3N3l3Ey8MDNw"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    GAevent(EVENT_NAME.footer_youtube, [
                      "https://www.youtube.com/channel/UCTbFGqfOeAd3N3l3Ey8MDNw",
                    ])
                  }
                >
                  <FiYoutube className="c-ptr" />
                </a>

                <a
                  href="https://in.pinterest.com/outreachmantra/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    GAevent(EVENT_NAME.footer_pinterest, [
                      "https://in.pinterest.com/outreachmantra/",
                    ])
                  }
                >
                  <FaPinterestP className="c-ptr" />
                </a>
              </div>
            </IconContext.Provider>
          </div>
          <div className="d-flex flex-column align-items-center align-items-lg-start">
            <h5 className="footer-headings text-theme text-md-start text-center ">
              CONNECT WITH US
            </h5>
            <a
              className="text-decoration-none"
              href={`mailto:${homeConfig?.supportEmail}`}
              target="_blank"
              rel="noreferrer"
            >
              <h6 className="text-theme text-md-start text-center my-md-3 my-2 text-decoration-none underline-on-hover">
                <FiMail className="me-2" size={20} />
                {homeConfig?.supportEmail}
              </h6>
            </a>
            <a
              href="skype:live:.cid.8618d16e3a52ef77?add"
              className="text-decoration-none"
            >
              <h6 className="text-theme text-md-start text-center my-md-3 text-decoration-none underline-on-hover">
                <FaSkype className="me-2" size={20} />
                live:.cid.8618d16e3a52ef77
              </h6>
            </a>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row flex-wrap justify-content-between  footer-bottom-section pt-3">
        <div className="d-flex flex-row">
          <Link
            to="/refund-policy"
            className=" footer-bottom-text text-theme text-decoration-none pe-2 text-center text-decoration-none underline"
            onClick={() =>
              GAevent(EVENT_NAME.footer_refund_policy, [
                CURRENT_URL + "/refund-policy",
              ])
            }
          >
            Refund Policy
          </Link>
          |
          <Link
            to="/terms-and-conditions"
            className=" footer-bottom-text text-theme text-decoration-none px-2 text-center text-decoration-none underline"
            onClick={() =>
              GAevent(EVENT_NAME.footer_terms_and_conditions, [
                CURRENT_URL + "/terms-and-conditions",
              ])
            }
          >
            Terms & Conditions
          </Link>
          |
          <Link
            to="/privacy-policy"
            className=" footer-bottom-text text-theme text-decoration-none px-2 text-center text-decoration-none underline"
            onClick={() =>
              GAevent(EVENT_NAME.footer_privacy_policy, [
                CURRENT_URL + "/privacy-policy",
              ])
            }
          >
            Privacy Policy
          </Link>
          |
          <Link
            to="/data-deletion-instructions"
            className=" footer-bottom-text text-theme text-decoration-none ps-2 text-center text-decoration-none underline"
            onClick={() =>
              GAevent(EVENT_NAME.footer_data_deletion_policy, [
                CURRENT_URL + "/data-deletion-instructions",
              ])
            }
          >
            Data Deletion Policy
          </Link>
        </div>
        <div className="text-theme footer-bottom-one ">
          <p className="border-0 text-center my-md-0 my-2 footer-bottom-text ">
            {getContent(language, "FOOTER_COPYRIGHT")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
