import React from "react";
import Carousel, { consts } from "react-elastic-carousel";
import { FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/all";
import { useSelector } from "react-redux";
import { EVENT_NAME, GAevent } from "../utils/Events";

export default function CarouselComp({page}) {
  const homeConfig = useSelector((state) => state.dashboardConfig);

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <FiArrowLeftCircle
          className="slider-arrow-icon"
          onClick={() => {
            GAevent(page, ["left_arrow"]);
          }}
        />
      ) : (
        <FiArrowRightCircle
          className="slider-arrow-icon"
          onClick={() => {
            GAevent(page, ["right_arrow"]);
          }}
        />
      );
    return <div onClick={onClick}>{pointer}</div>;
  };

  // const getItemsToShow = () => {
  //   switch (window.innerWidth) {
  //     case window.innerWidth > 450:
  //       return 4;
  //     case window.innerWidth > 750:
  //       return 5;
  //     default:
  //       return 2;
  //   }
  // };

  return (
    <div style={{ width: "100%" }}>
      <Carousel
        breakPoints={[
          { width: 400, itemsToShow: 2 },
          { width: 700, itemsToShow: 4 },
          { width: 1000, itemsToShow: 5 },
        ]}
        pagination={false}
        renderArrow={myArrow}
        enableSwipe={true}
      >
        {homeConfig?.brand &&
          homeConfig?.brand.map((item) => (
            <div>
              <img className="carouselImg" src={item.logo} alt="brandLogo" />
            </div>
          ))}
      </Carousel>
    </div>
  );
}
