import React from "react";
import { FiChevronDown } from "react-icons/all";

const CustomSelect = ({
  selectContainerStyle,
  label,
  value,
  onChange,
  id,
  optionList,
  all,
  disabled,
  containerStyle,
  style,
  disabledSelect,
  placeholder,
  ...props
}) => {
  return (
    <div
      className={`d-flex flex-column me-2 ms-md-0 ms-0 mx-md-3 ${selectContainerStyle}  custom-select-container`}
      style={containerStyle}
    >
      {label}
      <div className="position-relative">
        <select
          disabled={disabled}
          style={style}
          id={id}
          className="form-select position-relative custom-select-style"
          value={value}
          onChange={onChange}
          aria-label="Default select example"
          {...props}
        >
          {disabledSelect}
          {all && (
            <option className="bg-green" value="" selected>
              All
            </option>
          )}
          {optionList?.map((el) => (
            <option value={el}>{el.replace(/_/g, " ")}</option>
          ))}
        </select>
        {placeholder && (
          <span className="position-absolute" style={{ top: 10, left: 10 }}>
            {placeholder}
          </span>
        )}
        <span className="d-flex justify-content-center align-items-center position-absolute inputs-span ">
          <FiChevronDown color={"#BE98E8"} size={"1.6em"} />
        </span>
      </div>
    </div>
  );
};

export default CustomSelect;
