import React, { Suspense, useState } from "react";

import Sidebar from "./Sidebar";
import UserNav from "./UserNav";
import DashboardFooter from "./DashboardComponents/DashboardFooter";
import { useSelector } from "react-redux";
import InScreenLoader from "./InScreenLoader";

const DashboardContainer = (props) => {
  const profileDetails = useSelector((state) => state.profileDetails);
  const dashboardBadges = useSelector((state) => state.dashboardBadges);
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  return (
    <div
      className={`d-flex position-relative dashboard-bg `}
      style={{
        overflowX: "visible",
      }}
    >
      <Sidebar
        preference={profileDetails.preference}
        profileDetails={profileDetails}
      />
      {/*{isVisible ? (*/}
      {/*  <div*/}
      {/*    className=" position-fixed back-to-top-button p-2 fw-semiBold text-theme bg-white rounded shadow c-ptr"*/}
      {/*    onClick={() => window.scrollTo(0, 0)}*/}
      {/*  >*/}
      {/*    Back To Top <FiArrowUp />*/}
      {/*  </div>*/}
      {/*) : (*/}
      {/*  ""*/}
      {/*)}*/}

      <div
        className=" flex-grow-1 px-2 px-lg-5 dashboard-main justify-content-between d-flex flex-column "
        style={{ minWidth: 0 }}
      >
        <div
          className=" d-flex flex-column position-relative "
          style={{ minHeight: "calc(100vh - 70px)" }}
        >
          <UserNav
            pageTitle="MY ORDERS"
            advertiserRoute="/advertiser/my-orders"
            publisherRoute="/publisher/my-orders"
            preference={profileDetails.preference}
            badges={dashboardBadges}
            profileDetails={profileDetails}
          />
          <Suspense fallback={<InScreenLoader />}>{props.component}</Suspense>
        </div>
        <DashboardFooter />
      </div>
    </div>
  );
};

export default DashboardContainer;
