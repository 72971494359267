import React, { useContext, useEffect } from "react";
import SignupBanner from "../../components/SignupBanner";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import HomeComponent from "../../components/HomeComponent";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchJWTToken } from "../../utils/auth";
import { TawkContext } from "../../context/TawkContext";

function Homepage() {
  const [isLoaded, setIsLoaded, showChatbot, setShowChatbot] =
    useContext(TawkContext);
  const profileDetails = useSelector((state) => state.profileDetails);
  const userPreference = profileDetails?.preference?.toLowerCase();
  const history = useHistory();
  const jwtToken = fetchJWTToken();
  useEffect(() => {
    console.log(isLoaded, "isLoaded");
  }, [isLoaded]);
  useEffect(() => {
    jwtToken && userPreference && history.push(`/${userPreference}/dashboard`);
    isLoaded && setTimeout(() => window.Tawk_API.showWidget(), 1000);
    return () => {
      isLoaded && window.Tawk_API.hideWidget();
    };
  }, [isLoaded]);

  return (
    <>
      <NavBar />
      <div className="container">
        <HomeComponent />
        <SignupBanner />
      </div>
      <Footer />
    </>
  );
}

export default Homepage;
