import PublisherDashboard from "../assets/publisherDashboard.webp";
import { PublisherCardData, PublisherProcessData } from "../constants/content";
import HowItWorksCard from "./HowItWorksCard";
import React from "react";
import Card from "../components/Card";
import Typography from "./Typography";
import Publisher1 from "../assets/solutions_illustrations/publishers/publisher1.png";
import Publisher2 from "../assets/solutions_illustrations/publishers/publisher2.png";
import Publisher3 from "../assets/solutions_illustrations/publishers/publisher3.png";
import {
  FaPaypal,
  FaUniversity,
  FiCornerDownRight,
  FiCornerUpRight,
} from "react-icons/all";
import PublisherProcessComponent from "./PublisherProcessComponent";
import Colors from "../constants/colors";
import ProgressiveImage from "./ProgressiveImage";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { getContent } from "../localization/index";
import { EVENT_NAME } from "../utils/Events";

export default function PublisherComp() {
  const homeConfig = useSelector((state) => state.dashboardConfig);
  const language = useSelector((state) => state.language);

  return (
    <>
      <Helmet>
        <title>{getContent(language, "META_TITLE_PUBLISHERS")}</title>
        <meta
          name="description"
          content={getContent(language, "META_DESCRIPTION_PUBLISHERS")}
        />
        <link rel='canonical' href='/publisher' />

        <meta
          property="og:title"
          content={getContent(language, "META_TITLE_PUBLISHERS")}
        />
        <meta
          property="og:site_name"
          content={getContent(language, "META_TITLE_HOME")}
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:description"
          content={getContent(language, "META_DESCRIPTION_PUBLISHERS")}
        />
        <meta property="og:type" content="website"></meta>
       

      </Helmet>

      <div className=" mt-5 d-flex flex-column align-items-center ">
        <div className="publisher-circle-translucent" />
        <Typography
          variant="header"
          className="text-center mx-md-5"
          text={getContent(language, "SOLUTIONS_PUBLISHER_HERO_HEADER")}
        />
        <Typography
          variant="description"
          className="text-center mx-md-5 w-75 mb-3 "
        >
          {getContent(language, "SOLUTIONS_PUBLISHER_HERO_DESCRIPTION")}
        </Typography>
        <ProgressiveImage
          className="mb-5 w-100 px-lg-5 px-sm-2 px-0"
          placeholder={PublisherDashboard}
          alt="Publisher dashboard"
          src={getContent(language, "IMAGE_staticPublisherDashboard")}
        />
        <HowItWorksCard url={homeConfig?.tourDto?.publisher?.split("?v=")[1]}  page ={EVENT_NAME.solutions_publishers_page}/>
        <div className="position-relative text-center mt-5  ">
          <div className="publisher-circle-translucent-2" />
          {/*<div className="publisher-circle-1 hide-on-responsive " />*/}
          <div className="publisher-circle-2 shadow hide-on-responsive " />
          <h5 className=" fst-italic text-theme-minor mb-3 publisher-process-h5">
            {getContent(language, "SOLUTIONS_PUBLISHER_EASY_TO_WORK_HEADER")}
          </h5>
          <Typography variant="header" className="fst-italic mx-md-5">
            {getContent(language, "SOLUTIONS_PUBLISHER_EASY_TO_WORK_SUBHEADER")}
          </Typography>
          <Typography variant="description">
            {getContent(language, "SOLUTIONS_PUBLISHER_HERO_DESCRIPTION")}
          </Typography>
          <div className="d-flex flex-row flex-wrap w-100 mt-5 pt-3">
            <div className="d-flex justify-content-around flex-grow-1 ">
              <PublisherProcessComponent
                step={{
                  arrow: PublisherProcessData[0].arrow,
                  circleColor: PublisherProcessData[0].circleColor,
                  header: getContent(
                    language,
                    "SOLUTIONS_PUBLISHER_EASY_TO_WORK_NUMERIC_POINTERS"
                  )[0].header,
                  helperText: getContent(
                    language,
                    "SOLUTIONS_PUBLISHER_EASY_TO_WORK_NUMERIC_POINTERS"
                  )[0].helperText,
                  helperText2: getContent(
                    language,
                    "SOLUTIONS_PUBLISHER_EASY_TO_WORK_NUMERIC_POINTERS"
                  )[0].helperText2,
                }}
              />
              <div className="position-relative d-none d-md-block">
                <FiCornerDownRight className="process-arrow" />
              </div>
              <PublisherProcessComponent
                step={{
                  arrow: PublisherProcessData[1].arrow,
                  circleColor: PublisherProcessData[1].circleColor,
                  header: getContent(
                    language,
                    "SOLUTIONS_PUBLISHER_EASY_TO_WORK_NUMERIC_POINTERS"
                  )[1].header,
                  helperText: getContent(
                    language,
                    "SOLUTIONS_PUBLISHER_EASY_TO_WORK_NUMERIC_POINTERS"
                  )[1].helperText,
                  helperText2: getContent(
                    language,
                    "SOLUTIONS_PUBLISHER_EASY_TO_WORK_NUMERIC_POINTERS"
                  )[1].helperText2,
                }}
              />
            </div>
            <div className="d-flex justify-content-around flex-grow-1 ">
              <div className="position-relative d-none d-md-block">
                <FiCornerUpRight className="process-arrow" />
              </div>
              <PublisherProcessComponent
                step={{
                  arrow: PublisherProcessData[2].arrow,
                  circleColor: PublisherProcessData[2].circleColor,
                  header: getContent(
                    language,
                    "SOLUTIONS_PUBLISHER_EASY_TO_WORK_NUMERIC_POINTERS"
                  )[2].header,
                  helperText: getContent(
                    language,
                    "SOLUTIONS_PUBLISHER_EASY_TO_WORK_NUMERIC_POINTERS"
                  )[2].helperText,
                  helperText2: getContent(
                    language,
                    "SOLUTIONS_PUBLISHER_EASY_TO_WORK_NUMERIC_POINTERS"
                  )[2].helperText2,
                }}
              />
              <div className="position-relative d-none d-md-block">
                <FiCornerDownRight className="process-arrow" />
              </div>
              <PublisherProcessComponent
                step={{
                  arrow: PublisherProcessData[3].arrow,
                  circleColor: PublisherProcessData[3].circleColor,
                  header: getContent(
                    language,
                    "SOLUTIONS_PUBLISHER_EASY_TO_WORK_NUMERIC_POINTERS"
                  )[3].header,
                  helperText: getContent(
                    language,
                    "SOLUTIONS_PUBLISHER_EASY_TO_WORK_NUMERIC_POINTERS"
                  )[3].helperText,
                  helperText2: getContent(
                    language,
                    "SOLUTIONS_PUBLISHER_EASY_TO_WORK_NUMERIC_POINTERS"
                  )[3].helperText2,
                }}
              />
            </div>
          </div>
        </div>
        <div className=" text-center display-flex flex-column align-items-center position-relative mt-5 pt-3">
          <div className="publisher-circle-translucent-3" />
          <Typography variant="header" className="fst-italic mb-4">
            {getContent(language, "SOLUTIONS_PUBLISHER_PERKS_HEADER")}
          </Typography>
          <div className="row mb-5">
            {PublisherCardData.map((card, idx) => (
              <div className={"col-lg-3 col-md-6"}>
                <Card
                  card={{
                    icon: card.icon,
                    title: getContent(
                      language,
                      "SOLUTIONS_PUBLISHER_PERKS_CARDS"
                    )[idx].title,
                    description: getContent(
                      language,
                      "SOLUTIONS_PUBLISHER_PERKS_CARDS"
                    )[idx].description,
                    circleColor: card.circleColor,
                  }}
                  className="publisher-card"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="row my-5 ">
          <div className="col-lg-6 showCaseText position-relative pe-md-5">
            <Typography
              variant="header"
              className="text-center text-md-start fst-italic"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: getContent(
                    language,
                    "SOLUTIONS_PUBLISHER_WHY_POINTERS_SECTIONS"
                  )[0].title,
                }}
              ></div>
            </Typography>
            <Typography
              variant="description"
              className="text-md-start text-center"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: getContent(
                    language,
                    "SOLUTIONS_PUBLISHER_WHY_POINTERS_SECTIONS"
                  )[0].description,
                }}
              ></div>
            </Typography>
          </div>
          <div className="col-lg-6 position-relative ">
            <div className="publisher-circle-translucent-4" />
            <img
              className="img-fluid dsbdImg2"
              src={Publisher1}
              alt="Dashboard vector"
            />
          </div>
        </div>
        <div className="row mb-5 ">
          <div className="col-lg-6 order-1  mb-5 pe-md-5 showCaseText position-relative ">
            <div className="publisher-circle-translucent-5" />
            <Typography
              variant="header"
              className="text-center text-md-start fst-italic"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: getContent(
                    language,
                    "SOLUTIONS_PUBLISHER_WHY_POINTERS_SECTIONS"
                  )[1].title,
                }}
              ></div>
            </Typography>
            <Typography
              variant="description"
              className="text-md-start text-center my-4"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: getContent(
                    language,
                    "SOLUTIONS_PUBLISHER_WHY_POINTERS_SECTIONS"
                  )[1].description,
                }}
              ></div>
            </Typography>
            <div className="mt-4 d-flex flex-row">
              <h3 className="fw-bold fst-italic text-theme">
                <FaPaypal
                  style={{ marginBottom: 5, color: Colors.peachPink }}
                />
                {getContent(language, "SOLUTIONS_PUBLISHER_PAYPAL")}
              </h3>
              &nbsp;
              <h3 className="fw-bold fst-italic text-theme ms-3 ">
                <FaUniversity
                  style={{ marginBottom: 5, color: Colors.peachPink }}
                />{" "}
                {getContent(language, "SOLUTIONS_PUBLISHER_BANK_TRANSFER")}
              </h3>
            </div>
          </div>
          <div className="col-lg-6 order-2  order-last order-lg-first dashboardImage">
            <img
              className="img-fluid dsbdImg3"
              src={Publisher2}
              alt="Dashboard vector"
            />
          </div>
        </div>
        <div className="row my-5">
          <div className="col-lg-6 pb-5 position-relative pe-md-5 ">
            <div className="publisher-circle-3 hide-on-responsive" />
            <Typography
              variant="header"
              className="text-center text-md-start fst-italic"
            >
              {
                getContent(
                  language,
                  "SOLUTIONS_PUBLISHER_WHY_POINTERS_SECTIONS"
                )[2].title
              }
            </Typography>
            <Typography
              variant="description"
              className="text-md-start text-center"
            >
              {
                getContent(
                  language,
                  "SOLUTIONS_PUBLISHER_WHY_POINTERS_SECTIONS"
                )[2].description
              }
            </Typography>
            <div className="publisher-circle-translucent-7" />
          </div>
          <div className="col-lg-6 position-relative">
            <div className="publisher-circle-4 hide-on-responsive " />
            <img
              className="img-fluid dsbdImg1"
              src={Publisher3}
              alt="Dashboard vector"
            />
          </div>
        </div>
      </div>
    </>
  );
}
