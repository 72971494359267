import axios from "axios";
import { BASE_URL } from "../constants/Endpoints";

export function postContactUsQuery({ email, message, name, type }) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/om/contact`,
    data: { email, message, name, type },
  });
}
