import React from "react";
import {
  FaArrowCircleLeft,
  FaArrowCircleRight,
  FiArrowLeftCircle,
  FiArrowRightCircle,
} from "react-icons/all";
import Colors from "../../constants/colors";

const Arrow = ({ direction, clickFunction, glyph }) => (
  <div style={{ fontSize: "32px" }} onClick={clickFunction}>
    {direction === "left" ? (
      <FiArrowLeftCircle color={Colors.secondaryColor} />
    ) : (
      <FiArrowRightCircle color={Colors.secondaryColor} />
    )}
  </div>
);

export default Arrow;
