import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { setPaymentStatusData } from "../redux/auth/ActionCreator";

const PaymentStatusRedirect = () => {
  const dispatch = useDispatch();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  console.log(query);
  const paypalOrderId = query.get("token");
  const payerId = query.get("PayerID");

  localStorage.setItem("paypalOrderId", paypalOrderId);
  localStorage.setItem("payerId", payerId);

  useEffect(() => {
    dispatch(setPaymentStatusData({ paypalOrderId, payerId }));

    localStorage.setItem("addFundsClose", "true");
    const path =  localStorage.getItem("REDIRECT_PATH");
    window.location.href=path;
  }, [paypalOrderId, payerId]);

  return <></>;
};

export default PaymentStatusRedirect;
