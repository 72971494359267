export const SET_USER_PROFILE_DATA = "SET_USER_PROFILE_DATA";

export const UPDATE_USER_PREFERENCE = "UPDATE_USER_PREFERENCE";

export const SET_DASHBOARD_BADGES = "SET_DASHBOARD_BADGES";

export const SET_HOME_CONFIG = "SET_HOME_CONFIG";

export const SET_DASHBOARD_CONFIG = "SET_DASHBOARD_CONFIG";

export const SET_GA_AUTH_CODE = "SET_GA_AUTH_CODE";

export const SET_EDIT_WEBSITE_DATA = "SET_EDIT_WEBSITE_DATA";

export const UPDATE_CART_DELETE_PREFERENCE = "UPDATE_CART_DELETE_PREFERENCE";

export const UPDATE_WISHLIST_DELETE_PREFERENCE =
  "UPDATE_WISHLIST_DELETE_PREFERENCE";

export const SET_ADVERTISER_NOTIFICATIONS_COUNT =
  "SET_ADVERTISER_NOTIFICATIONS_COUNT";

export const SET_PUBLISHER_NOTIFICATIONS_COUNT =
  "SET_PUBLISHER_NOTIFICATIONS_COUNT";

export const SET_PAYMENT_STATUS_DATA = "SET_PAYMENT_STATUS_DATA";

export const SET_PAYMENT_STATUS_ORDER_ID = "SET_PAYMENT_STATUS_ORDER_ID";

export const SET_MY_WEBSITE_CONSENT_STATUS_CODE =
  "SET_MY_WEBSITE_CONSENT_STATUS_CODE";

export const SET_MARKETPLACE_CHECKOUT_DATA = "SET_MARKETPLACE_CHECKOUT_DATA";

export const SET_LANGUAGE = "SET_LANGUAGE";

export const SET_RAZORPAY_PAYMENT_ID = "SET_RAZORPAY_PAYMENT_ID";

export const CLEAR_REDUX_INITIAL = "CLEAR_REDUX_INITIAL";

export const SET_RELOAD_BILLING_AND_FUNDS = "SET_RELOAD_BILLING_AND_FUNDS";
