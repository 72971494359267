import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { setMyWebsiteConsentStatusCode } from "../redux/auth/ActionCreator";

const CreateDomainStatusRedirect = () => {
  const dispatch = useDispatch();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  const createDomainStatusCode = query.get("code");

  useEffect(() => {
    dispatch(setMyWebsiteConsentStatusCode(createDomainStatusCode));
    localStorage.setItem("createDomainStatusCode", createDomainStatusCode);
    localStorage.setItem("consentClose", "true");
     window.close();
  }, [createDomainStatusCode]);

  return <></>;
};

export default CreateDomainStatusRedirect;
