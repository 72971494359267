import React from "react";

const ThemeButton = ({
  className,
  type,
  style,
  children,
  onClick,
  ...otherProps
}) => {
  return (
    <button
      onClick={onClick}
      style={style}
      className={`${className} ${
        type === "secondary" ? "bg-theme-minor" : "bg-theme"
      }  btn text-white`}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default ThemeButton;
