import axios from "axios";
import { fetchJWTToken } from "../utils/auth";
import { BASE_URL } from "../constants/Endpoints";

export async function getExchangeRate() {
  return axios.get(`${BASE_URL}/om/exchange-rate`, {
    headers: {
      Authorization: fetchJWTToken(),
    },
  });
}
