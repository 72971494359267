import axios from "axios";
import { BASE_URL } from "../constants/Endpoints";
import { checkOS } from "./Helpers";
import { fetchJWTToken } from "../utils/auth";

export async function switchUserPreference(preference) {
  return axios.put(`${BASE_URL}/om/user?userType=${preference}`, null, {
    headers: {
      Authorization: fetchJWTToken(),
    },
  });
}

export async function getProfileDetails(jwtToken) {
  return axios.get(`${BASE_URL}/om/user`, {
    headers: {
      Authorization: jwtToken,
    },
  });
}

export async function getUserProfileDetails(jwtToken) {
  return axios.get(`${BASE_URL}/om/user-profile`, {
    headers: {
      Authorization: jwtToken,
    },
  });
}



export const sendInvitation = async (jwtToken, email) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}/om/invite/send`,
    data: {email},
    headers: {
      Authorization: jwtToken,
    },
  });
};


export async function registerEmail(data) {
  return axios.post(`${BASE_URL}/om/auth/register-email`, data);
}

export const verifyEmail = async data => {
  return axios.post(`${BASE_URL}/om/auth/sign-in/verify-email`, data, {
    headers: {
      //hardcoded for now
      //these params will be dynamic
      deviceId: "DEVICE_ID_123456789",
      platform: "WEB", //checkOS(),
    },
  });
};

export const loginWithEmail = async data => {
  return axios.post(`${BASE_URL}/om/auth/sign-in/email-login`, data, {
    headers: {
      //hardcoded for now
      //these headers will be dynamic
      deviceId: "DEVICE_ID_132465",
      platform: checkOS(),
    },
  });
};

export const forgotPassword = async email => {
  return axios({
    method: "POST",
    url: `${BASE_URL}/om/auth/forgot-password`,
    data: { email },
  });
};

export const changePassword = async email => {
  return axios({
    method: "POST",
    url: `${BASE_URL}/om/auth/change-password`,
    headers: {
      Authorization: fetchJWTToken(),
    },
    data: { email },
  });
};

export const VerifyGoogle = async token => {
  return axios({
    method: "POST",
    url: `${BASE_URL}/om/auth/sign-in/google`,
    data: token,
    headers: {
      //hardcoded for now
      //these headers will be dynamic
      deviceId: "DEVICE_ID_132465",
      platform: checkOS(),
    },
  });
};

export const ChangeSetPreference = async (jwtToken, userType) => {
  return axios({
    method: "PUT",
    url: `${BASE_URL}/om/user?userType=${userType}`,
    data: {},
    headers: {
      Authorization: jwtToken,
    },
  });
};

export const VerifyFacebook = async data => {
  return axios({
    method: "POST",
    url: `${BASE_URL}/om/auth/sign-in/facebook`,
    data: data,
    headers: {
      //hardcoded for now
      //these headers will be dynamic
      deviceId: "DEVICE_ID_132465",
      platform: checkOS(),
    },
  });
};

export const logoutUser = async jwtToken => {
  return axios.post(
    `${BASE_URL}/om/user/logout`,
    {},
    {
      headers: {
        Authorization: jwtToken,
      },
    }
  );
};
