import { EN } from "./languages/EN";

export const getContent = (language, key) => {
  switch (language) {
    case "EN":
      return EN[key];
    default:
      return;
  }
};
