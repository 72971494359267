const Colors = {
  black: "#000",
  white: "#fff",
  primaryColor: "#34006B",
  secondaryColor: "#9847ee",
  hotPink: "#FE5BAC",
  peachPink: "#DA627D",
  lightGreen: "#86C28B",
  lavender: "#9847EE",
  lightBlue: "#567EBB",
  yellow: "#FACA66",
  darkYellow: "#e2af30",
  orange: "#D15C57",
  gray: "#808080",
  navyBlue: "#141759",
  lightPink: "#DA627D33",
  lightPeach: "#DA627D99",
  success: "#198754",
  danger: "#dc3545",
};
export default Colors;
