import React, { useEffect, useState } from "react";

const ProgressiveImage = (props) => {
  const [state, setState] = useState({
    loading: true,
    currentSrc: props.placeholder,
  });
  useEffect(() => {
    const { src } = props;
    // start loading original image
    const imageToLoad = new Image();
    imageToLoad.src = src;
    imageToLoad.onload = () => setState({ currentSrc: src, loading: false });
  }, []);
  const { currentSrc, loading } = state;
  const { alt } = props;
  return (
    <img
      src={currentSrc}
      className={`ProgressiveImage ${props.className}`}
      style={{
        // opacity: loading ? 0.5 : 1,
        opacity: 1,
        transition: "opacity .15s linear",
        aspectRatio: props.aspectRatio
      }}
      alt={alt}
    />
  );
};

export default ProgressiveImage;
