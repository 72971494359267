import {
  FiBarChart2,
  FiBookmark,
  FiDollarSign,
  FiHome,
  FiMonitor,
  FiPackage,
  FiPenTool,
  TbFileInvoice,
  AiOutlineShareAlt,
} from "react-icons/all";
import React from "react";
import { EVENT_NAME } from "../utils/Events";

export const AdvertiserRoutes = [
  {
    name: "dashboard",
    to: "/advertiser/dashboard",
    icon: <FiHome className="side-route-icon" />,
    analytics_key:EVENT_NAME.advertiser_menu_dashboard,
  },
  {
    name: "marketplace",
    to: "/advertiser/marketplace",
    icon: <FiMonitor className="side-route-icon" />,
    analytics_key:EVENT_NAME.advertiser_menu_marketplace,

  },
  {
    name: "my orders",
    to: "/advertiser/my-orders",
    icon: <FiPackage className="side-route-icon" />,
    analytics_key:EVENT_NAME.advertiser_menu_myorders,

  },
  {
    name: "content writing",
    to: "/advertiser/content-writing",
    icon: <FiPenTool className="side-route-icon" />,
    analytics_key:EVENT_NAME.advertiser_menu_content_writing,

  },
  {
    name: "paypal invoices",
    to: "/advertiser/paypal-invoice",
    icon: <TbFileInvoice className="side-route-icon" />,
    analytics_key:EVENT_NAME.advertiser_menu_paypal_invoices,

  },
  {
    name: "billing & funds",
    to: "/advertiser/billing-funds",
    icon: <FiBookmark className="side-route-icon" />,
    analytics_key:EVENT_NAME.advertiser_menu_billing_funds,

  },
  {
    name: "reports",
    to: "/advertiser/reports",
    icon: <FiBarChart2 className="side-route-icon" />,
    analytics_key:EVENT_NAME.advertiser_menu_reports,

  },
  {
    name: "Refer & Earn",
    to: "/user/referral",
    icon: <AiOutlineShareAlt className="side-route-icon" />,
    analytics_key:EVENT_NAME.advertiser_refer_earn,

  },
];

export const PublisherRoutes = [
  {
    name: "dashboard",
    to: "/publisher/dashboard",
    icon: <FiHome className="side-route-icon" />,
    analytics_key:EVENT_NAME.publisher_menu_dashboard,

  },
  {
    name: "my websites",
    to: "/publisher/my-website",
    icon: <FiMonitor className="side-route-icon" />,
    analytics_key:EVENT_NAME.publisher_menu_mywebsites,

  },
  {
    name: "my orders",
    to: "/publisher/my-orders",
    icon: <FiPackage className="side-route-icon" />,
    analytics_key:EVENT_NAME.publisher_menu_myorders,

  },
  {
    name: "payments",
    to: "/publisher/payments",
    icon: <FiDollarSign className="side-route-icon" />,
    analytics_key:EVENT_NAME.publisher_menu_payments,

  },
  {
    name: "reports",
    to: "/publisher/reports",
    icon: <FiBarChart2 className="side-route-icon" />,
    analytics_key:EVENT_NAME.publisher_menu_reports,

  },
  {
    name: "Refer & Earn",
    to: "/user/referral",
    icon: <AiOutlineShareAlt className="side-route-icon" />,
    analytics_key:EVENT_NAME.publisher_refer_earn,

  },
];
