import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import InScreenLoader from "../components/InScreenLoader";
import { getUserProfileDetails, sendInvitation } from "../services/UserService";
import { fetchJWTToken } from "../utils/auth";
import { toast } from "react-toastify";
import { getContent } from "../localization/index";

import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import FullScreenLoader from "../components/FullScreenLoader";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";
import { DiJavascript } from "react-icons/di";
import { async } from "@firebase/util";
import { GAevent } from "../utils/Events";
import { EVENT_NAME } from "./../utils/Events";

const InviteFriend = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [email, setEmail] = useState(null);
  const language = useSelector((state) => state.language);
  const profileDetails = useSelector((state) => state.profileDetails);

  const jwtToken = fetchJWTToken();

  const copy = async () => {
    await navigator.clipboard.writeText(
      inviteShareText + data?.inviteDto?.inviteUrl
    );
    toast.success(getContent(language, "INVITATION_LINK_COPY_MESSAGE"));
    GAevent(EVENT_NAME.invite_friends_code, [
      "COPY CODE",
      data?.inviteDto?.inviteCode,
    ]);
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    const response = await getUserProfileDetails(jwtToken);
    if (response.status === 200) {
      setData(response.data);
    }
  };

  const sendInvite = async () => {
    setIsLoading(true);
    const response = await sendInvitation(jwtToken, email);
    if (response.status === 200) {
      console.log(response.data);
      toast.success(getContent(language, "INVITATION_SENT_MESSAGE"));
      setEmail("");
      setIsLoading(false);
    }
    GAevent(EVENT_NAME.invite_friends_email, ["SEND INVITATION", email]);
  };

  const copyMarketingTool = async (text) => {
    await navigator.clipboard.writeText(text);
    toast.success(getContent(language, "INVITATION_BANNER_COPY_MESSAGE"));
  };

  const inviteShareText =
    "OutreachMantra - Guest Post Marketplace, " +
    data?.inviteDto?.inviteTitle +
    ". Here is the invite link - ";

  return (
    <div className="container-fluid">
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <title>{getContent(language, "META_TITLE_INVITE_FRIEND")}</title>
        <meta
          name="description"
          content={getContent(language, "META_DESCRIPTION_HOME")}
        />
        <link rel="canonical" href="/" />
        <meta
          property="og:title"
          content={getContent(language, "META_TITLE_HOME")}
        />
        <meta
          property="og:site_name"
          content={getContent(language, "META_TITLE_HOME")}
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:description"
          content={getContent(language, "META_DESCRIPTION_HOME")}
        />
        <meta property="og:type" content="website"></meta>
      </Helmet>
      {data && (
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="d-flex flex-column pt-5 align-items-center">
              <div style={{ height:200, width:200}}>
                <img
                  src="/assets/invite-image.svg"
                  style={{ maxHeight: 200 }}
                  className="img-fluid"
                />
              </div>

              <h5
                style={{
                  marginTop: 24,
                  fontSize: 24,
                  fontWeight: 700,
                  color: "#34006B",
                  textAlign: "center",
                }}
              >
                {data?.inviteDto?.inviteTitle}
              </h5>
              <div
                className="text-center"
                style={{ fontSize: 18, color: "#000", marginTop: "-5px" }}
              >
                {data?.inviteDto?.inviteDesc}
              </div>

              <div style={{ marginTop: 24 }}>
                <div style={{ fontSize: 30, fontWeight: 700 }}>
                  Your referral code is :{" "}
                  <span style={{ color: "#34006B" }}>
                    {data?.inviteDto?.inviteCode}
                  </span>
                </div>
              </div>
              <button
                className={`btn text-white  addWebButton ${
                  profileDetails.preference === "ADVERTISER" && "bg-theme"
                } `}
                style={{
                  height: 42,
                  width: 161,

                  borderRadius: 5,
                  color: "#fff",
                  fontSize: 16,
                  fontWeight: 700,
                  cursor: "pointer",
                  marginBottom: 24,
                }}
                onClick={copy}
              >
                COPY CODE
              </button>
              <h4
                className="mt-1"
                style={{ color: "#34006B", fontSize: 24, fontWeight: 700 }}
              >
                More ways to invite your friends
              </h4>

              <div className="row w-100 mb-3">
                <div className="col-sm-2"></div>
                <div className="col-sm-8">
                  <div className="position-relative">
                    <input
                      type="text"
                      value={email}
                      className="form-control"
                      placeholder="Your friend's email"
                      aria-label="Your friend's email"
                      aria-describedby="button-addon2"
                      onChange={(e) => setEmail(e.target.value)}
                      style={{
                        borderColor: "#D8E2EF",
                        padding: 14,
                        width: "100%",
                        paddingRight: 180,
                        borderRadius: 10,
                      }}
                    />
                    <button
                      className={`btn text-white position-absolute  addWebButton ${
                        profileDetails.preference === "ADVERTISER" && "bg-theme"
                      } `}
                      style={{
                        width: 170,
                        height: 45,
                        right: 3,
                        top: 4,

                        borderRadius: 10,
                        color: "#fff",
                      }}
                      type="button"
                      onClick={sendInvite}
                    >
                      Send Invitation
                    </button>
                  </div>
                </div>
                <div className="col-sm-2"></div>
              </div>

              <div className="d-flex flex-row justify-content-center  flex-wrap">
                <div className="ms-1 me-1">
                  <FacebookShareButton
                    url={data?.inviteDto?.inviteUrl}
                    quote={inviteShareText}
                  >
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                </div>
                <div className="ms-1 me-1">
                  <WhatsappShareButton url={data?.inviteDto?.inviteUrl}>
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                </div>

                <div className="ms-1 me-1">
                  <TwitterShareButton
                    url={data?.inviteDto?.inviteUrl}
                    title={inviteShareText}
                  >
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                </div>

                <div className="ms-1 me-1">
                  <LinkedinShareButton
                    url={data?.inviteDto?.inviteUrl}
                    title={inviteShareText}
                  >
                    <LinkedinIcon size={32} round={true} />
                  </LinkedinShareButton>
                </div>

                <div className="ms-1 me-1">
                  <TelegramShareButton
                    url={data?.inviteDto?.inviteUrl}
                    title={inviteShareText}
                  >
                    <TelegramIcon size={32} round={true} />
                  </TelegramShareButton>
                </div>
              </div>
            </div>
          </div>

          <div
            className="text-center"
            style={{ color: "#34006B", fontWeight: 600, marginTop: 24 }}
          >
            {getContent(language, "VIEW_STATUS_REFERRAL_TEXT")}
          </div>
          <div className="text-center">
            <button
              className={`btn text-white  addWebButton ${
                profileDetails.preference === "ADVERTISER" && "bg-theme"
              } `}
              onClick={() => {
                window.location.href = "/user/referral/status";
                GAevent(EVENT_NAME.invite_friends, ["TRACK_REFERRAL"]);
              }}
              style={{
                width: 328,
                height: 48,
                fontWeight: 600,
                color: "#fff",

                borderRadius: 5,
                marginTop: 5,
              }}
            >
              {getContent(language, "VIEW_STATUS_REFERRAL")}
            </button>
          </div>

          <div className="container mb-5" style={{ marginTop: 24 }}>
            <Tab.Container id="left-tabs-example" defaultActiveKey="0">
              <Row>
                <div className="col-sm-2"></div>
                <div className="col-sm-8 text-center">
                  <h5
                    style={{ fontWeight: 800, color: "#34006B", fontSize: 30 }}
                  >
                    {getContent(language, "MARKETING_TOOL")}
                  </h5>
                  <p style={{ color: "#000", fontSize: 18, marginTop: "-5px" }}>
                    {getContent(language, "MARKETING_TOOL_TEXT1")}
                  </p>

                  <div className="row">
                    <div
                      className="card p-2 border-0"
                      style={{
                        boxShadow: "-4px 4px 20px rgba(0, 0, 0, 0.2)",
                        borderRadius: 10,
                      }}
                    >
                      <Nav variant="pills" defaultActiveKey="0">
                        {Object.keys(data.inviteDto.inviteImageShareUrls).map(
                          (item, i) => (
                            <Nav.Item
                              style={{
                                flex: 1,
                                textAlign: "center",
                                display: "flex",
                              }}
                            >
                              <Nav.Link
                                eventKey={i + ""}
                                onClick={() => {
                                  GAevent(EVENT_NAME.invite_friends, [
                                    "marketing_tool_header",
                                    item,
                                  ]);
                                }}
                              >
                                {item.replace("_", " X ")}
                              </Nav.Link>
                            </Nav.Item>
                          )
                        )}
                      </Nav>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <Tab.Content>
                      {Object.keys(data.inviteDto.inviteImageShareUrls).map(
                        (item, i) => (
                          <Tab.Pane eventKey={i + ""}>
                            <div className="row">
                              <div
                                className={`${
                                  item.split("_")[0] > 720
                                    ? "col-md-12"
                                    : "col-md-6"
                                } mb-3`}
                              >
                                <a href={data.inviteDto.inviteUrl}>
                                  <img
                                    src={
                                      data.inviteDto.inviteImageShareUrls[item]
                                    }
                                    style={{
                                      height: item.split("_")[1],
                                      width: item.split("_")[0],
                                    }}
                                  />
                                </a>
                              </div>
                              {item.split("_")[0] > 720 ? (
                                <div className="col-md-3" />
                              ) : null}
                              <div
                                className={`${
                                  item.split("_")[0] > 720
                                    ? "col-md-6"
                                    : "col-md-6"
                                } mb-3`}
                              >
                                <div
                                  style={{
                                    border: "1px solid #f8f8f8",
                                    textAlign: "left",
                                    padding: 10,
                                    background: "#e8e8e8",
                                    overflowWrap: "break-word",
                                    borderRadius: 5,
                                  }}
                                >
                                  {`<a href="${data.inviteDto.inviteUrl}"> <img src="${data.inviteDto.inviteImageShareUrls[item]}" alt="OutreachMantra signup referral"/></a>`}
                                </div>
                                <button
                                  className={`btn text-white  addWebButton ${
                                    profileDetails.preference ===
                                      "ADVERTISER" && "bg-theme"
                                  } `}
                                  style={{
                                    width: "auto",
                                    fontWeight: 500,
                                    color: "#fff",
                                    borderRadius: 5,
                                    float: "right",
                                    cursor: "pointer",
                                    marginTop: 2,
                                  }}
                                  onClick={() => {
                                    copyMarketingTool(
                                      `<a href="${data.inviteDto.inviteUrl}"> <img src="${data.inviteDto.inviteImageShareUrls[item]}" alt="OutreachMantra signup referral"/></a>`
                                    );
                                    GAevent(EVENT_NAME.invite_friends, [
                                      "marketing_tool_copy_code",
                                      item,
                                    ]);
                                  }}
                                >
                                  Copy
                                </button>
                              </div>
                              {item.split("_")[0] > 720 ? (
                                <div className="col-md-3" />
                              ) : null}
                            </div>
                          </Tab.Pane>
                        )
                      )}
                    </Tab.Content>
                  </div>
                </div>

                <div className="col-sm-2"></div>
              </Row>
            </Tab.Container>
          </div>
        </div>
      )}
      {data == null ? <FullScreenLoader /> : null}
      {isLoading ? <FullScreenLoader /> : null}
    </div>
  );
};

export default InviteFriend;
