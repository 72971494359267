export const defaultModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0",
    transform: "translate(-50%, -50%)",
    border: "none",
  },
  overlay: {
    background: "rgba(0,0,0,0.4)",
    zIndex: 99999,
  },
};

export const customStyles = {
  content: {
    textAlign: "center",
    padding: "32px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "8px",
  },
  overlay: {
    background: "rgba(0,0,0,0.4)",
    zIndex: 2,
  },
};

export const customModalStyles = {
  content: {
    textAlign: "center",
    paddingLeft: "32px",
    paddingRight: "32px",
    padding: "20px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "8px",
  },
  overlay: {
    background: "rgba(0,0,0,0.4)",
    zIndex: 2,
  },
};

export const opts = {
  height: "507",
  width: "832",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
  },
};

export const OrderActionPopupStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    paddingBottom: 5,
    transform: "translate(-50%, -50%)",
    width: window.screen.width < 450 ? "350px" : "450px",
    maxWidth: "450px",
    background: "#fff",
    border: "none",
    boxShadow: "0px 0px 10px -7px rgba(0,0,0,0.99)",
    "-webkit-box-shadow": "0px 0px 10px -7px rgba(0,0,0,0.99)",
    "-moz-box-shadow": "0px 0px 10px -7px rgba(0,0,0,0.99)",
    borderRadius: "8px",
  },
  overlay: {
    background: "rgba(0,0,0,0.4)",
    zIndex: 1,
  },
};
