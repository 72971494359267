import React from "react";
import PrimaryButton from "./PrimaryButton";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className=" vh-100 d-flex flex-column align-items-center justify-content-center">
          <h1>Something Went Wrong :(</h1>
          <h3>Please try again</h3>
          <PrimaryButton
            onClick={() => window.location.reload()}
            className="mt-3"
          >
            Retry
          </PrimaryButton>
        </div>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
