export const handleHideNavLinks = (location, setHideNavLinks) => {
  const path = location.pathname;
  const routeToHide = [
    "/set-preference",
    "/check-mail",
    "/error-page",
    "/finish-setup",
  ];
  if (routeToHide.includes(path)) setHideNavLinks(true);
};
