import React from "react";
import { useHistory } from "react-router-dom";

const OrderContentButton = () => {
  const history = useHistory();
  return (
    <div>
      <button
        className="text-white fw-bold  py-2  px-4 bg-theme addWebButton shadow text-nowrap"
        onClick={() => history.push("/advertiser/order-content-writing")}
      >
        ORDER CONTENT
      </button>
    </div>
  );
};

export default OrderContentButton;
