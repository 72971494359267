const d = new Date();

export const EN = {
  SEO_LOGO_URL:
    "https://blog.outreachmantra.com/wp-content/uploads/2022/01/OutreachMantra-logo.png",
  SAFARI_VERSION_LOWER_TEXT:
    "There will be UI rendering issue on Safari browser below version 14. Kindly upgrade your Safari browser version or switch to Chrome/Firefox browser.",

  // Meta
  META_TITLE_HOME: "OutreachMantra - Buy & Sell Premium Guest Post Marketplace",
  META_DESCRIPTION_HOME:
    "OutreachMantra is a premium guest post marketplace that caters to advertisers and publishers at the same time. Signup for a free account & explore the marketplace.",

  META_TITLE_OUR_STORY: "About Us  - OutreachMantra",
  META_DESCRIPTION_OUR_STORY:
    "Learn everything you need to know about the OutreachMantra mission, history, and team. We help you boost business growth through guest posts",

  META_TITLE_FAQ: "FAQ - OutreachMantra",
  META_DESCRIPTION_FAQ:
    "We have answered all the basic customer queries here. Check out our FAQ page and find out answers to your queries.",

  META_TITLE_CONTACT_US: "Contact Us - OutreachMantra",
  META_DESCRIPTION_CONTACT_US:
    "Need any assistance? No worries, our virtual assistants are always available for your queries. Email us at support@outreachmantra.com",

  META_TITLE_ADVERTISERS:
    "Premium Guest Post Services: Buy Guest Posts on Real Blogs",
  META_DESCRIPTION_ADVERTISERS:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_PUBLISHERS: "Sell Guest Posts & Monetize Your Website",
  META_DESCRIPTION_PUBLISHERS:
    "OutreachMantra provides independent publishers with an easy monetization method. Start selling guest posts, connect with advertisers all over the world and grow business.",

  META_TITLE_CONTENT_WRITING:
    "Professional Content Writing Services: Get Awesome Content",
  META_DESCRIPTION_CONTENT_WRITING:
    "OutreachMantra offers high-quality content writing services at affordable prices. Submit your requirements, and leave the rest on us.",

  META_TITLE_PRIVACY_POLICY: "Privacy Policy - OutreachMantra",
  META_DESCRIPTION_PRIVACY_POLICY:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_TERMS_AND_CONDITIONS: "Terms and Conditions - OutreachMantra",
  META_DESCRIPTION_TERMS_AND_CONDITIONS:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_SET_PREFERENCE: "Set Preference - OutreachMantra",
  META_DESCRIPTION_SET_PREFERENCE:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_CHANGE_PASSWORD: "Change Password - OutreachMantra",
  META_DESCRIPTION_CHANGE_PASSWORD:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_FORGOT_PASSWORD: "Password Reset - OutreachMantra",

  META_TITLE_CHECK_MAIL: "Check Mail - OutreachMantra",
  META_DESCRIPTION_CHECK_MAIL:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_LOGIN: "Login - OutreachMantra",
  META_DESCRIPTION_LOGIN: "Log in to your account to access the marketplace.",

  META_TITLE_SIGNUP: "Sign Up - OutreachMantra",
  META_DESCRIPTION_SIGNUP:
    "Welcome to OutreachMantra. Sign up for a new account and grow your business. No credit card required for signup.",

  META_TITLE_FINISH_SETUP: "Finish Setup - OutreachMantra",
  META_DESCRIPTION_FINISH_SETUP:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_404: "Premium Guest Post Services: Buy Guest Posts on Real Blogs",
  META_DESCRIPTION_404:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_DATA_DELETION: "Data Deletion - OutreachMantra",
  META_DESCRIPTION_DATA_DELETION:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_REPORTS: "Reports - OutreachMantra",
  META_DESCRIPTION_REPORTS:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_WISHLIST: "OutreachMantra - Wishlist",
  META_DESCRIPTION_WISHLIST:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_PAYMENTS: "Payments - OutreachMantra",
  META_DESCRIPTION_PAYMENTS:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_BILLING_AND_FUNDS: "Billing and Funds - OutreachMantra",
  META_DESCRIPTION_BILLING_AND_FUNDS:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_ORDERS: "My Orders - OutreachMantra",
  META_DESCRIPTION_ORDERS:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_PAYPAL_INVOICE: "Paypal Invoices - OutreachMantra",
  META_DESCRIPTION_PAYPAL_INVOICE:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_ADD_PAYPAL_INVOICE: "Create Paypal Invoice - OutreachMantra",
  META_DESCRIPTION_ADD_PAYPAL_INVOICE:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_EDIT_PAYPAL_INVOICE: "Edit Paypal Invoice - OutreachMantra",
  META_DESCRIPTION_EDIT_PAYPAL_INVOICE:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_CONTENT_WRITING_PAGE: "Content Writing - OutreachMantra",
  META_DESCRIPTION_CONTENT_WRITING_PAGE:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_CART_PAGE: "Cart - OutreachMantra",
  META_DESCRIPTION_CART_PAGE:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_MY_WEBSITES: "My Websites - OutreachMantra",
  META_DESCRIPTION_MY_WEBSITES:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_ORDER_CONTENT_WRITING: "OutreachMantra - Content Writing",
  META_DESCRIPTION_ORDER_CONTENT_WRITING:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_EDIT_WEBSITE: "My Websites - OutreachMantra",
  META_DESCRIPTION_EDIT_WEBSITE:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_UPDATE_TRAFFIC: "My Websites - OutreachMantra",
  META_DESCRIPTION_UPDATE_TRAFFIC:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_DASHBOARD: "Dashboard - OutreachMantra",
  META_DESCRIPTION_DASHBOARD:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_ADD_WEBSITE: "My Websites - OutreachMantra",
  META_DESCRIPTION_ADD_WEBSITE:
    "At OutreachMantra, we offer high quality guest posts that you can review before the link goes live. Explore the marketplace now!",

  META_TITLE_INVITE_FRIEND: "Invite Friends - OutreachMantra",
  META_TITLE_TRACK_REFERRAL: "Track Referrals - OutreachMantra",

  INVITATION_SENT_MESSAGE: "Invitation Sent Successfully",
  INVITATION_LINK_COPY_MESSAGE: "Invite link copied",
  INVITATION_BANNER_COPY_MESSAGE: "Code copied",

  NAME: "Name",
  EMAIL: "Email",
  WALLET_BALANCE: "Wallet Balance",
  PREFERENCE: "Preference",

  // Modals
  MODAL_PAYMENT_IN_PROGRESS: "Payment in Progress...",
  MODAL_DONT_ASK_AGAIN_TEXT: "Don't ask again",
  MODAL_GO_BACK_CTA: "Go Back",
  MODAL_CONTACT_US_SUBMIT_SUCCESS:
    "Thank You! Our team will reply in the next 24 hours.",
  MODAL_ADD_FUNDS_SUCCESS: "Funds Added Successfully",
  MODAL_MY_WEBSITES_SUBMIT_WEBSITE_SUCCESS: "Website Submitted Successfully",
  MODAL_MY_WEBSITES_EDIT_WEBSITE_SUCCESS: "Website Edited Successfully",
  MODAL_MY_WEBSITES_UPDATE_TRAFFIC_SUCCESS: "Traffic Updated Successfully",
  MODAL_MY_WEBSITES_GO_TO_MY_WEBSITES_CTA: "Go To My Websites",
  MODAL_MY_WEBSITES_REQUIRE_CONSENT_TEXT:
    "We require your consent from Google to check your ownership of the domain.",
  MODAL_CART_ORDER_PLACED_SUCCESS: "Order Placed Successfully", // used for marketplace checkout also
  MODAL_CART_GO_TO_MY_ORDERS_CTA: "Go to My Orders", // used for marketplace checkout also
  MODAL_CART_DELETE_CONFIRMATION:
    "Are you sure, you want to delete item from your Cart?",
  MODAL_GO_TO_CONTENT_ORDERS_CTA: "Go To My Content Orders",
  MODAL_MARKETPLACE_PRICE_POST_TYPE_TITLE: "Select Post Type",
  MODAL_ORDER_CONTENT_WRITING_SUCCESS: "Content Order Placed Successfully",
  MODAL_WISHLIST_DELETE_CONFIRMATION:
    "Are you sure, you want to delete item from your wishlist?",
  MODAL_GO_TO_CART_CTA: "Go To Cart",

  MODAL_PAYMENT_PENDING_DETAILS_TITLE: "PENDING DETAILS",
  MODAL_PAYMENT_PENDING_DETAILS_PRIMARY_ACCOUNT: "Primary Account:",
  MODAL_PAYMENT_PENDING_DETAILS_FOLLOWING_DETAILS: [
    "Following are the",
    "details:",
  ],
  MODAL_PAYMENT_PENDING_DETAILS_PAYPAL_ADDRESS: "Paypal Address:",
  MODAL_PAYMENT_PENDING_DETAILS_ACCOUNT_TYPE: "Account Type:",
  MODAL_PAYMENT_PENDING_DETAILS_BANK_NAME: "Bank Name:",
  MODAL_PAYMENT_PENDING_DETAILS_BENEFICIARY_ACCOUNT_NUMBER:
    "Beneficiary Account Number:",
  MODAL_PAYMENT_PENDING_DETAILS_BENEFICIARY_NAME: "Beneficiary Name:",
  MODAL_PAYMENT_PENDING_DETAILS_IFSC_CODE: "IFSC Code:",
  MODAL_PAYMENT_PENDING_DETAILS_OTHER_BANK_NAME: "Other Bank Name:",

  // Placeholders
  PH_CONTACT_US_FIRST_NAME: "First name",
  PH_CONTACT_US_LAST_NAME: "Last name",
  PH_CONTACT_US_EMAIL: "Email",
  PH_CONTACT_US_MESSAGE: "MESSAGE",
  PH_SIGNUP_FIRST_NAME: "First name",
  PH_SIGNUP_LAST_NAME: "Last name",
  PH_SIGNUP_EMAIL: "Email",
  PH_REFERRAL_CODE_TITLE: "Referral Code (Optional)",
  PH_REFERRAL_CODE: "Enter Referral Code",
  PH_PASSWORD_RESET_EMAIL: "Enter your email address",
  PH_LOGIN_EMAIL: "Email",
  PH_LOGIN_PASSWORD: "Password",
  PH_ORDER_CONTENT_TITLE: "Type here",
  PH_ORDER_CONTENT_INSTRUCTION: "Description",
  PH_MARKETPLACE_SEARCHBAR: "Search",
  PH_CHANGE_PASSWORD_EMAIL: "Enter your email address",
  PH_ADD_WEBSITE_STEP_ONE_BLOG_URL: "Enter your Blog URL",
  PH_ADD_WEBSITE_STEP_TWO_WEBSITE_NAME: "Type here",
  PH_ADD_WEBSITE_STEP_TWO_SAMPLE_URL: "Enter Sample URL",
  PH_ADD_WEBSITE_STEP_TWO_TAT: "TAT (value only can be 1 - 30)",
  PH_ADD_WEBSITE_STEP_TWO_POST_GUIDELINES: "Type Here",
  PH_ADD_WEBSITE_STEP_TWO_CONTACT_NUMBER: "Enter Contact Number",
  PH_ADD_WEBSITE_STEP_TWO_SKYPE_ID: "Enter Skype ID",
  PH_ADD_WEBSITE_STEP_THREE_TRAFFIC: "Enter Traffic Value",
  PH_ORDER_ACTION_POPUP_ENTER_LIVE_URL: "https://",
  PH_ORDER_ACTION_POPUP_REASON: "Enter your reason",
  PH_CART_TITLE: "Type Here",
  PH_CART_INSTRUCTION: "Type Here",
  PH_CART_EXISTING_POST_URL: "https://",
  PH_CART_ANCHOR_TEXT: "Type Here",
  PH_CART_TARGET_LINK: "https://",
  PH_PAYMENTS_ENTER_PAYPAL_ADDRESS: "Enter your Paypal Address",
  PH_KEYWORD_TARGET_URL_COMPONENT_KEYWORD_TEXT: "Type Here",
  PH_KEYWORD_TARGET_URL_COMPONENT_TARGET_URL_TEXT: "https://",
  PH_ADD_FUNDS_ENTER_AMOUNT: "Enter Amount",
  PH_PREFERENCE_RIGHT_DRAWER_FILTER_NAME: "Type Filter Name",
  PH_PREFERENCE_RIGHT_DRAWER_MIN: "Min",
  PH_PREFERENCE_RIGHT_DRAWER_MAX: "Max",

  // Labels
  LABEL_ADD_FUNDS_ENTER_AMOUNT: "Enter Amount",
  LABEL_CREATE_PAYPAL_INVOICE: "You can now add funds using PayPal Invoice",
  LABEL_PREFERENCE_RIGHT_DRAWER_FILTER_NAME: "Filter Name",
  LABEL_PREFERENCE_RIGHT_DRAWER_DA_RANGE: "DA Range",
  LABEL_PREFERENCE_RIGHT_DRAWER_CATEGORIES: "Categories",
  LABEL_PREFERENCE_RIGHT_DRAWER_TRAFFIC: "Traffic",
  LABEL_PREFERENCE_RIGHT_DRAWER_GA_VALIDATED: "GA Validated",
  LABEL_PREFERENCE_RIGHT_DRAWER_POST_TYPE: "Post Type",
  LABEL_PREFERENCE_RIGHT_DRAWER_PRICE: "Price",
  LABEL_KEYWORD_TARGET_URL_COMPONENT_KEYWORD_TEXT: "Keyword",
  LABEL_KEYWORD_TARGET_URL_COMPONENT_TARGET_URL_TEXT: "Target URL",
  LABEL_ORDER_CONTENT_WRITING_TITLE: "Title Suggestion",
  LABEL_ORDER_CONTENT_WRITING_WORD_COUNT: "Word Count",
  LABEL_ORDER_CONTENT_WRITING_WRITING_STYLE: "Choose writing style",
  LABEL_ORDER_CONTENT_WRITING_INSTRUCTION: "Instructions",
  LABEL_CART_CONTENT_TYPE: "Content Type",
  LABEL_CART_ATTACHMENT: "Attachment",
  LABEL_CART_ATTACHMENT_FORMATS:
    "(Note: Support only .doc, .docx, .txt, .pdf File.)",
  LABEL_CART_DROP_FILES: "Drop your Files Here!",
  LABEL_CART_TITLE: "Title Suggestion",
  LABEL_CART_WORD_COUNT: "Word Count",
  LABEL_CART_WRITING_STYLE: "Choose Writing Style",
  LABEL_CART_INSTRUCTIONS: "Instructions",
  LABEL_CART_EXISTING_POST_URL: "Existing Post URL",
  LABEL_ANCHOR_TEXT: "Anchor Text",
  LABEL_TARGET_LINK: "Target Link",
  LABEL_ADD_WEBSITE_STEP_ONE_ENTER_WEBSITE_URL: "Enter Website URL",
  LABEL_ORDER_ACTION_POPUP_LIVE_URL: "Enter Live URL",
  LABEL_ORDER_ACTION_POPUP_DELIVERED_URL: "Delivered URL",
  LABEL_ORDER_ACTION_POPUP_NOTE: "Note:",
  LABEL_ORDER_ACTION_POPUP_REASON: "Reason",

  // Error Messages
  ERR_CONTACT_FIRST_NAME: "Enter valid First Name.",
  ERR_CONTACT_LAST_NAME: "Enter valid Last Name.",
  ERR_CONTACT_EMAIL: "Enter valid Email.",
  ERR_CONTACT_MSG: "Enter valid message.",
  ERR_SET_PREFERENCE_PREFERENCE: "Please select a preference",
  ERR_CHANGE_PASSWORD_EMAIL: "Enter valid Email.",
  ERR_FORGOT_PASSWORD_EMAIL: "Enter valid Email.",
  ERR_LOGIN_EMAIL: "Enter valid Email.",
  ERR_LOGIN_PASSWORD: "Enter valid password.",
  ERR_SIGNUP_FIRST_NAME: "Enter valid First Name.",
  ERR_SIGNUP_LAST_NAME: "Enter valid Last Name.",
  ERR_SIGNUP_EMAIL: "Enter valid Email.",
  ERR_FINISH_SETUP_PASSWORD: "Password field cannot be empty",
  ERR_FINISH_SETUP_CONFIRM_PASSWORD: "Confirm password field cannot be empty",
  ERR_FINISH_SETUP_PREFERENCE: "Please select a preference",
  ERR_FINISH_SETUP_PASSWORD_NOT_MATCHING: "Password does not match",
  //!!! -> Some of these cart ones are actually snackbars
  ERR_CART_URL: "URL is required",
  ERR_CART_ANCHOR_TEXT: "Anchor text is required",
  ERR_CART_ANCHOR_LINK: "Target Link is required",
  ERR_CART_ATTACHMENT: "Attachment file is required",
  ERR_CART_TITLE: "Title is required",
  ERR_CART_WORD_COUNT: "Word count is required",
  ERR_CART_WRITING_STYLE: "Writing style is required",
  ERR_CART_KEYWORD: "Keyword is required", // used for order content writing as well
  ERR_CART_TARGET_URL: "Target URL is required", // used for order content writing as well
  ERR_CART_TARGET_URL_INVALID: "Target URL is invalid", // used for order content writing as well
  ERR_CART_INSUFFICIENT_FUNDS:
    "You don’t have enough money on your wallet. Please add funds to proceed.",

  ERR_ADD_WEBSITE_STEP_TWO_WEBSITE_NAME: "Website name is required",
  ERR_ADD_WEBSITE_STEP_TWO_POST_CATEGORY:
    "Minimum one post category is required",
  ERR_ADD_WEBSITE_STEP_TWO_POST_TYPE: "Minimum one post type is required",
  ERR_ADD_WEBSITE_STEP_TWO_EMPTY_GUEST_POST_PRICE:
    "Empty guest post price not allowed",
  ERR_ADD_WEBSITE_STEP_TWO_EMPTY_LINK_INSERTION_PRICE:
    "Empty link insertion price not allowed",
  ERR_ADD_WEBSITE_STEP_TWO_EMPTY_CASINO_POST_PRICE:
    "Empty casino post price not allowed",
  ERR_ADD_WEBSITE_STEP_TWO_SAMPLE_URL: "Sample URL is required",
  ERR_ADD_WEBSITE_STEP_TWO_SAMPLE_URL_INVALID: "Sample URL is not valid",
  ERR_ADD_WEBSITE_STEP_TWO_TAT: "TAT is required",
  ERR_ADD_WEBSITE_STEP_TWO_TAT_INVALID: "TAT value should be between 1 and 30",
  ERR_ADD_WEBSITE_STEP_THREE_TRAFFIC_VALUE: "Traffic value is required",

  // Snackbars
  SB_FILL_WEBSITE_DETAILS_FIRST: "You need to fill post details first",
  SB_SET_PREFERENCE_LOGGED_OUT_SUCCESS: "Logged Out Successfully",
  SB_CHANGE_PASSWORD_VALID_EMAIL_ERROR: "Please enter a valid email address",
  SB_FORGOT_PASSWORD_VALID_EMAIL_ERROR: "Please enter a valid email address",
  SB_FINISH_SETUP_PASSWORD: "Password field cannot be empty",
  SB_FINISH_SETUP_CONFIRM_PASSWORD: "Confirm password field cannot be empty",
  SB_FINISH_SETUP_PREFERENCE: "Please select a preference",
  SB_FINISH_SETUP_PASSWORD_INVALID: "Password not in valid format",
  SB_FINISH_SETUP_PASSWORD_NOT_MATCHING:
    "Password and confirm password do not match",
  SB_ENTER_START_DATE: "Please Enter Start Date",
  SB_WISHLIST_DELETE_ITEM_SUCCESS: "Wishlist Item Deleted Successfully",
  SB_PAYMENTS_DELETE_METHOD_SUCCESS: "Payment Method Deleted Successfully",
  SB_PAYMENTS_ANOTHER_PENDING_IN_QUEUE:
    "Another pending request already in queue",
  SB_PAYMENTS_PAYPAL_ADDRESS: "Paypal address is required",
  SB_PAYMENTS_NEW_METHOD_VERIFICATION:
    "New Payment Method Sent for Verification",
  SB_PAYMENTS_UPDATE_METHOD_VERIFICATION:
    "Payment Method Update Sent for Verification",
  SB_PAYMENTS_BANK_BENEFICIARY_NAME: "Beneficiary name is required",
  SB_PAYMENTS_BANK_BANK_NAME: "Bank name is required",
  SB_PAYMENTS_BANK_ACCOUNT_NUMBER: "Account number is required",
  SB_PAYMENTS_BANK_IFSC_CODE: "IFSC Code is required",
  SB_PAYMENTS_BANK_OTHER_BANK_NAME: "Other bank name is required",
  SB_CART_ITEM_DELETED: "Cart Item Deleted Successfully",
  SB_MY_WEBSITES_WEBSITE_DISABLED: "This website is disabled",
  // Some used for error messages as well here below ones
  SB_ORDER_CONTENT_WRITING_TITLE: "Title is required",
  SB_ORDER_CONTENT_WRITING_WORD_COUNT: "Word count is required",
  SB_ORDER_CONTENT_WRITING_WRITING_STYLE: "Writing style is required",
  SB_ORDER_CONTENT_WRITING_KEYWORDS: "Keyword is required",
  SB_ORDER_CONTENT_WRITING_TARGET_URL: "Target URL is required",
  SB_ORDER_CONTENT_WRITING_TARGET_URL_INVALID: "Target URL is invalid",
  SB_ADD_WEBSITE_CONSENT_APPROVE_SUCCESS: "User Consent Approved Successfully",
  SB_ADD_WEBSITE_ENTER_VALID_URL: "Please enter a valid website URL",
  SB_USER_CLOSED_POPUP: "Popup closed by the user.",
  SB_ADD_WEBSITE_VERIFY_WEBSITE_FIRST: "You need to verify your website first",
  SB_ADD_WEBSITE_FILL_POST_DETAILS_FIRST: "You need to fill post details first",
  SB_ADD_WEBSITE_META_TAG_COPIED: "Meta Tag Copied",
  SB_ADD_WEBSITE_DNS_COPIED: "DNS Value Copied",
  SB_ADD_WEBSITE_STEP_THREE_TRAFFIC_VALUE: "Traffic value is required",
  SB_ADD_WEBSITE_STEP_THREE_GA_VERIFIED_SUCCESS:
    "Google Analytics Verified Successfully",
  SB_NEW_FILTER_SAVED_SUCCESS: "Saved new filter successfully",
  SB_FILTER_EDITED_SUCCESS: "Edited filter successfully",
  SB_MARKETPLACE_ADDED_TO_CART_SUCCESS: "Added to Cart Successfully",
  SB_MARKETPLACE_ADDED_TO_WISHLIST_SUCCESS: "Added to Wishlist Successfully",
  SB_MARKETPLACE_REMOVED_FROM_WISHLIST_SUCCESS:
    "Removed from Wishlist Successfully",
  SB_PREFERENCE_RIGHT_DRAWER_MIN_ONE_FILTER: "Minimum 1 filter is required",

  // Keywords
  LOAD_MORE_BUTTON_TEXT: "LOAD MORE",
  DELETE_TEXT: "DELETE",
  CANCEL_TEXT: "CANCEL",
  UPDATE_TEXT: "UPDATE",
  EDIT_TEXT: "EDIT",
  REMOVE_TEXT: "REMOVE",
  SUBMIT_TEXT: "SUBMIT",
  ADD_TEXT: "ADD",
  VERIFIED_TEXT: "Verified by",
  VERIFY_TEXT: "Verify",
  VERIFY_GA_TEXT: "Verify GA",
  DA_TEXT: "DA",
  DR_TEXT: "DR",
  TAT_TEXT: "TAT (in Days)",
  TRAFFIC_TEXT: "GA Traffic",
  A_HREF_TRAFFIC_TEXT: "Ahrefs Traffic",
  WEBSITE_STATUS: "WEBSITE STATUS",
  MESSAGE_TEXT: "MESSAGE",
  ACTION_TEXT: "ACTION",
  ACTIVATE_TEXT: "ACTIVATE",
  DEACTIVATE_TEXT: "DEACTIVATE",
  GUEST_POST_TEXT: "GUEST POST",
  LINK_INSERTION_TEXT: "LINK INSERTION",
  CASINO_POST_TEXT: "CASINO POST",
  CLICK_HERE: "Click Here",
  NEXT: "NEXT",
  UPDATE: "UPDATE",
  SUBMIT: "SUBMIT",
  OR: "OR",
  MODAL_INITIALIZING: "Initializing...",
  MODAL_FINALIZING: "Finalizing...",
  ADVERTISERS: "ADVERTISERS",
  PUBLISHERS: "PUBLISHERS",
  ADVERTISER: "ADVERTISER",
  PUBLISHER: "PUBLISHER",
  CONTENT_WRITING: "CONTENT WRITING",
  CHOOSE_DATE: "Choose Date",
  FILTER_BY_STATUS: "Filter by Status",
  SORT_BY: "Sort By",
  CHOOSE_DATE_FROM: "Choose Date From",
  CLEAR: "CLEAR",
  PAYPAL: "PAYPAL",
  SAMPLE_POST: "SAMPLE POST",
  GUIDELINES: "GUIDELINES",
  CATEGORIES: "CATEGORIES",

  // Labels
  LABEL_ADD_WEBSITE_STEP_TWO_WEBSITE_NAME: "Website Name",
  LABEL_ADD_WEBSITE_STEP_TWO_POST_CATEGORIES: "Post Categories",
  LABEL_ADD_WEBSITE_STEP_TWO_POST_CATEGORIES_DESCRIPTION: [
    "Upto",
    "categories",
  ],
  LABEL_ADD_WEBSITE_STEP_TWO_SET_POST_TYPE_PRICE: "Set Price Per Post Type",
  LABEL_ADD_WEBSITE_STEP_TWO_SET_POST_TYPE_PRICE_DESCRIPTION:
    "(Set lower price to attract more advertisers)",
  LABEL_ADD_WEBSITE_STEP_TWO_SAMPLE_URL: "Sample URL",
  LABEL_ADD_WEBSITE_STEP_TWO_TAT: "TAT (Turn Around Time in Days)",
  LABEL_ADD_WEBSITE_STEP_TWO_TAT_TIME_CRITICAL:
    "Time is critical for customer satisfaction",
  LABEL_ADD_WEBSITE_STEP_TWO_POST_GUIDELINE: "Post Guideline",
  LABEL_ADD_WEBSITE_STEP_TWO_CONTACT_NUMBER: "Enter Contact Number (optional)",
  LABEL_ADD_WEBSITE_STEP_TWO_SKYPE_ID: "Enter Skype ID (optional)",
  LABEL_ADD_WEBSITE_STEP_THREE_TRAFFIC_DETAILS: "Traffic Details",
  LABEL_ADD_WEBSITE_STEP_THREE_AHREF: "Add Ahrefs Traffic",
  LABEL_ADD_WEBSITE_STEP_THREE_GA_VALIDATED: "GA Validated",
  LABEL_ADD_WEBSITE_STEP_THREE_VERIFY_TRAFFIC:
    "Verify traffic with Google Analytics",
  LABEL_ADD_WEBSITE_STEP_THREE_DESCRIPTION_LONG: `We have seen 337% increase in sponsored post offers for the blogs who
  have verified their Google Analytics with us, hence we would recommend
  you to verify Google Analytics to get more orders.`,
  LABEL_ADD_WEBSITE_STEP_THREE_SUBMIT_WEBSITE_BTN: "SUBMIT WEBSITE",
  LABEL_ADD_WEBSITE_STEP_THREE_EDIT_WEBSITE_BTN: "EDIT WEBSITE",
  LABEL_ADD_WEBSITE_STEP_THREE_UPDATE_TRAFFIC_BTN: "UPDATE TRAFFIC",

  // Tooltips
  TOOLTIP_GA_VERIFIED: "Google Analytics Verified",
  TOOLTIP_GA_NOT_VERIFIED: "Google Analytics Not Verified",
  TOOLTIP_MY_WEBSITE_LAST_UPDATED_TIME: "Last Updated Time",
  TOOLTIP_SAVED_FILTER: [
    "Save your search as a filter. Only data that matches the search criteria is displayed in the list. Click here to",
    "learn more",
  ],

  // Marketplace
  MARKETPLACE_NO_SAVED_FILTERS: "No saved filters in the list.",
  MARKETPLACE_REMOVE_FILTER_TEXT_BTN: "REMOVE FILTER",
  MARKETPLACE_CREATE_FILTER_TEXT_BTN: "CREATE FILTER",
  MARKETPLACE_POST_TYPE: "Post Type:",
  MARKETPLACE_MANUAL_FILTERS: "Manual Filters",
  MARKETPLACE_FILTERS_APPLIED: "Filters Applied",
  MARKETPLACE_CLEAR_ALL_TEXT_BTN: "CLEAR ALL",
  MARKETPLACE_UPGRADE: [
    "Want to access the full inventory?",
    "You must add",
    "to your wallet to see the complete list of the websites.",
    "",
    "UPGRADE NOW",
  ],
  MARKETPLACE_GO_TO_CART_BTN: "GO TO CART",
  MARKETPLACE_DIRECT_CHECKOUT: "DIRECT CHECKOUT",
  MARKETPLACE_ADD_TO_CART_BTN: "ADD TO CART",
  MARKETPLACE_SEARCH_DROPDOWN_WEBSITE_NAME: "Website Name -",
  MARKETPLACE_RIGHT_DRAWER_UPDATE_FILTER_BTN: "Update Filter",
  MARKETPLACE_RIGHT_DRAWER_SAVE_AND_APPLY_BTN: "Save & Apply",

  // Add Website Page
  ADD_WEBSITE_CLICK_VERIFY_BELOW_TEXT: "Click Verify Below",
  ADD_WEBSITE_HOW_IT_WORKS_TEXT: "How it works",
  ADD_WEBSITE_STEP_ONE_HEADER: "LET'S GET STARTED...",
  ADD_WEBSITE_STEP_ONE_SUBHEADER:
    "Read more details about Publisher’s Interface.",
  ADD_WEBSITE_STEP_ONE_CLICK_HERE_TEXT: "Click Here",
  ADD_WEBSITE_STEP_ONE_CHECK_ELIGIBILITY_BUTTON: "CHECK ELIGIBILITY",
  ADD_WEBSITE_STEP_ONE_WEBSITE_URL_NOT_AVAILABLE_ERROR:
    "Website URL is not valid",
  ADD_WEBSITE_STEP_ONE_BLOG_AVAILABLE_TEXT: "Blog is eligible",
  ADD_WEBSITE_STEP_ONE_BLOG_NOT_AVAILABLE_TEXT: "Blog is not eligible",
  ADD_WEBSITE_STEP_ONE_ACCORDION_META_HEADER:
    "1. VERIFY WITH HTML META TAG (RECOMMENDED)",
  ADD_WEBSITE_STEP_ONE_ACCORDION_META_DESCIPTION: `Copy the meta tag below and paste it into the HTML code of your site's homepage. It should go in the &lt;head&gt; section, before the first &lt;body&gt; section.`,
  ADD_WEBSITE_STEP_ONE_COPY_META_TAG: "COPY META TAG",
  ADD_WEBSITE_STEP_ONE_META_TO_STAY_VERIFIED_TEXT:
    "To stay verified, don’t remove the meta tag, even after verification succeeds.",
  ADD_WEBSITE_STEP_ONE_VERIFY: "VERIFY",
  ADD_WEBSITE_STEP_ONE_RELOAD_PAGE: "RELOAD PAGE",
  ADD_WEBSITE_STEP_ONE_REACH_OUT_TO_TEXT:
    "If you are not able to verify your domain, please reach out to",
  ADD_WEBSITE_STEP_ONE_ACCORDION_HTML_HEADER: "2. VERIFY WITH HTML FILE",
  ADD_WEBSITE_STEP_ONE_ACCORDION_HTML_UPLOAD_VERIFICATION_FILE_TEXT:
    "Upload the HTML verification file below to your domain's root folder.",
  ADD_WEBSITE_STEP_ONE_ACCORDION_HTML_DOWNLOAD_VERIFICATION_FILE_BTN:
    "Download Verification File",
  ADD_WEBSITE_STEP_ONE_ACCORDION_HTML_FILE_VIEWABLE_AT:
    "If the upload was successful, the file will be viewable at",
  ADD_WEBSITE_STEP_ONE_VERIFY_HTML_BTN: "Verify (HTML)",
  ADD_WEBSITE_STEP_ONE_ACCORDION_DNS_HEADER: "3. VERIFY WITH DNS RECORD",
  ADD_WEBSITE_STEP_ONE_ACCORDION_DNS_DESCRIPTION:
    "Sign in to your domain provider (e.g. godaddy.com or namecheap.com) and copy the TXT record below to the DNS configuration for your domain.",
  ADD_WEBSITE_STEP_ONE_ACCORDION_DNS_NOTE:
    "Note: DNS changes may take some time. If we don’t find the record immediately, wait for upto 24 hours before trying again for",
  ADD_WEBSITE_STEP_ONE_COPY_DNS_VALUE: "COPY DNS VALUE",
  ADD_WEBSITE_STEP_ONE_VERIFY_DNS: "Verify (DNS)",

  // My Websites Page
  MY_WEBSITES_TOTAL_WEBSITES_COUNT_TEXT: ["Total ", " Websites"],

  // 404 Page
  PAGE_404_HEADER: "Sorry, page not found!",
  PAGE_404_HOME_CTA: "Go to Homepage",

  // Empty States
  EMPTY_REPORTS: "Data Not Found For Time Period",
  EMPTY_WISHLIST: "Data Not Found",
  EMPTY_PAYMENTS: "Data Not Found",
  EMPTY_BILLING_AND_FUNDS: "Data Not Found",
  EMPTY_ORDERS: "Data Not Found",
  EMPTY_INVOICE: "Data Not Found",
  EMPTY_CONTENT_WRITING: "Data Not Found",
  EMPTY_CART: "Data Not Found",
  EMPTY_MY_WEBSITES: "Data Not Found",

  // Wishlist
  WISHLIST_TOTAL_ITEMS_TEXT: ["Total", "Items in wishlist"],
  WISHLIST_MOVE_TO_CART_CTA: "MOVE TO CART",

  // Cart
  CART_CHOOSE_FILE: "CHOOSE FILE",
  CART_CHECKOUT_BTN: "CHECKOUT",

  // Static Pages (Common)
  GET_STARTED_HEADER: "Join thousands of Publishers & Advertisers",
  GET_STARTED_DESCRIPTION:
    "Still using cold email outreach to buy guest posts? Switch to OutreachMantra",
  GET_STARTED_CTA: "SIGN UP TODAY - IT'S FREE",
  JOIN_TEXT:
    "Join INSERT_KEYWORD_HERE enterprises using to step up the content marketing game.",

  GET_STARTED_HEADER_ADVERTISER: "Get started with OutreachMantra",
  GET_STARTED_DESCRIPTION_ADVERTISER:
    "Over INSERT_KEYWORD_HERE publishers that accept guest posts are waiting for you on OutreachMantra.",
  GET_STARTED_CTA_ADVERTISER: "SIGN UP TODAY - IT'S FREE",

  GET_STARTED_HEADER_PUBLISHER:
    "Get paid by selling guest Posts & publishing content",
  GET_STARTED_DESCRIPTION_PUBLISHER:
    "Join thousands of bloggers on OutreachMantra by publishing high-quality content and earning handsomely. Sign Up on the platform and make money from today!",
  GET_STARTED_CTA_PUBLISHER: "SIGN UP TODAY - IT'S FREE",

  GET_STARTED_HEADER_CONTENT_WRITING: "Quality content at scale",
  GET_STARTED_DESCRIPTION_CONTENT_WRITING:
    "Ready to see why industry-leading companies trust us to write their content? Create your free account today.",
  GET_STARTED_CTA_CONTENT_WRITING: "SIGN UP TODAY - IT'S FREE",

  HOW_IT_WORKS_HEADER: "How does it work?",
  HOW_IT_WORKS_DESCRIPTION:
    "We have created a user-friendly interface. So you can easily search and order the online media you need. Watch our tutorial video to make sure it’s easy.",

  // Footer
  FOOTER_COPYRIGHT: `©${d.getFullYear()} OutreachMantra`,
  FOOTER_TEXT:
    "OutreachMantra is a premium guest post marketplace that connects advertisers & publishers. Our primary goal is to help advertisers distribute content to the right audience, and provide a revenue source to the publishers.",

  // Static Pages (Home)
  HOME_TAKE_TOUR: "Take a Tour",
  HOME_VIEW_DETAILS: "View Details",
  HOME_GET_TO_KNOW_US: "Get to Know Us",

  HOME_TOTAL_WEBSITES: "Total Websites",
  HOME_TOTAL_ADVERTISERS: "Total Advertisers",
  HOME_TOTAL_PUBLISHERS: "Total Publishers",
  HOME_TOTAL_ORDERS: "Total Orders",

  HOME_HERO_HEADER: "Premium Guest Post Marketplace",
  HOME_HERO_SUBHEADER:
    "Buy Guest Posts on high traffic websites & increase your organic traffic",
  HOME_HERO_ADVERTISER_POINTERS: [
    "Content distribution and marketing, simplified",
    "Build your brand’s authority and improve organic traffic",
    "High traffic & high authority sites, at your fingertips",
    "Fast Turnaround time",
  ],
  HOME_HERO_PUBLISHER_POINTERS: [
    "Publish quality content & earn money",
    "Let your sponsored articles be noticed by INSERT_ADVERTISER_COUNT advertisers",
    "Higher rankings for quality & frequently updated content",
    "100% payment guarantee",
  ],
  HOME_HERO_CREATE_ACCOUNT_CTA: "CREATE A FREE ACCOUNT",
  HOME_SIGNUP_BONUS1: "Get ",
  HOME_SIGNUP_BONUS2: " SignUp Bonus",
  HOME_SIGNUP_BONUS3: " (Only for Advertisers)",
  HOME_HERO_NO_CREDIT_CARD_REQUIRED_TEXT: "*No Credit Card Required",
  HOME_HERO_BOTTOM_TEXT:
    "Over THOUSANDS of Quality Websites for Guest Posting are waiting for you. Publish now with outreachmantra.com",

  HOME_HOW_HELPS_HEADER: "How OutreachMantra Helps You With Guest Posts?",
  HOME_HOW_HELPS_SUBHEADER:
    "Tired of writing emails and waiting for replies from the webmasters? We have got your back.",

  HOME_HOW_HELPS_CARDS: [
    {
      title: "High authority & traffic sites",
      description:
        "OutreachMantra connects you with webmasters having high authority & genuine sites. Our team manually verify & approve sites, which saves your time.",
    },
    {
      title: "Hassle-free publishing ",
      description:
        "As soon as an order is placed, our priority is to get it published as fast as possible.",
    },
    {
      title: "Filter according to your needs",
      description:
        "Our user-oriented filters help you to quickly find sites according to your requirements. The filters help you pick the best ones from our database.",
    },
    {
      title: "Track the progress",
      description:
        "Once your order is placed, all information related to it can be tracked on the dashboard. Our automated systems even send your emails as soon as the content gets published.",
    },
    {
      title: "Dofollow Links",
      description:
        "OutreachMantra understands the importance of link juice, so your links are marked as Dofollow.",
    },
    {
      title: "Content Creation",
      description:
        "Content is the king! OutreachMantra understands the importance of quality content. Our wordsmith writers are well versed in writing content with facts & figures.",
    },
  ],

  HOME_TESTIMONIALS_HEADER: "Testimonials from customers",
  HOME_TESTIMONIALS_SUBHEADER: " What our customers <br /> have to say",

  // Static Pages (Our Story)
  OUR_STORY_HERO_HEADER:
    "From Content Creation To Building Authority, OutreachMantra Is A Go-To Platform For Marketers",
  OUR_STORY_HERO_DESCRIPTION_TEXT_BLOCK_1:
    "OutreachMantra's vision is to help businesses and SEOs to fast forward their link-building process. Unlike others, we make sure the sites we offer to our customers have: a clean link profile, high traffic from tier 1 and 2 countries, real blogs, not a guest post farm, genuine pricing.",
  OUR_STORY_HERO_DESCRIPTION_TEXT_BLOCK_2:
    "We understand the struggle of finding an ideal guest posting site, and therefore, we have our own set of rules to pick up sites that help the customer to rank build authority without draining the entire SEO budget for just 1 link.",
  OUR_STORY_HERO_DESCRIPTION_TEXT_TICK_1:
    "Our team checks each website submission made by webmasters and runs it through various quality checks, and negotiates the best possible price.",
  OUR_STORY_HERO_DESCRIPTION_TEXT_TICK_2:
    "We also offer content creation service so that you don’t have to worry about writing guest posts.",
  OUR_STORY_HERO_DESCRIPTION_TEXT_BLOCK_3:
    "OutreachMantra is a place for both advertisers and Publishers. We have INSERT_WEBSITE_COUNT verified websites and INSERT_ADVERTISER_COUNT advertisers who are actively looking for guest posting opportunities.",

  OUR_STORY_TOGETHER_QUOTE_HEADER: `"Quality Guest Posts are the backbone of Off-Page SEO.” OutreachMantra helps marketers to skyrocket rankings at minimal efforts & reasonable rates.`,

  OUR_STORY_TOGETHER_BENEFITS_HEADER: "Benefits of Choosing Us",
  OUR_STORY_TOGETHER_BENEFITS_SUBHEADER:
    "OutreachMantra offers a variety of services to its customers, if you are new to the platform, below is an overview of what you can expect from us.",

  OUR_STORY_BENEFITS_CARDS: [
    {
      title: "Don’t waste time waiting for a reply.",
      description:
        "You don't have to sit back and wait for days to get a reply from web admins, negotiate or worry about topic approval and payments. Join our platform, select sites based on their metrics, and leave the rest on us :).",
    },
    {
      title: "Huge database, and economical pricing",
      description:
        "We offer you a huge database of niche relevant and quality websites. Unlike others, we charge a genuine price for guest posts, link insertions, and content creation, so you can order without having to worry about your budget. ",
    },
    {
      title: "Passive earning source for webmasters.",
      description:
        "If you are a webmaster, join us, and start accepting quality guest posts on your websites, that are reader-friendly and add value to your blog. Add your site to OutreachMantra, and start receiving orders.",
    },
    {
      title: "Fulfill your content requirements",
      description:
        "Tired of searching for a reliable wordsmith? Our team of in-house writers is expert in writing, nitpicking grammatical mistakes, and doing research that completes the reader’s search intent.",
    },
  ],

  OUR_STORY_VISION_STATEMENT_HEADER: "VISION STATEMENT",
  OUR_STORY_VISION_STATEMENT_SUBHEADER:
    "Team OutreachMantra’s main motto is to help marketers focus on an integral part of their marketing strategy and save a ton of time. We help fill the gap between publishers and advertisers, benefitting both, and that too, with just one platform.",
  OUR_STORY_VISION_STATEMENT_DESCRIPTION:
    "OutreachMantra saves your time by reaching out to webmasters all over the world, and bring them to our platform. <br/> This helps both, webmaster, and advertiser, as the publisher can generate additional revenue, and advertiser can promote their website.",

  // Static Pages (Advertisers)
  SOLUTIONS_ADVERTISER_HERO_HEADER:
    "Grow Your Business with Premium Guest Post Services",

  SOLUTIONS_ADVERTISER_TOOLS_HEADER: "WITH OUR TOOLS",
  SOLUTIONS_ADVERTISER_TOOLS_SUBHEADER:
    "Connect with publishers & reach out to a larger audience",

  SOLUTIONS_ADVERTISER_TOOLS_CARDS: [
    {
      title: "Filters",
      description:
        "With advanced filters & sorting, you can choose publishers that meet your requirements. There are a handful of parameters to choose from, such as DA, traffic, Price, niche etc.",
    },
    {
      title: "Extensive database",
      description:
        "At OutreachMantra, our primary focus is to keep our database huge & updated. We’ve got an extensive database of sites in various niches, be it, technology, automotive, finance, health, pet, marketing sites, and so on.",
    },
    {
      title: "Easy payments",
      description:
        "We provide various payment methods for you to choose from so that you only focus on choosing relevant publishers and stay free from the worries of finding a compatible credit card.",
    },
    {
      title: "Get notified",
      description:
        "Any progress that your order makes gets instantly updated in your dashboard. We also send order updates right in your inbox so that you can stay updated with your order status.",
    },
  ],

  SOLUTIONS_ADVERTISER_TOOLS_POINTERS_SECTIONS: [
    {
      title: "Huge community of publishers, at one place.",
      description:
        "OutreachMantra is connected with INSERT_KEYWORD_HERE websites. Our experts manually verify each site by closely observing its traffic, ranking, domain metrics & content quality. <br/><br/> Publishers at OutreachMantra are always open to contextual linking, sponsored posting, and Press releases.",
    },
    {
      title: "Target the right audience",
      description:
        "We understand the importance of niche relevant link building tactics and how effective they would be for higher rankings. That’s why we have added various filters that help you to choose the best for you.",
    },
    {
      title: "Managing things is Super easy",
      description:
        "The user dashboard gets updated as soon as there’s an update from the publisher. This keeps you notified of even the tiniest activity of your order.",
    },
  ],

  SOLUTIONS_ADVERTISER_TOOLS_POINTER_2_ICON_CATEGORY: "Category",
  SOLUTIONS_ADVERTISER_TOOLS_POINTER_2_ICON_TRAFFIC_RANGE: "Traffic Range",
  SOLUTIONS_ADVERTISER_TOOLS_POINTER_2_ICON_DOMAIN_AUTHORITY:
    "Domain Authority",
  SOLUTIONS_ADVERTISER_TOOLS_POINTER_2_ICON_SORT_BY: "Sort By",
  SOLUTIONS_ADVERTISER_TOOLS_POINTER_2_ICON_PRICE_RANGE: "Price Range",

  SOLUTIONS_ADVERTISER_INSTANT_ACCESS_HEADER: "INSTANT ACCESS",
  SOLUTIONS_ADVERTISER_INSTANT_ACCESS_SUBHEADER:
    "Instantly Connect With Publishers Globally",
  SOLUTIONS_ADVERTISER_INSTANT_ACCESS_DESCRIPTION:
    "OutreachMantra does the outreach, deal negotiations on your behalf so that you can focus on the other essential aspects of your business.",

  // Static Pages (Publishers)
  SOLUTIONS_PUBLISHER_PAYPAL: "PayPal",
  SOLUTIONS_PUBLISHER_BANK_TRANSFER: "Bank Transfer",

  SOLUTIONS_PUBLISHER_HERO_HEADER:
    "Monetize your blog by publishing sponsored content",
  SOLUTIONS_PUBLISHER_HERO_DESCRIPTION:
    "OutreachMantra provides publishers with an effective way of monetizing a blog by accepting sponsored posts. Publish content & get paid.",

  SOLUTIONS_PUBLISHER_EASY_TO_WORK_HEADER: "EASY TO WORK",
  SOLUTIONS_PUBLISHER_EASY_TO_WORK_SUBHEADER:
    "You only need to take 4 steps to access more than 150 customers that we have in our database",
  SOLUTIONS_PUBLISHER_EASY_TO_WORK_DESCRIPTION:
    "and the numbers are increasing every day. Register for free and start to earn more by publishing content on your websites. It only takes 1 minute.",

  SOLUTIONS_PUBLISHER_EASY_TO_WORK_NUMERIC_POINTERS: [
    {
      header: "01",
      helperText: "Sign",
      helperText2: "Up",
    },
    {
      header: "02",
      helperText: "Get",
      helperText2: "Orders",
    },
    {
      header: "03",
      helperText: "Complete",
      helperText2: "the orders",
    },
    {
      header: "04",
      helperText: "Get",
      helperText2: "Paid",
    },
  ],

  SOLUTIONS_PUBLISHER_PERKS_HEADER: "Perks of Joining OutreachMantra",
  SOLUTIONS_PUBLISHER_PERKS_CARDS: [
    {
      // icon: <FiRss size={50} style={cardIconStyle} />,
      // circleColor: Colors.lightGreen,
      title: "Higher conversions",
      description:
        "Advertisers at OutreachMantra are actively looking for quality content sites. By listing your site on OutreachMantra, your site gets noticed by thousands of advertisers around the world.",
    },
    {
      // icon: <FiTrendingUp size={50} style={cardIconStyle} />,
      // circleColor: Colors.lightBlue,
      title: "0 Investments",
      description:
        "Since we do not charge you a fee for joining our platform, you don’t have to worry about paying us to get listed or to stay listed.",
    },
    {
      // icon: <FiUsers size={50} style={cardIconStyle} />,
      // circleColor: Colors.secondaryColor,
      title: "Timely Payouts",
      description:
        "Our finance team takes care of your payments. The dues are cleared every Tuesday of the week.",
    },
    {
      // icon: <FiHeadphones size={50} style={cardIconStyle} />,
      // circleColor: Colors.hotPink,
      title: "24x7 Support",
      description:
        "If there’s anything bugging you, reach out to us on our email and talk to an expert.",
    },
  ],

  SOLUTIONS_PUBLISHER_WHY_POINTERS_SECTIONS: [
    {
      title: "Stay Updated With Order Status",
      description:
        "OutreachMantra keeps you updated with the order status of all websites that you have listed on the platform. <br/><br/> Check the real-time statistics of your websites, download the data, check orders status, and a lot more, in one place.",
    },
    {
      title: "Timely and Hassle-Free Payments",
      description:
        "Unlike any other affiliate or monetization network, our payment processing is quick. <br/><br/> We offer weekly and monthly payment requests, that can be amended according to your needs. <br/><br/> You can request payment either via Paypal or direct bank deposits, whichever you prefer.",
    },
    {
      title: "Manage, track, and export your order data",
      description:
        "Manage all your orders under a single dashboard and never spend hours on reporting and analytics every again.",
    },
  ],

  // Static Pages (Content Writing)
  SOLUTIONS_CONTENT_WRITING_HERO_HEADER:
    "Get data-rich, engaging, and specially curated content for your websites & blogs.",

  SOLUTIONS_CONTENT_WRITING_TOOLS_HEADER: "WITH OUR TOOLS",
  SOLUTIONS_CONTENT_WRITING_TOOLS_SUBHEADER:
    "Written by industry experts, we serve content that brings traffic and converts well",

  SOLUTIONS_CONTENT_WRITING_TOOLS_CARDS: [
    {
      title: "Expert Writers",
      description:
        "Our team of well-versed writers focuses on creating quality, data-rich, and research-oriented content that performs well on SERP. The content you receive passes several quality checks, such as grammatical checks, plagiarism checks, and fact checks.",
    },
    {
      title: "Data-Rich content",
      description:
        "People love reading content that’s well researched and fulfills their needs. The writers at Outreachmantra collect information from various sources while writing content so that your audience remains satisfied.",
    },
    {
      title: "Increase Engagement",
      description:
        "Bounce rate plays a vital role in determining the quality of your content. With advanced techniques of research and the right choice of words, we deliver engaging content that your audience will love reading.",
    },
    {
      title: "Stay Updated with your Order",
      description:
        "Once you are done sending your content requirements, we forward the details to our writers and update you for even the tiniest activity of your order.",
    },
  ],

  SOLUTIONS_CONTENT_WRITING_ORDER_HEADER:
    "Customize Your Order, Send Us Your Requirements",
  SOLUTIONS_CONTENT_WRITING_ORDER_DESCRIPTION:
    "Order customized content, and get the work done.",
  SOLUTIONS_CONTENT_WRITING_ORDER_ICON_KEYWORDS: "Keywords",
  SOLUTIONS_CONTENT_WRITING_ORDER_ICON_TARGET_URLS: "Target URLs",
  SOLUTIONS_CONTENT_WRITING_ORDER_ICON_WORD_COUNTS: "Word Counts",
  SOLUTIONS_CONTENT_WRITING_ORDER_ICON_WRITING_OPTIONS: "Writing Options",
  SOLUTIONS_CONTENT_WRITING_ORDER_ICON_SPECIAL_NOTES: "Special Notes",

  // Static Pages (Contact Us)
  CONTACT_US_SKYPE_ID: "live:.cid.8618d16e3a52ef77",
  CONTACT_US_ADDRESS: "New Delhi, India",
  CONTACT_US_ADDRESS_USA:
    "447 Broadway, 2nd Floor Suite #1275, New York, 10013, USA",

  CONTACT_US_HERO_SECTION_HEADER:
    "Need any assistance? Get in touch with an expert",
  CONTACT_US_HERO_SECTION_SUBHEADER:
    "Planning to take off your blog to new heights but stuck up somewhere? Drop an email or chat with us right here.",

  CONTACT_US_FAQ_HEADER: "Frequently Asked Questions",
  CONTACT_US_FAQ_SUBHEADER:
    "Hey, don’t forget to check out our FAQ section, specially written for you. Most of your questions have an answer here.",

  CONTACT_US_PUBLISHERS_DESCRIPTION: "Check FAQs asked by other users",
  CONTACT_US_ADVERTISERS_DESCRIPTION: "Check FAQs asked by other users",
  CONTACT_US_CONTENT_WRITING_DESCRIPTION: "Check FAQs asked by other users",

  CONTACT_US_FORM_HEADER: "Still Have a Question",
  CONTACT_US_FORM_SUBHEADER:
    "Please fill in the details below, to get in touch with us",

  // Check Mail
  CHECK_MAIL_HEADER: "Check your Mailbox",
  CHECK_MAIL_GO_HOME_CTA: "Go Home",
  CHECK_MAIL_RESET_PASSWORD_TEXT:
    "A password reset link has been sent to your e-mail address.",
  CHECK_MAIL_CHANGE_PASSWORD_TEXT:
    "A change password link has been sent to your e-mail address.",
  CHECK_MAIL_VERIFY_EMAIL_TEXT:
    "An email has been sent with a link to activate your account",

  // Static Pages (FAQ)
  FAQ_HEADER: "Have a",
  FAQ_HEADER_HIGHLIGHTED: "question?",
  FAQ_SUBHEADER: "We have",
  FAQ_SUBHEADER_HIGHLIGHTED: "answers!",
  FAQ_DESCRIPTION:
    "Ready to unleash the potential of your marketing teams and scale your business on the Internet? Get in touch with us today!",

  FAQS_INFO_HEADER: "FAQs",
  FAQS_INFO_SUBHEADER: "Browse all",
  FAQS_INFO_SUBHEADER_HIGHLIGHTED: "topics",

  ADVERTISER_FAQS_CONTENT: [
    {
      title: "How do I get started?",
      content:
        "Joining OutreachMantra is an easy process. <ol> <li>Visit <a class='text-theme' href='https://outreachmantra.com' target='_blank' >https://outreachmantra.com</a>.</li> <li>Click on CREATE A FREE ACCOUNT</a> button.</li> <li>You can signup using Google, Facebook account, or by simply entering your first name, last name, and email.</li> <li>Once done, you will receive an email in your inbox. Note: Please check the spam folder in case you fail to find the email in the primary inbox and promotions tab. Don’t forget to report the email as “not spam” :).</li> <li>Click on verify account button, and create a password.</li> </ol>",
    },
    {
      title: "How do I select a website?",
      content:
        "OutreachMantra offers a wide range of websites in various niches. Since our database consists of thousands of sites, here’s how to pick your desirable site. <ol> <li>Login to your OutreachMantra dashboard.</li> <li>Select <a class='text-theme' href='https://outreachmantra.com/advertiser/marketplace' target='_blank' >marketplace</a> option (left sidebar).</li> <li>You can now explore our vast marketplace.</li> <li>Use filters to find out sites according to your requirements. </li> <li>To make things easier, we let you create and save filters so that the next time you come back, you don’t have to repeat the same process.</li> </ol>  ",
    },
    {
      title: "How do I place an order?",
      content:
        "Find your ideal website for guest posting? <ol> <li>Click on “Add to cart” or “Direct Checkout”.</li> <li>Select content type <ul> <li>Provide content - The guest post content is provided by you. Click on choose file and submit the files in .doc, .docx, .txt, .pdf File format. <ul><li>Type instructions, or select the general instructions from suggestions</li></ul></li> <li>Hire content writer (recommended) - If you don’t have time to write content, select this option. <ul><li>Provide a title, word count, choose a writing style, keywords, target URL, and instructions for the content. </li></ul></li> </ul> </li> <li>Click on checkout, and that’s it. </li> <li>If your wallet has balance, the charges will be deducted from it, otherwise, you will have to add the funds in the wallet for placing an order</li>  </ol>",
    },
    {
      title: "What does my order status mean?",
      content:
        "After placing an order, it goes through multiple stages, here’s a brief explanation for it.<ol> <li> <strong >Pending</strong>: Your order has been forwarded to the publisher.</li> <li>\n" +
        "      <strong>Progress</strong>: Your order has been accepted by the publisher.\n" +
        "    </li>\n" +
        "    <li>\n" +
        "      <strong>Delivered</strong>: The article is published on the desired\n" +
        "      website.\n" +
        "    </li>\n" +
        "    <li>\n" +
        "      <strong>Completed</strong>:The order has been verified by advertiser and\n" +
        "      is marked as completed.\n" +
        "    </li>\n" +
        "    <li>\n" +
        "      <strong>Revision</strong>: You have requested a revision for anchor, link,\n" +
        "      or content for the order.\n" +
        "    </li>\n" +
        "    <li>\n" +
        "      <strong>Cancelled</strong>: The order has been canceled by our team\n" +
        "      because of no reply from the webmaster.\n" +
        "    </li>\n" +
        "    <li><strong>Rejected</strong>: The order was rejected by the publisher.</li>\n" +
        "    <li>\n" +
        "      <strong>Content Pending</strong>: When the article is being written by our\n" +
        "      in-house writer, If you have selected “Hire a writer” while placing an\n" +
        "      order.\n" +
        "    </li> </ol> ",
    },
    {
      title: "How much time does it take for an order to complete?",
      content:
        "The TAT (turn around time) is usually 3 to 4 days. However, it depends upon the webmasters and their publishing schedule.",
    },
    {
      title: "Do you provide refunds upon cancellation of an order?",
      content:
        "A webmaster has the full right to reject a pending order. At the time of cancellation, the webmaster will provide the reasons, which will be forwarded to you. The fees paid by you for the order will be fully refunded in your wallet. For more details, please check our refund policy. ",
    },
    {
      title:
        "Will I get a refund if the webmaster removes my link after some time?",
      content:
        "Although we work with trusted webmasters, if any of them removes your link, we will first try to reinstate the link, and if we fail, we will issue a refund or provide a replacement. <br/><br/> We review publishers often and remove the bad publishers from our database as we believe links are supposed to be live forever.\n",
    },
    {
      title: "Do I have to provide content?",
      content:
        "OutreachMantra has a team of expert in-house writers who are experienced in writing engaging and data-rich content. You just have to place the order and wait till it's completed. However, if you want to, you can provide self-written content",
    },
    {
      title: "Can I withdraw my wallet balance to my account?",
      content:
        "Although, you cannot withdraw your advertiser’s wallet balance to your bank or PayPal account. <br/> But if there’s an urgency, reach out to us at <a class='text-theme' href='mailto:SUPPORT_MAIL' >SUPPORT_MAIL</a> for resolution.",
    },
    {
      title: "My question is not listed here, can we speak?",
      content:
        "Sure, please drop us an email to <a class='text-theme' href='mailto:SUPPORT_MAIL' >SUPPORT_MAIL</a> or use live chat support for faster communication.",
    },
  ],

  PUBLISHER_FAQS_CONTENT: [
    {
      title: "How to list a blog on OutreachMantra?",
      content:
        "Publishers play a major role in building this platform, hence we have kept the blog listing process as simple as we could. <br/> To get started: <ol>\n" +
        "  <li    > Signup on OutreachMantra by clicking on <a class='text-theme' href='https://outreachmantra.com/signup' target='_blank' >create an account</a>.</li>\n" +
        "  <li    > Confirm your account by clicking on the verification link you receive in your email.</li>\n" +
        "  <li    > Set up a password, and visit the dashboard.</li>\n" +
        "  <li    > You can access advertiser and publisher features from one account itself. To toggle between these two, click on the toggle button beside your profile picture.</li>\n" +
        "  <li    > Choose publisher, and click on <a class='text-theme' href= 'https://outreachmantra.com/publisher/add-website' target= '_blank'>add website</a> button.</li>\n" +
        "  <li    > Now enter the necessary details, and verify your site.</li>\n" +
        "  <li    > Your website will then be forwarded to our team for review.</li>\n" +
        "  <li    > Once website approved/rejected, we will email your further details. </li>\n" +
        "  </ol>",
    },
    {
      title: "What are the blog selection criteria for OutreachMantra?",
      content:
        "OutreachMantra focuses on quality more than quality. We prefer blogs with the following statistics. <br /> <li>500 unique visitors a month</li><li>Moz Domain Authority of 20+</li><li>The blog must publish quality content</li><li>The blog should not be a Private Blogging Network (PBN).</li>",
    },
    {
      title: "Can I submit more than 1 blog?",
      content:
        "You can submit more than 100 websites under one publisher account on OutreachMantra. However, only those blogs will be approved that pass the quality check by our SEO experts.<br/>To submit website: <ol>\n" +
        "  <li>Visit your dashboard</li>\n" +
        "  <li>Select my websites.</li>\n" +
        "  <li>Click on <a class='text-theme' href= 'https://outreachmantra.com/publisher/add-website' target= '_blank'>add website</a> and repeat the same steps that you performed while adding your first website.</li>\n" +
        "</ol> ",
    },
    {
      title: "How much can I charge for one sponsored post?",
      content:
        "It depends upon the statistics of your site. You can set the price according to your calculations, but we recommend keeping minimum prices to attract more advertisers.",
    },

    {
      title: "What does the order status mean?",
      content:
        "Whenever you receive an order, it is labeled according to its status. Here’s a brief of the order status: <ol>\n" +
        "  <li ><b>Pending</b>: Order received, and unprocessed by you. </li>\n" +
        "  <li ><b>Progress</b>: The status will change to progress once you have accepted the order </li>\n" +
        "  <li ><b>Delivered</b>: Once you submit the live link, the status will change to deliver. </li>\n" +
        "  <li ><b>Completed</b>: When the advertiser approves the link, it will be marked as completed. </li>\n" +
        "  <li ><b>Canceled</b>: Order canceled by OutreachMantra because the publisher didn&rsquo;t respond to emails. </li>\n" +
        "  <li ><b>Rejected</b>: Order rejected by Publisher due to specific reasons. </li>\n" +
        "  <li ><b>Revision</b>: Advertiser has requested a revision for the order. </li>\n" +
        "  </ol>\n",
    },
    {
      title: "When do I get paid?",
      content: "We release payment every Tuesday, i.e., 4 times a month.",
    },
    {
      title: "How do I get paid?",
      content:
        "The withdrawal method is Paypal and bank transfer. For Indian publishers, we only provide bank transfers. The payments are automatically processed every Tuesday.",
    },
    {
      title:
        "Can I buy purchase sponsored posts on OutreachMantra using my earnings?",
      content:
        "Unfortunately, you can’t use your publisher wallet balance to buy guest posts or link insertions.",
    },
    {
      title: "Can I reject orders?",
      content:
        "Yes, it is not necessary to accept every order you receive. We recommend approving orders that comply with your <a class='text-theme' href='https://blog.outreachmantra.com/add-website-to-outreachmatra/#Criteria_for_Getting_Site_approval_on_OutreachMantra' target='_blank'>blog’s guidelines</a>.",
    },
    {
      title:
        "Why do I need to accept the Google consent form while adding my website?",
      content:
        "Google consent form is required because it prevents third-party resellers from adding the website to OutreachMantra’s database without the permission of the owner.<br><br>This helps us to identify the true owner of the web property. Your personal data, and privacy, are neither collected nor compromised while accepting the consent form.<br><br>Learn more here - <a class='text-theme' href='https://developers.google.com/site-verification' target='_blank'>https://developers.google.com/site-verification</a>",
    },
    {
      title: "Why do I have to provide Google analytics permission?",
      content:
        "Google analytics permission is necessary so that OutreachMantra can display the correct traffic value of the publishers sites to the advertisers. You can either manually add the traffic value, or use Google analytic authentication. It’s completely safe.<br><br>Learn more here - <a class='text-theme' href='https://developers.google.com/analytics/devguides/reporting/core/v4' target='_blank'>https://developers.google.com/analytics/devguides/reporting/core/v4</a>",
    },
    {
      title: "My question is not listed here, can we speak?",
      content:
        "Sure, please drop us an email to <a class='text-theme' href='mailto:SUPPORT_MAIL' >SUPPORT_MAIL</a> or use live chat support for faster communication.",
    },
  ],

  CONTENT_WRITING_FAQS_CONTENT: [
    {
      title: "Who writes the content?",
      content:
        "OutreachMantra has onboarded a team of excellent and experienced writers who are in this industry for a significant period of time. Your content requirements are shared with the available writers and fulfilled within time limits without affecting the quality.",
    },
    {
      title:
        "Do you offer content writing services for languages other than English?",
      content:
        "Sorry, we can only offer content writing services in the English language.",
    },
    {
      title: "Is the content SEO optimized?",
      content:
        "Yes, our writers are well versed with on-page SEO, and along with this, your content will be thoroughly checked by our eagle-eyed editor to rectify grammatical mistakes and fact-check the content. ",
    },
    {
      title: "How long does it take to deliver the content?",
      content:
        "The delivery time depends upon your requirements. If the content requires too much research, we may take 48-72 hours to fulfill the delivery.",
    },
    {
      title: "Do you provide revisions?",
      content:
        "Our team thoroughly checks the content before delivering, but if there’s anything that we missed out on, you can request a free revision.",
    },
    {
      title: "Can I request a custom order?",
      content:
        "Every order is treated as a unique order. You can go to your advertiser dashboard, click on <a class='text-theme' href='https://outreachmantra.com/advertiser/content-writing' target='_blank'>content writing</a>, and specify your content requirements. Once your order is successfully placed, we forward it to our editor who takes care of the further process. \n" +
        "For bulk orders, you can reach out to us at <a class='text-theme' href='mailto:SUPPORT_MAIL' >SUPPORT_MAIL</a>",
    },
    {
      title: "My question is not listed here, can we speak?",
      content:
        "Sure, please drop us an email to <a class='text-theme' href='mailto:SUPPORT_MAIL' >SUPPORT_MAIL</a> or use live chat support for faster communication.",
    },
  ],

  // Static Pages (Refund Policy)
  REFUND_POLICY_CONTENT: [
    {
      title: "Refund Policy",
      content:
        "OutreachMantra’s automated system automatically refunds your purchase credits/money if an order gets rejected. The refund is directly credited to your OutreachMantra wallet.",
    },
    {
      title: "Advertiser:",
    },
    {
      title: "In what cases are you liable to receive a refund?",
      content:
        "An advertiser is liable to receive a refund after deducting portal fees and processing fees only on certain conditions, such as: <ol><li>The publisher has removed your link.</li> <li>The publisher has changed the link tags from Dofollow to Nofollow, sponsored, or UGC</li> <li>Your order could not be completed after multiple attempts.</li> </ol> ",
    },
    {
      title: "How long does it take to get a refund credited back?",
      content:
        "It usually takes 5 to 7 working/business days for a refund to reflect back in your wallet. We send a “REFUND PROCESSED” email as soon as the process has started from our end. However, the actual time for receiving the money in your bank account depends on PayPal and your banking partner.",
    },
    {
      title: "When Payment fails while adding funds to wallet from Paypal",
      content:
        "While adding funds to the wallet, if the transaction fails and the money is debited from your bank account, we will cross-check the accounting books (Paypal dashboard)  from our end and update the wallet balance if the money has reached us.In case we have not received the money, and it is debited from your bank within 5 working days, we are not responsible, and you should reach out to your bank or Paypal support.",
    },
  ],

  // Static Pages (Privacy Policy)
  PRIVACY_POLICY_CONTENT: [
    {
      title: "Privacy Policy for OutreachMantra",
      content:
        "At OutreachMantra, accessible from https://outreachmantra.com/, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by OutreachMantra and how we use it. <br/><br/> If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us. <br/><br/> This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collected in OutreachMantra. This policy is not applicable to any information collected offline or via channels other than this website. Our Privacy Policy was created with the help of the Privacy Policy Generator.",
    },
    {
      title: "Consent",
      content:
        "By using our website, you hereby consent to our Privacy Policy and agree to its terms.",
    },
    {
      title: "Information we collect",
      content:
        "The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information. <br/><br/> If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide. <br/> <br/> When you register for an Account, we may ask for your contact information, including items such as name, email address, and telephone number.",
    },
    {
      title: "How we use your information",
      content:
        "<p>We use the information we collect in various ways, including to:</p><ul><li>Provide, operate, and maintain our website</li><li>Improve, personalize, and expand our website</li><li>Understand and analyze how you use our website</li><li>Develop new products, services, features, and functionality</li><li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li><li>Send you emails</li><li>Find and prevent fraud</li></ul>",
    },
    {
      title: "Log Files",
      content:
        "OutreachMantra follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this as part of hosting services",
    },
    {
      title: "Cookies and Web Beacons",
      content:
        "Like any other website, OutreachMantra uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information. <br/><br/> For more general information on cookies, please read What Are Cookies.",
    },
    {
      title: "Advertising Partners Privacy Policies",
      content:
        "You may consult this list to find the Privacy Policy for each of the advertising partners of OutreachMantra. <br/><br/> Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on OutreachMantra, which are sent directly to users' browsers. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit. <br/><br/> Note that OutreachMantra has no access to or control over these cookies that are used by third-party advertisers.",
    },
    {
      title: "Third Party Privacy Policies",
      content:
        "OutreachMantra's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. <br/><br/> You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.",
    },
    {
      title: "CCPA Privacy Rights (Do Not Sell My Personal Information)",
      content:
        "<p>Under the CCPA, among other rights, California consumers have the right to: </p> <ul><li>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</li> <li>Request that a business delete any personal data about the consumer that a business has collected.</li> <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li> <li>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</li> </ul>",
    },
    {
      title: "GDPR Data Protection Rights",
      content:
        "<p> We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following: </p> <ul> <li> The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service. </li> <li> The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete. </li> <li> The right to erasure – You have the right to request that we erase your personal data, under certain conditions. </li> <li> The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions. </li> <li> The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions. </li> <li> The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions. </li> <li> If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us. </li> </ul>",
    },
    {
      title: "Children's Information",
      content:
        "Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity. <br/><br/> OutreachMantra does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.",
    },
  ],

  // Static Pages (Terms and Conditions)
  TERMS_AND_CONDITIONS_CONTENT: [
    {
      title: "Terms and Conditions",
      content: `Welcome to OutreachMantra! <br /> <br /> These terms and conditions outline the rules and regulations for the use of OutreachMantra's Website, located at <a href="https://outreachmantra.com">https://outreachmantra.com</a>. <br /> <br /> OutreachMantra is a link building and content creation services venture. We provide our services and campaigns after we receive the full fee. <br/> <br/> As a client or service partner, you shall consent to the following terms and conditions of the services offered by Outreach monks.`,
    },
    {
      title: "OutreachMantra Services ",
      content: `OutreachMantra will perform the following services in SEO, depending upon the subscription: <ol>
<li>Create visual and written content like blogs and articles</li>
<li>Generate placement and submission reports. </li>
<li>Propagate link popularity by obtaining backlinks from other websites. </li>
<li>Fetch potential bloggers that shall publish content promoting the customer’s brand.</li>
</ol> <br/> By accessing this website we assume you accept these terms and conditions. Do not continue to use OutreachMantra if you do not agree to take all of the terms and conditions stated on this page. <br/> <br/> The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of India. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to the same.`,
    },
    {
      title:
        "IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT ENTER TO, CONNECT TO, ACCESS OR USE THE SITE AND/OR THE SERVICE.",
      content: ``,
    },
    {
      title: "The Service",
      content: `Our site available at https://outreachmantra.com/ provides you with information regarding OutreachMantra and our services. The site includes a link to the platform's log-in page. Our Platform is available to users who have opened an Account (as further detailed below) and will be made available to Users through a cloud-based dashboard (the "Dashboard"). 
<br/> The process is as follows:
<ol><li>
Buyer places an order, which includes certain criteria pertaining to potential Posts, based on Dashboard's filters (such as the requested Properties, the Seller, the topic of the potential Post and etc.) (the "Order").
</li>
<li>The Order will be sent to the applicable Seller, for his approval. If the Seller is unable to provide the required services in accordance with the terms stipulated in the Order, the Order will be declined and the fees will be refunded to the Buyer's OutreachMantra Account. </li>
<li>Upon the successful publication of the Post, a notice of publication will be issued to the Buyer, ratifying the publication of the Post, in accordance with the terms agreed in the Order ("Notice of Publication").  </li>
</ol> 
`,
    },
    {
      title: "Cookies",
      content: `We employ the use of cookies. By accessing OutreachMantra, you
    agreed to use cookies in agreement with OutreachMantra's Privacy
    Policy. <br />
    <br /> Most interactive websites use cookies to let us retrieve the
    user’s details for each visit. Our website uses cookies to enable
    the functionality of certain areas to make it easier for people
    visiting our website. Some of our affiliate/advertising partners may
    also use cookies.`,
    },
    {
      title: "License",
      content: ` Unless otherwise stated, OutreachMantra and/or its licensors own the
    intellectual property rights for all material on OutreachMantra. All
    intellectual property rights are reserved. You may access this from
    OutreachMantra for your own personal use subjected to restrictions
    set in these terms and conditions. <br />
    <br /> You must not: <br />
    <li>Republish material from OutreachMantra</li>
    <li>Sell, rent, or sub-license material from OutreachMantra</li>
    <li>Reproduce, duplicate or copy material from OutreachMantra</li>
    <li>Redistribute content from OutreachMantra</li>
    This Agreement shall begin on the date hereof. Our Terms and
    Conditions were created with the help of the Terms And Conditions
    Template.
    <br />
    <br />
    Parts of this website offer an opportunity for users to post and
    exchange opinions and information in certain areas of the website.
    OutreachMantra does not filter, edit, publish or review Comments
    prior to their presence on the website. Comments do not reflect the
    views and opinions of OutreachMantra, its agents, and/or affiliates.
    Comments reflect the views and opinions of the person who posts
    their views and opinions. To the extent permitted by applicable
    laws, OutreachMantra shall not be liable for the Comments or for any
    liability, damages, or expenses caused and/or suffered as a result
    of any use of and/or posting of and/or appearance of the Comments on
    this website.
    <br />
    <br />
    OutreachMantra reserves the right to monitor all Comments and remove
    any Comments that can be considered inappropriate, offensive, or
    cause a breach of these Terms and Conditions.
    <br />
    <br />
    You warrant and represent that: <br />
    <li>
      You are entitled to post the Comments on our website and have all
      necessary licenses and consents to do so;
    </li>
    <li>
      The Comments do not invade any intellectual property right,
      including without limitation copyright, patent, or trademark of
      any third party;
    </li>
    <li>
      The Comments do not contain any defamatory, libelous, offensive,
      indecent or otherwise unlawful material which is an invasion of
      privacy
    </li>
    <li>
      The Comments will not be used to solicit or promote business or
      custom or present commercial activities or unlawful activity.
    </li>
    <br />
    <br />
    You hereby grant OutreachMantra a non-exclusive license to use,
    reproduce, edit and authorize others to use, reproduce and edit any
    of your Comments in any and all forms, formats, or media.`,
    },
    {
      title: "Hyperlinking to our Content",
      content: `The following organizations may link to our Website without prior
    written approval: <br />
    <li>Government agencies;</li>
    <li>Search engines;</li>
    <li>News organizations;</li>
    <li>
      Online directory distributors may link to our Website in the same
      manner as they hyperlink to the Websites of other listed
      businesses; and
    </li>
    <li>
      System-wide Accredited Businesses except soliciting non-profit
      organizations, charity shopping malls, and charity fundraising
      groups which may not hyperlink to our Web site.
    </li>
    These organizations may link to our home page, to publications or to
    other Website information so long as the link: (a) is not in any way
    deceptive; (b) does not falsely imply sponsorship, endorsement or
    approval of the linking party and its products and/or services; and
    (c) fits within the context of the linking party’s site.
    <br />
    <br />
    We may consider and approve other link requests from the following
    types of organizations:
    <li>
      commonly-known consumer and/or business information sources;
    </li>
    <li>dot.com community sites;</li>
    <li>associations or other groups representing charities;</li>
    <li>online directory distributors;</li>
    <li>internet portals;</li>
    <li>accounting, law, and consulting firms; and</li>
    <li>educational institutions and trade associations.</li>
    <br />
    We will approve link requests from these organizations if we decide
    that: (a) the link would not make us look unfavorably to ourselves
    or to our accredited businesses; (b) the organization does not have
    any negative records with us; (c) the benefit to us from the
    visibility of the hyperlink compensates the absence of
    OutreachMantra, and (d) the link is in the context of general
    resource information.
    <br />
    <br />
    These organizations may link to our home page so long as the link:
    (a) is not in any way deceptive; (b) does not falsely imply
    sponsorship, endorsement or approval of the linking party and its
    products or services; and (c) fits within the context of the linking
    party’s site.
    <br /> <br />
    If you are one of the organizations listed in paragraph 2 above and
    are interested in linking to our website, you must inform us by
    sending an e-mail to OutreachMantra. Please include your name, your
    organization name, contact information as well as the URL of your
    site, a list of any URLs from which you intend to link to our
    Website, and a list of the URLs on our site to which you would like
    to link. Wait 2-3 weeks for a response.
    <br /> <br />
    Approved organizations may hyperlink to our Website as follows:
    <br />
    <li>By use of our corporate name; or</li>
    <li>By use of the uniform resource locator being linked to; or</li>
    <li>
      By use of any other description of our Website being linked to
      that makes sense within the context and format of content on the
      linking party’s site.
    </li>
    No use of OutreachMantra's logo or other artwork will be allowed for
    linking absent a trademark license agreement.`,
    },
    {
      title: "iFrames",
      content: `Without prior approval and written permission, you may not create
    frames around our Webpages that alter in any way the visual
    presentation or appearance of our Website.`,
    },
    {
      title: "Content Liability",
      content: ` We shall not be held responsible for any content that appears on
    your Website. You agree to protect and defend us against all claims
    that is rising on your Website. No link(s) should appear on any
    Website that may be interpreted as libelous, obscene, or criminal,
    or which infringes, otherwise violates, or advocates the
    infringement or other violation of, any third party rights.`,
    },
    { title: "Your Privacy", content: `Please read Privacy Policy` },
    {
      title: "Reservation of Rights",
      content: `We reserve the right to request that you remove all links or any
    particular link to our Website. You approve to immediately remove
    all links to our Website upon request. We also reserve the right to
    amend these terms and conditions and its linking policy at any time.
    By continuously linking to our Website, you agree to be bound to and
    follow these linking terms and conditions.`,
    },
    {
      title: "Removal of links from our website",
      content: `If you find any link on our Website that is offensive for any
    reason, you are free to contact and inform us at any moment. We will
    consider requests to remove links but we are not obligated to or so
    or to respond to you directly. <br/> We do not ensure that the information
    on this website is correct, we do not warrant its completeness or
    accuracy; nor do we promise to ensure that the website remains
    available or that the material on the website is kept up to date.`,
    },
    {
      title: "Disclaimer",
      content: `To the maximum extent permitted by applicable law, we exclude all
    representations, warranties, and conditions relating to our website
    and the use of this website. Nothing in this disclaimer will:
    <br />
    <li>
      limit or exclude our or your liability for death or personal
      injury;
    </li>
    <li>
      limit or exclude our or your liability for fraud or fraudulent
      misrepresentation;
    </li>
    <li>
      limit any of our or your liabilities in any way that is not
      permitted under applicable law; or
    </li>
    <li>
      exclude any of our or your liabilities that may not be excluded
      under applicable law.
    </li>
    <br />
    The limitations and prohibitions of liability set in this Section
    and elsewhere in this disclaimer: (a) are subject to the preceding
    paragraph; and (b) govern all liabilities arising under the
    disclaimer, including liabilities arising in contract, in tort and
    for breach of statutory duty.
    <br /> <br />
    As long as the website and the information and services on the
    website are provided free of charge, we will not be liable for any
    loss or damage of any nature.`,
    },
    {
      title: "In What cases is OutreachMantra not responsible for a refund?",
      content:
        "OutreachMantra is not responsible for a refund if the publisher sells the site after publishing the guest posts. However, we try to reinstate the link, but we do not take a guarantee of issuing refunds in such cases. <br/> <br/> If you used PayPal to add funds to OutreachMantra’s wallet, please refer to this page for more details regarding refund. <br/> <br/> Users who added funds using RazorPay gateway should refer to this page for more information related to refunds.<br/> <br/> Terms and conditions for Publishers <ul><li>The publisher is responsible for providing the correct payment address. If the address provided is incorrect, and the payment has been sent to it, the withdrawal is considered to be completed. OutreachMantra will not send the same payment again to the corrected address.</li><li>We may ask for the changes after completing the order.</li> <li>We may update the details added by you at the time of adding the website to our platform.</li></ul> <br/> Terms and conditions for Advertisers <ul><li>The order will be auto-approved if we don’t receive any response after <auto_approved_hours> hours.</li><li>Withdrawal of the amount from the wallet is a manual process.</li></ul> <br/> For more information, questions or notification of errors, please contact us at <a class='text-theme' href='mailto:SUPPORT_MAIL' _target='blank' rel='noreferrer'>SUPPORT_MAIL</a>",
    },
  ],

  // Data Deletion
  DATA_DELETION_HEADER: "Data Deletion Instructions",
  DATA_DELETION_CONTENT: `OutreachMantra is a platform for Advertisers & Publishers to connect. We do not save your personal data on our server. <br/><br/>
 
  But according to the Facebook Platform rules, we have to provide a User Data Deletion Callback URL or Data Deletion Instructions URL. <br/><br/>
   
  If you want to delete your activities for OutreachMantra App, you can remove your activities by the following instructions. <br/><br/>
   
  1. Go to Your Facebook Account’s Setting & Privacy. Click “Setting”. <br/>
  2. Then, go to “Apps and Websites” and you will see all of your Apps activities. <br/>
  3. Select the option box of OutreachMantra. <br/>
  4. Click the ” Remove” button. <br/>
  5. Congratulations , you have successfully removed your activities.
  `,

  // Auth Pages
  LOGIN_HEADER: "Login",
  LOGIN_DESCRIPTION: `Want to rank higher on 
  search engines? <br />
  <br />
  Login to your account 
  and surf among thousands
  of quality publishers.`,
  LOGIN_FACEBOOK_TEXT: "Sign in with Facebook",
  LOGIN_LOGIN_CTA: "LOGIN",
  LOGIN_FORGOT_PASSWORD_TEXT_CTA: "Forgot password?",

  REGISTER_HEADER_LINE_1: "Create an Account",
  REGISTER_TICK_POINTER_1: "Quality publishers",
  REGISTER_TICK_POINTER_2: "Niche relevant sites",
  REGISTER_TICK_POINTER_3: "Timely payouts",
  REGISTER_TICK_POINTER_4: "Fast Customer Support",
  REGISTER_TICK_POINTER_5: "Moderated Rates",
  REGISTER_SUPPORT_TEXT: "If you are facing some issue you can contact with - ",
  SIGNUP_FACEBOOK_TEXT: "Sign in with Facebook",
  SIGNUP_SIGNUP_CTA: "SIGN UP",
  SIGNUP_AGREE_TEXT: [
    "I agree to",
    "Terms & Services",
    "and",
    "Privacy Policy",
  ],

  // Forgot Password
  FORGOT_PASSWORD_HEADER: "Forgot Password?",
  FORGOT_PASSWORD_TEXT:
    "Enter your email address to receive your reset password link",
  FORGOT_PASSWORD_RESET_CTA: "RESET PASSWORD",

  // Change Password
  CHANGE_PASSWORD_HEADER: "Change Password",
  CHANGE_PASSWORD_TEXT:
    "Enter your email address to receive your reset password link",
  CHANGE_PASSWORD_RESET_CTA: "RESET PASSWORD",

  SET_PREFERENCE_HEADER: "You have to set your preference.",
  SET_PREFERENCE_DESCRIPTION:
    "Welcome to the world’s largest content and advertiser's community. There is only one step left. Please choose one of the service below in order to complete the registration process.",
  SET_PREFERENCE_NOTE:
    "(Note: you can switch this any time from your dashboard)",
  SET_PREFERENCE_SET_PREF_CTA: "Set preference",
  SET_PREFERENCE_LOGOUT_CTA: "Logout",

  FINISH_SETUP_HEADER: "You have successfully verified your email address",
  FINISH_SETUP_SUBHEADER:
    "Just one last step, create a strong password for your account in order to complete the registration process.",
  FINISH_SETUP_SUBMIT_CTA: "SUBMIT & ACTIVATE",
  FINISH_SETUP_VALIDATION_TEXT: [
    "Minimum 8 characters",
    "max 25 characters",
    "at least 1 special character(!@#$%^&*()_+)",
    "at least 1 digit",
  ],
  FINISH_SETUP_HEY_TEXT: "Hey",

  // Dashboard
  ADVERTISER_DASHBOARD_MARKETPLACE_CTA: "GO TO MARKETPLACE",

  DASHBOARD_ADVERTISER_CARD_TOTAL_ORDERS_TITLE: "Total Orders",
  DASHBOARD_ADVERTISER_CARD_PENDING_ORDERS_TITLE: "Pending Orders",
  DASHBOARD_ADVERTISER_CARD_COMPLETED_ORDERS_TITLE: "Completed Total Orders",
  DASHBOARD_ADVERTISER_CARD_TOTAL_AMOUNT_SPENT_TITLE: "Total Amount Spent",

  DASHBOARD_PUBLISHER_CARD_MY_WEBSITES_TITLE: "My Websites",
  DASHBOARD_PUBLISHER_CARD_TOTAL_ORDERS_TITLE: "Total Orders",
  DASHBOARD_PUBLISHER_CARD_PENDING_ORDERS_TITLE: "Pending Orders",
  DASHBOARD_PUBLISHER_CARD_COMPLETED_ORDERS_TITLE: "Completed Orders",
  DASHBOARD_PUBLISHER_CARD_PAYOUT_AMOUNT_TITLE: "Payout Amount In Progress",

  DASHBOARD_RECENT_ORDERS_HEADER_TEXT: "Recent Orders",
  DASHBOARD_RECENT_ORDERS_TABLE_COLUMN_ORDER_ID: "ORDER ID",
  DASHBOARD_RECENT_ORDERS_TABLE_COLUMN_ORDER_DATE_AND_TIME: "DATE & TIME",
  DASHBOARD_RECENT_ORDERS_TABLE_COLUMN_WEBSITE: "WEBSITE",
  DASHBOARD_RECENT_ORDERS_TABLE_COLUMN_PRICE: "PRICE",
  DASHBOARD_RECENT_ORDERS_TABLE_COLUMN_STATUS: "STATUS",
  DASHBOARD_RECENT_ORDERS_TABLE_COLUMN_ACTION: "ACTION",
  DASHBOARD_RECENT_ORDERS_VIEW_ALL_ORDERS_BUTTON_TEXT: "VIEW ALL ORDERS",

  // My Orders
  MY_ORDERS_TABLE_COLUMN_ORDER_ID: "ORDER ID",
  MY_INVOICE_TABLE_COLUMN_INVOICE_DATE: "DATE",
  MY_INVOICE_TABLE_COLUMN_INVOICE_MESSAGE: "MESSAGE",
  MY_INVOICE_TABLE_COLUMN_STATUS: "STATUS",
  MY_ORDERS_TABLE_COLUMN_ACTION: "ACTION",

  // Paypal Invoice
  MY_INVOICE_TABLE_COLUMN_INVOICE_ID: "Invoice Number",
  MY_ORDERS_TABLE_COLUMN_DATE_AND_TIME: "DATE & TIME",
  MY_ORDERS_TABLE_COLUMN_WEBSITE: "WEBSITE",
  MY_ORDERS_TABLE_COLUMN_PRICE: "PRICE",
  MY_ORDERS_TABLE_COLUMN_STATUS: "STATUS",

  // Orders Action Popup
  ORDER_ACTION_POPUP_ORDER_DETAILS_HEADER: "Order details are as follows:",
  ORDER_ACTION_POPUP_VIEW_DOWNLOAD_HEADER:
    "Content delivered. You can view or download the content.",

  // Content Writing
  CONTENT_WRITING_TABLE_COLUMN_ORDER_ID: "ORDER ID",
  CONTENT_WRITING_TABLE_COLUMN_DATE_AND_TIME: "DATE & TIME",
  CONTENT_WRITING_TABLE_COLUMN_WORDS: "WORDS",
  CONTENT_WRITING_TABLE_COLUMN_POST_TYPE: "POST TYPE",
  CONTENT_WRITING_TABLE_COLUMN_PRICE: "PRICE",
  CONTENT_WRITING_TABLE_COLUMN_STATUS: "STATUS",
  CONTENT_WRITING_TABLE_COLUMN_ACTION: "ACTION",

  // Order Content Writing
  ORDER_CONTENT_WRITING_TOTAL_PRICE: "Total Price",
  ORDER_CONTENT_WRITING_PLACE_ORDER: "PLACE ORDER",

  // Payments
  PAYMENTS_SHOW_PENDING_DETAILS_CTA: "SHOW PENDING DETAILS",
  PAYMENTS_PAYMENT_ONLY_OUTSIDE_INDIA_TEXT: "Only for outside indian users",
  PAYMENTS_PRIMARY: "Primary",
  PAYMENTS_BANK_ACCOUNT: "BANK ACCOUNT",

  // Billing & Funds and Payments (Common - Table, Transaction Details Side Modal)
  BILLING_AND_FUNDS_OR_PAYMENTS_TABLE_COLUMN_TXN_ID: "TXN ID",
  BILLING_AND_FUNDS_OR_PAYMENTS_TABLE_COLUMN_DATE: "DATE",
  BILLING_AND_FUNDS_OR_PAYMENTS_TABLE_COLUMN_MESSAGE: "MESSAGE",
  BILLING_AND_FUNDS_OR_PAYMENTS_TABLE_COLUMN_TXN_TYPE: "TXN TYPE",
  BILLING_AND_FUNDS_OR_PAYMENTS_TABLE_COLUMN_TXN_AMOUNT: "TXN AMOUNT",
  BILLING_AND_FUNDS_OR_PAYMENTS_TABLE_COLUMN_TXN_STATUS: "STATUS",

  TRANSACTION_DETAILS_SIDEMODAL_HEADER: "Transaction Details",
  TRANSACTION_DETAILS_SIDEMODAL_DATE_TEXT: "Date",
  TRANSACTION_DETAILS_SIDEMODAL_INFO_HEADER: "Transaction Details as follows:",
  TRANSACTION_DETAILS_SIDEMODAL_TRANSACTION_ID_TEXT: "Transaction Id:",
  TRANSACTION_DETAILS_SIDEMODAL_AMOUNT_TEXT: "Amount:",
  TRANSACTION_DETAILS_SIDEMODAL_TRANSACTION_TYPE_TEXT: "Transaction Type:",
  TRANSACTION_DETAILS_SIDEMODAL_STATUS_TEXT: "Status",

  TRANSACTION_DETAILS_SIDEMODAL_PAYOUT_HEADER: "Payout Order Details:",
  TRANSACTION_DETAILS_SIDEMODAL_PAYMENT_HEADER: "Payment Order Details:",

  TRANSACTION_DETAILS_SIDEMODAL_PAYPAL_PAYOUT_TYPE: "Payment Type:",
  TRANSACTION_DETAILS_SIDEMODAL_BANK_PAYMENT_CURRENCY_TYPE:
    "Payment Currency Type:",
  TRANSACTION_DETAILS_SIDEMODAL_PAYPAL_PAYMENT_ORDER_ID: "Payment Order Id:",
  TRANSACTION_DETAILS_SIDEMODAL_PAYPAL_PAYER_NAME: "Payer Name:",
  TRANSACTION_DETAILS_SIDEMODAL_PAYPAL_PAYER_EMAIL: "Payer Email:",
  TRANSACTION_DETAILS_SIDEMODAL_PAYPAL_STATUS: "Payment Status:",
  TRANSACTION_DETAILS_SIDEMODAL_PAYPAL_PAYMENT_AMOUNT: "Payment Amount:",

  TRANSACTION_DETAILS_SIDEMODAL_BANK_PAYOUT_TYPE: "Payout Type:",
  TRANSACTION_DETAILS_SIDEMODAL_BANK_PAYMENT_ORDER_ID: "Transaction Id:",
  TRANSACTION_DETAILS_SIDEMODAL_BANK_AMOUNT: "Payout Amount:",
  TRANSACTION_DETAILS_SIDEMODAL_BANK_TYPE: "Type:",
  TRANSACTION_DETAILS_SIDEMODAL_BANK_EXCHANGE_RATE: "Exchange Rate:",
  TRANSACTION_DETAILS_SIDEMODAL_BANK_CONVEYANCE_FEE: "Conveyance Fee:",
  TRANSACTION_DETAILS_SIDEMODAL_BANK_STATUS: "Payout Status:",
  TRANSACTION_DETAILS_SIDEMODAL_BANK_PAYOUT_CURRENCY_TYPE:
    "Payout Currency Type:",

  TRANSACTION_DETAILS_REASON_TEXT: "Reason:",
  TRANSACTION_DETAILS_QUERY_TEXT: "In case of any query",
  TRANSACTION_DETAILS_CONCERN_TEXT: " Concern raise to us",

  // Reports
  REPORTS_GRAPH_NUMBER_OF_ORDERS: "Number of Orders",
  REPORTS_GRAPH_AMOUNT_EARNED: "Amount Earned (in $)",
  REPORTS_GRAPH_EXPENSES: "Expenses (in $)",

  REPORTS_DOWNLOAD_REPORT_CTA: "Download Reports",
  REPORTS_CURRENT_DATA_3_MONTHS: "Current data is visible for 3 months",

  REPORTS_ORDER_GRAPH_HEADER: "MY ORDERS",

  REPORTS_ADVERTISER_ORDER_GRAPH_SUBHEADER: "(ORDERS AND EXPENSES)",
  REPORTS_ADVERTISER_CONTENT_WRITING_GRAPH_HEADER: "CONTENT WRITING",
  REPORTS_ADVERTISER_CONTENT_WRITING_GRAPH_SUBHEADER: "(ORDERS AND EXPENSES)",

  REPORTS_PUBLISHER_ORDER_GRAPH_SUBHEADER: "(ORDERS AND AMOUNT EARNED)",

  // Payments
  PAYMENTS_DELETE_CONFIRMATION_INFO_TEXT:
    "Are you sure you want to delete this payment method?",

  PAYMENTS_PAYPAL_CARD_SUBHEADER: "Only for Outside India Users",
  PAYMENTS_BANK_CARD_SUBHEADER: "Only for Indian Users",

  PAYMENTS_SET_AS_PRIMARY_TEXT: "Set as Primary",
  PAYMENTS_PAYPAL_ENTER_EMAIL_LABEL: "Enter your PayPal Email",
  PAYMENTS_BANK_BENEFICIARY_NAME_LABEL: "Beneficiary Name",
  PAYMENTS_BANK_OTHER_BANK_LABEL: "Other Bank",
  PAYMENTS_BANK_SELECT_BANK_LABEL: "Select Bank",
  PAYMENTS_BANK_ACCOUNT_NUMBER_LABEL: "Account Number",
  PAYMENTS_BANK_IFSC_CODE_LABEL: "IFSC Code",

  // Error and Empty Lists
  ERROR_HEADER: "Oh No!",
  ERROR_DESCRIPTION: "Something went wrong, please try again",

  // Default values
  EMPTY_LISTING_HEADER: "Oops!",
  EMPTY_LISTING_SUBHEADER: "No Data available in the table",

  //Add Website Domain Verification
  ADD_WEBSITE_DNS_VERIFY_HEADING: "Ownership Verification",
  ADD_WEBSITE_DNS_VERIFY_DESC:
    "Verification proves that you own the website to be added to the marketplace.",

  // Add Website
  VERIFIED_BY_META: "Meta Tag Method",
  VERIFIED_BY_HTML: "HTML Method",
  VERIFIED_BY_DNS: "DNS Method",
  VERIFIED_BY_ADMIN: "Admin",
  ADD_WEBSITES_FIRST_TAB: "Check Eligibility",
  ADD_WEBSITES_SECOND_TAB: "Website Details",
  ADD_WEBSITES_THIRD_TAB: "Traffic Details",

  // Notifications
  NOTIFICATIONS_TITLE: "Notifications",

  // Profile Dropdown
  PROFILE_DROPDOWN_CHANGE_PASSWORD: "Change Password",
  PROFILE_DROPDOWN_LOGOUT: "Logout",
  PROFILE_INVITE_FRIEND: "Invite a Friend",
  TRACK_REFERRAL: "Track Referral",
  TRACK_REFERRAL_TEXT:
    "You can track every step of the referral process, from the time a customer signs up to your program",
  MARKETING_TOOL: "Marketing Tool",
  MARKETING_TOOL_TEXT1:
    "We've made things very easy. Just choose a banner from our collection and place it in your site. ",
  VIEW_STATUS_REFERRAL: "View the status of your referrals",
  VIEW_STATUS_REFERRAL_TEXT:
    "Click below to check how many referrals you attracted and how much money you earned",

  PAYPAL_INVOICES: "Paypal Invoices",
  POST_PAY_TEXT: "*You are eligible for pay later*",
  POST_PAY_NOT_PAID_TEXT: "You need to pay first to complete your order. ",
  POST_PAY_PAYMENT_RECEIVED_TEXT:
    "Payment received, status will be updated soon.",
  POST_PAY_PAID_TEXT: "Payment received, status will be updated soon.",

  // image Urls
  IMAGE_StaticHomeTestimonial:
    "https://blog.outreachmantra.com/wp-content/uploads/2022/04/staticHomeTestimonial.webp",
  IMAGE_staticHomeHerobanner:
    "https://blog.outreachmantra.com/wp-content/uploads/2022/04/staticHomeHerobanner.webp",
  IMAGE_staticContentDashboard:
    "https://blog.outreachmantra.com/wp-content/uploads/2022/04/staticContentDashboard.webp",
  IMAGE_staticAdvertiserDashboard:
    "https://blog.outreachmantra.com/wp-content/uploads/2022/04/staticAdvertiserDashboard.webp",

  IMAGE_staticStoryStorybackground:
    "https://blog.outreachmantra.com/wp-content/uploads/2022/04/staticStoryStorybackground.webp",
  IMAGE_staticPublisherDashboard:
    "  https://blog.outreachmantra.com/wp-content/uploads/2022/04/staticPublisherDashboard.webp",
  IMAGE_staticRegisterImage:
    "https://blog.outreachmantra.com/wp-content/uploads/2022/04/staticRegisterImage.webp",
  IMAGE_staticContactHero:
    "https://blog.outreachmantra.com/wp-content/uploads/2022/04/staticContactHero.webp",
  IMAGE_staticFaqHero:
    "https://blog.outreachmantra.com/wp-content/uploads/2022/04/staticFaqHero.webp",
};
