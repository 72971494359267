import React, { useEffect, useState } from "react";
import PaypalLogo from "../assets/logo_paypal.png";
import ThemeButton from "./ThemeButton";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { countryData } from "../constants/Country";

const AddCustomerInfo = (props) => {
  const { paypalBillingDto } = props;
  const [firstName, setFirstName] = useState(paypalBillingDto?.firstName);
  const [lastName, setLastName] = useState(paypalBillingDto?.lastName);
  const [email, setEmail] = useState(paypalBillingDto?.emailAddress);
  const [companyName, setCompanyName] = useState(paypalBillingDto?.companyName);
  const [addressLine1, setAddressLine1] = useState(
    paypalBillingDto?.billingAddress?.addressLine1
  );
  const [addressLine2, setAddressLine2] = useState(
    paypalBillingDto?.billingAddress?.addressLine2
  );
  const [country, setCountry] = useState(
    paypalBillingDto?.billingAddress?.country
  );
  const [postalCode, setPostalCode] = useState(
    paypalBillingDto?.billingAddress?.postalCode
  );
  const [vatNumber, setVatNumber] = useState(
    paypalBillingDto?.billingAddress?.vatNumber
  );
  const [additionalInfo, setAdditionalInfo] = useState(
    paypalBillingDto?.billingAddress?.additionalInfo
  );

  const onFormSubmit = (event) => {
    event.preventDefault();
    const data = {
      billingAddress: {
        addressLine1: addressLine1 ? addressLine1 : null,
        addressLine2: addressLine2 ? addressLine2 : null,
        country: country ? country : null,
        additionalInfo: additionalInfo?additionalInfo:null,
        postalCode:postalCode?postalCode:null,
        vatNumber:vatNumber?vatNumber:null,
      },
      companyName: companyName,
      emailAddress: email,
      firstName: firstName,
      lastName: lastName,
    };

    props.saveCustomerInfo(data);
    props.onHide();
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Customer Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <Form onSubmit={onFormSubmit}>
            <b className="mb-3">Customer Information</b>
            <div className="row">
              <div className="col-md-6">
                <FloatingLabel
                  controlId="firstName"
                  label="First Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-6">
                <FloatingLabel
                  controlId="lastName"
                  label="Last Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-12">
                <FloatingLabel
                  controlId="companyName"
                  label="Business Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Business Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-12">
                <FloatingLabel controlId="email" label="Email" className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </FloatingLabel>
              </div>
            </div>
            <hr />
            <b>Billing Address</b>

            <div className="row mt-3">
              <div className="col-md-12 mb-3">
                <FloatingLabel controlId="country" label="Country">
                  <Form.Select
                    aria-label="Floating label select example"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option value=""> Select Country</option>
                    {countryData &&
                      countryData.length > 0 &&
                      countryData.map((item, index) => {
                        return (
                          <option value={item.name} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </FloatingLabel>
              </div>
              <div className="col-md-12">
                <FloatingLabel
                  controlId="addressLine1"
                  label="Address Line 1"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Address Line 1"
                    value={addressLine1}
                    onChange={(e) => setAddressLine1(e.target.value)}
                  />
                </FloatingLabel>
              </div>

              <div className="col-md-12">
                <FloatingLabel
                  controlId="addressLine2"
                  label="Address Line 2"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Address Line2"
                    value={addressLine2}
                    onChange={(e) => setAddressLine2(e.target.value)}
                  />
                </FloatingLabel>
              </div>

              <div className="col-md-6">
                <FloatingLabel
                  controlId="postalCode"
                  label="Postal Code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Postal Code"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                </FloatingLabel>
              </div>

              <div className="col-md-6">
                <FloatingLabel
                  controlId="vatNumber"
                  label="VAT Number"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="VAT Number"
                    value={vatNumber}
                    onChange={(e) => setVatNumber(e.target.value)}
                  />
                </FloatingLabel>
              </div>

              <div className="col-md-12">
                <FloatingLabel controlId="additionalInfo" label="Additional Info" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Notes"
                    value={additionalInfo}
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                  />
                </FloatingLabel>
              </div>
            </div>
            <ThemeButton type="submit">Save</ThemeButton>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddCustomerInfo;
