import { useState, createContext } from "react";

export const TawkContext = createContext();

export const TawkProvider = props => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [showChatbot, setShowChatbot] = useState(false);

  return (
    <TawkContext.Provider
      value={[isLoaded, setIsLoaded, showChatbot, setShowChatbot]}
    >
      {props.children}
    </TawkContext.Provider>
  );
};
