import React from "react";

const FadedElement = ({ testimonial, type, onClick }) => {
  return testimonial?.img ? (
    <div
        onClick={onClick}
      className={`image-slide faded-image ${
        type === "second" ? "second-faded-element" : ""
      }`}
      style={{
        backgroundImage: `url(${testimonial?.img})`,
        marginLeft: "-10px",
      }}
    />
  ) : (
    <div
        onClick={onClick}
      className={`image-slide faded-image ${
        type === "second" ? "second-faded-element" : ""
      } text-white fs-2 fw-bold d-flex justify-content-center align-items-center`}
    >
      {testimonial?.authorName[0]}
    </div>
  );
};

export default FadedElement;
