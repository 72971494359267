import React from "react";

export default function Card({ card, className }) {
  return (
    <div className={`cardContainer ${className}`}>
      <div className="cardImgContainer">
        {card.circleColor ? (
          <div className="circle-icon-container">
            <div
              className="cardCircle"
              style={{ backgroundColor: card.circleColor }}
            />
            {card.icon}
          </div>
        ) : (
          <img src={card.imageUri} alt="card image" className="cardImage" />
        )}
      </div>
      <div className="card-text-container">
        <h1 className="cardHeader text-start ">{card.title}</h1>
        <p className="text-start">{card.description}</p>
      </div>
    </div>
  );
}
