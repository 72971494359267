import React, { useReducer } from "react";
import Arrow from "./Arrow";
import FadedElement from "./FadedElement";
import FocusElement from "./FocusElement";
import { FaQuoteLeft } from "react-icons/all";
import Colors from "../../constants/colors";
import { CURRENT_URL, EVENT_NAME, GAevent } from "../../utils/Events";

const reducer = (state = {}, action) => {
  switch (action.type) {
    case "GOTO_START_INDEX":
      return Object.assign({}, state, { index: 0 });
    case "GOTO_END_INDEX":
      return Object.assign({}, state, {
        index: action.payload,
      });
    case "INCREMENT_INDEX":
      return Object.assign({}, state, {
        index: state.index + 1,
      });
    case "DECREMENT_INDEX":
      return Object.assign({}, state, {
        index: state.index - 1,
      });
    default:
      return state;
  }
};

function Carousel({ data }) {
  const [{ index }, dispatch] = useReducer(reducer, {
    index: 0,
  });

  const returnPrevIndex = (index) => {
    if (index === 0) {
      return data.length - 2;
    }
    if (index === 1) {
      return data.length - 1;
    }
    return index - 2;
  };
  const returnNextIndex = (index) => {
    if (index === data.length - 2) {
      return 0;
    }
    if (index === data.length - 1) {
      return 1;
    }
    return index + 2;
  };
  return (
    <div className="position-relative">
      <div className="mb-3">
        <div className="bubble px-4 pt-4 d-flex justify-content-center align-items-center">
          <div className="d-flex">
            <span>
              <FaQuoteLeft
                color={Colors.secondaryColor}
                style={{ opacity: 0.4 }}
                size={50}
              />
            </span>
            <span
              className="fst-italic text-theme"
              style={{ marginTop: 20, marginRight: 20, fontWeight: "500" }}
            >
              {data[index].content}
            </span>
          </div>
        </div>
        <div className="pointer-1"></div>
      </div>

      <div className="position-relative d-flex flex-row align-items-center justify-content-center">
        <Arrow
          direction="left"
          clickFunction={() => {
            index === 0
              ? dispatch({ type: "GOTO_END_INDEX", payload: data.length - 1 })
              : dispatch({ type: "DECREMENT_INDEX" });
            GAevent(EVENT_NAME.home_page_testimonials, [
              "left_arrow",
              data[index].authorName,
            ]);
          }}
        />
        <div className="d-flex flex-row align-items-center justify-content-center mx-2">
          <FadedElement
            testimonial={data[returnPrevIndex(index)]}
            type="second"
          />

          <FadedElement
            testimonial={index === 0 ? data[data.length - 1] : data[index - 1]}
          />
          <FocusElement testimonial={data[index]} />
          <FadedElement
            testimonial={index === data.length - 1 ? data[0] : data[index + 1]}
          />
          <FadedElement
            testimonial={data[returnNextIndex(index)]}
            type="second"
          />
        </div>
        <Arrow
          direction="right"
          clickFunction={() => {
            index === data.length - 1
              ? dispatch({ type: "GOTO_START_INDEX" })
              : dispatch({ type: "INCREMENT_INDEX" });
            GAevent(EVENT_NAME.home_page_testimonials, [
              "right_arrow",
              data[index].authorName,
            ]);
          }}
        />
      </div>
      <div>
        <h5 className="mt-3 fw-bold text-theme">{data[index].authorName}</h5>
        <p className="text-theme-minor fw-bold">
          {data[index].authorOccupation}
        </p>
      </div>
    </div>
  );
}

export default Carousel;
