import { setCookies } from "../services/Helpers";
import Cookies from "js-cookie";
import { setUserProfileData } from "../redux/auth/ActionCreator";

const JWT_COOKIE_NAME = "om-auth-token";

export const saveJWTToken = (jwtToken, expires) => {
  setCookies(JWT_COOKIE_NAME, jwtToken, expires);
};

export const fetchJWTToken = () => {
  return Cookies.get(JWT_COOKIE_NAME);
};

export const removeJWTToken = () => {
  return fetchJWTToken() && Cookies.remove(JWT_COOKIE_NAME);
};

export const handle401 = (history, dispatch) => {
  dispatch(setUserProfileData(""));
  removeJWTToken();
  history.push("/");
};
