import React from "react";

const Header = ({ style, text, className, children }) => {
  if (children)
    return (
      <h2 style={style} className={`header text-theme ${className}`}>
        {children}
      </h2>
    );
  return (
    <h2 style={style} className={`header text-theme ${className}`}>
      {text}
    </h2>
  );
};

const Description = ({ style, text, className, children }) => {
  if (children)
    return (
      <p style={style} className={`description text-theme ${className}`}>
        {children}
      </p>
    );
  return (
    <p style={style} className={`description text-theme ${className}`}>
      {text}
    </p>
  );
};

export default function Typography(props) {
  const { variant = "description" } = props;
  const getComponent = () => {
    switch (variant) {
      case "header":
        return <Header {...props} />;
      case "description":
        return <Description {...props} />;
      default:
        return null;
    }
  };
  return getComponent();
}
