import {
  SET_DASHBOARD_BADGES,
  SET_USER_PROFILE_DATA,
  UPDATE_USER_PREFERENCE,
  SET_DASHBOARD_CONFIG,
  SET_GA_AUTH_CODE,
  SET_EDIT_WEBSITE_DATA,
  UPDATE_CART_DELETE_PREFERENCE,
  UPDATE_WISHLIST_DELETE_PREFERENCE,
  SET_ADVERTISER_NOTIFICATIONS_COUNT,
  SET_PUBLISHER_NOTIFICATIONS_COUNT,
  SET_PAYMENT_STATUS_DATA,
  SET_PAYMENT_STATUS_ORDER_ID,
  SET_HOME_CONFIG,
  SET_MY_WEBSITE_CONSENT_STATUS_CODE,
  SET_MARKETPLACE_CHECKOUT_DATA,
  SET_LANGUAGE,
  SET_RAZORPAY_PAYMENT_ID,
  CLEAR_REDUX_INITIAL,
  SET_RELOAD_BILLING_AND_FUNDS
} from "./ActionTypes";

const initialState = {
  reloadBillingAndFunds: false,
  language: "EN",
  razorpayPaymentId: "",
  marketplaceCheckoutData: "",
  paymentStatusData: "",
  paymentStatusDataOrderId: "",
  profileDetails: "",
  dashboardBadges: "", // contains stale notification count [deprecated]
  homeConfig: "",
  dashboardConfig: "",
  gaAuthCode: "",
  myWebsiteConsentStatusCode: "",
  editWebsiteData: "",
  advertiserNotificationsCount: 0, // new way of storing notification count
  publisherNotificationsCount: 0, // new way of storing notification count
  cartDeleteItemInstantlyPref: false,
  wishlistDeleteItemInstantlyPref: false,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RELOAD_BILLING_AND_FUNDS:
      return {
        ...state,
        reloadBillingAndFunds: action.payload,
      };
    case CLEAR_REDUX_INITIAL:
      return {
        ...state,
        razorpayPaymentId: "",
        paymentStatusData: "",
        // paymentStatusDataOrderId: "",
        myWebsiteConsentStatusCode: "",
        gaAuthCode: "",
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case SET_USER_PROFILE_DATA:
      return {
        ...state,
        profileDetails: action.payload,
      };
    case UPDATE_USER_PREFERENCE:
      return {
        ...state,
        profileDetails: {
          ...state.profileDetails,
          preference: action.payload.preference,
          amount: action.payload.amount,
          totalAmount: action.payload.totalAmount,
          payoutAmount: action.payload.payoutAmount,
        },
      };
    case SET_DASHBOARD_BADGES:
      return {
        ...state,
        dashboardBadges: action.payload,
      };
    case SET_DASHBOARD_CONFIG:
      return {
        ...state,
        dashboardConfig: action.payload,
      };
    case SET_HOME_CONFIG:
      return {
        ...state,
        homeConfig: action.payload,
      };
    case SET_GA_AUTH_CODE:
      return {
        ...state,
        gaAuthCode: action.payload,
      };
    case SET_EDIT_WEBSITE_DATA:
      return {
        ...state,
        editWebsiteData: action.payload,
      };
    case UPDATE_CART_DELETE_PREFERENCE:
      return {
        ...state,
        cartDeleteItemInstantlyPref: action.payload,
      };
    case UPDATE_WISHLIST_DELETE_PREFERENCE:
      return {
        ...state,
        wishlistDeleteItemInstantlyPref: action.payload,
      };
    case SET_ADVERTISER_NOTIFICATIONS_COUNT: {
      return {
        ...state,
        advertiserNotificationsCount: action.payload,
      };
    }
    case SET_PUBLISHER_NOTIFICATIONS_COUNT: {
      return {
        ...state,
        publisherNotificationsCount: action.payload,
      };
    }
    case SET_PAYMENT_STATUS_DATA:
      return {
        ...state,
        paymentStatusData: action.payload,
      };
    case SET_PAYMENT_STATUS_ORDER_ID:
      return {
        ...state,
        paymentStatusDataOrderId: action.payload,
      };
    case SET_MY_WEBSITE_CONSENT_STATUS_CODE:
      return {
        ...state,
        myWebsiteConsentStatusCode: action.payload,
      };
    case SET_MARKETPLACE_CHECKOUT_DATA:
      return {
        ...state,
        marketplaceCheckoutData: action.payload,
      };
    case SET_RAZORPAY_PAYMENT_ID:
      return {
        ...state,
        razorpayPaymentId: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
